/* eslint-disable max-len */
export const validationMessages = {
  'validation.error.field-is-required': 'Pflichtfeld',
  'validation.error.string-is-with-spaces': 'Keine Leerzeichen',
  'validation.error.string-is-with-no-number': 'Bitte tragen Sie die Hausnummer im separaten Feld ein.',
  'validation.error.string-length-is-not-valid': 'Die Eingabe ist zu lang',
  'validation.error.string-length-is-not-valid.max-24': 'maximal 24 Buchstaben',
  'validation.error.string-length-is-not-valid.max-30': 'maximal 30 Buchstaben',
  'validation.error.string-length-is-not-valid.max-950': 'maximal 950 Buchstaben',
  'validation.error.name1-first-char-is-not-valid': 'sollte mit a-Z oder 1-9 beginnen',
  'validation.error.name2-first-char-is-not-valid': 'sollte mit a-Z oder 0-9 beginnen',
  // eslint-disable-next-line quotes
  'validation.error.name-tail-chars-are-not-valid': "nur Sonderzeichen . , - / + & ' ( )  erlaubt",
  'validation.error.zip-code-is-not-valid': 'PLZ muss fünfstellig sein',
  'validation.error.insuranse-sum-is-not-valid': 'Mögliche Versicherungssumme liegt zwischen 1.000 € und ',
  'validation.error.iban-is-not-valid': 'IBAN ist nicht korrekt',
  'validation.error.bic-is-not-valid': 'BIC ist nicht korrekt',
  'validation.error.eMail-is-not-valid': 'E-Mail ist nicht korrekt',
  'validation.error.eMail-is-not-allowed': 'E-Mail ist nicht erlaubt',
  'validation.error.confirmEMail-is-not-valid': 'Bitte prüfen Sie die eingegebene E-Mail-Adresse.',
  'validation.error.inseption-date-is-not-valid': 'Das Datum darf nicht in der Vergangenheit liegen.',
  'validation.error.date-of.birth-is-not-valid': 'Datum ist ungültig.',
  'validation.error.risk-address-is-not-valid':
    'Die Risikoadresse ist nicht überprüfbar, bitte prüfen Sie die Eingaben für den Versicherungsort. ',
  'validation.info.risk-address-is-not-valid': 'Die Prüfung findet erneut statt, sobald ein Adressfeld angepasst wird.',
  'validation.process.checking-risk-address': 'Die Risikoadresse wird geprüft.',
  'validation.description.insurance-sum': 'Die Versicherungssumme bitte in 100er-Schritten angeben.',
  'validation.error.telephone-number': 'Die Telefonnummer bitte im Format Vorwahl/Rufnummer angeben.',
};
