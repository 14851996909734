import Button from '@eg/elements/Button';
import ArrowRightIcon from '@eg/elements/Icons/ArrowRightIcon';
import { BasicTemplate } from 'client/components/templates/BasicTemplate/BasicTemplate';
import { withI18n } from 'client/i18n/withI18n';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from './messages';

const NotFoundPageComponent = () => (
  <BasicTemplate>
    <div className="u-padding-top-l u-padding-bottom-m u-text-align-center">
      <h1>404!</h1>
      <Button
        className="u-margin-top-m"
        onClick={() => window.location.replace('https://www.ergo.de/de/Geschaeftskunden-Produkte')}
        variant="primary"
        iconRight={ArrowRightIcon}
      >
        <FormattedMessage id="not-found-page.button.text" />
      </Button>
    </div>
  </BasicTemplate>
);

export const NotFoundPage = withI18n(NotFoundPageComponent, messages);
