/* eslint-disable import/prefer-default-export */
export interface IAddress {
  street: string;
  city: string;
  zipCode: string;
}
export interface IFrontAddress extends IAddress {
  houseNumber: string;
}

export const mapAddressToDto = (address: IFrontAddress): IAddress => ({
  street: `${address.street} ${address.houseNumber}`,
  city: address.city,
  zipCode: address.zipCode,
});
