import {
  validateRequiredStringLength,
  validateStringLength,
} from 'client/helpers/functions/validations/validateRequiredStringLength';
import { validationMessages } from 'client/i18n/validationMessages';

const NAME_LENGTH = 30;
const name1FirstCharRegExp = /^[a-zA-ZÄÖÜäöüÀÈÌÒÙàèìòùÁÉÍÓÚáéíóúÂß1-9].*/;
const name2FirstCharRegExp = /^[a-zA-ZÄÖÜäöüÀÈÌÒÙàèìòùÁÉÍÓÚáéíóúÂß0-9].*/;
const nameTailCharsRegExp = /^[a-zA-ZÄÖÜäöüÀÈÌÒÙàèìòùÁÉÍÓÚáéíóúÂß0-9 ,.\-'&+*/()]*$/;

const isValidFirstCharName1 = (str: string) => name1FirstCharRegExp.test(str);

const isValidFirstCharName2 = (str: string) => name2FirstCharRegExp.test(str);

const isValidTailChars = (str: string) => nameTailCharsRegExp.test(str);

// Last Name
export const validateName1 = (stringValue: string, isValidationOn = true) => {
  if (!isValidationOn) {
    return false;
  }

  // Validate length
  const stringValidationError = validateRequiredStringLength(NAME_LENGTH, stringValue);
  if (stringValidationError) {
    return stringValidationError;
  }

  // Validate first char
  if (!isValidFirstCharName1(stringValue)) {
    return validationMessages['validation.error.name1-first-char-is-not-valid'];
  }

  // Validate tail chars
  if (!isValidTailChars(stringValue)) {
    return validationMessages['validation.error.name-tail-chars-are-not-valid'];
  }

  return false;
};

// First Name
export const validateName2 = (stringValue: string, isRequired: boolean, isValidationOn = true) => {
  if (!isValidationOn) {
    return false;
  }

  // Validate length
  const lengthValidationError = isRequired
    ? validateRequiredStringLength(NAME_LENGTH, stringValue, isValidationOn)
    : validateStringLength(NAME_LENGTH, stringValue, isValidationOn);

  if (lengthValidationError) {
    return lengthValidationError;
  }

  // Validate first char
  const shouldValidateFirstChar = isRequired || stringValue.length > 0;
  if (shouldValidateFirstChar && !isValidFirstCharName2(stringValue)) {
    return validationMessages['validation.error.name2-first-char-is-not-valid'];
  }

  // Validate tail chars
  if (!isValidTailChars(stringValue)) {
    return validationMessages['validation.error.name-tail-chars-are-not-valid'];
  }

  return false;
};
