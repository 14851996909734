import { RiskAddressState } from 'client/model/RiskAddressState';
import { IFrontAddress } from 'common/address.interface';
import { RiskAddressResponseDto } from 'common/dto/risk-address-response.dto';
import { EStatus } from 'common/status.enum';
import React, { createContext, useState } from 'react';

import { getZuersClassAndHazardZoneByAddress } from './api';

export interface IRiskAddressContext {
  riskAddressState: RiskAddressState;
  isLoading: boolean;
  error: string | null;
  checkRiskAddress: (newAddress: IFrontAddress) => void;
}

const RiskAddressContext = createContext<IRiskAddressContext>({
  riskAddressState: RiskAddressState.getDefaultRiskAddressState(),
  isLoading: false,
  error: null,
  checkRiskAddress: () => { },
});
const { Provider } = RiskAddressContext;

interface IRiskAddressProvider {
  children: React.ReactNode;
}

const RiskAddressProvider = ({ children }: IRiskAddressProvider) => {
  const [riskAddressState, setRiskAddressState] = useState(RiskAddressState.getDefaultRiskAddressState());
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [checkAddressTimer, setCheckAddressTimer] = useState(0);

  const checkRiskAddress = (newAddress: IFrontAddress) => {
    setIsLoading(true);
    setError(null);

    // execute the last input value after 1/2 second
    if (checkAddressTimer) {
      window.clearTimeout(checkAddressTimer);
    }
    setCheckAddressTimer(
      window.setTimeout(
        () => getZuersClassAndHazardZoneByAddress(newAddress).then((riskAddressResult) => {
          setIsLoading(false);
          setError(riskAddressResult.error);
          // eslint-disable-next-line prefer-destructuring
          const payload: RiskAddressResponseDto = riskAddressResult.payload as RiskAddressResponseDto;
          if (payload.status === EStatus.Ok && payload.zuersClass !== null && payload.hazardZone !== null) {
            setRiskAddressState(new RiskAddressState(payload.zuersClass, payload.hazardZone, true));
          } else {
            setRiskAddressState(RiskAddressState.getDefaultRiskAddressState());
          }
        }),
        500,
      ),
    );
  };

  return (
    <Provider
      value={{
        riskAddressState,
        isLoading,
        error,
        checkRiskAddress,
      }}
    >
      {children}
    </Provider>
  );
};

export { Provider, RiskAddressContext, RiskAddressProvider };
