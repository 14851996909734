export const preInsuringCompanies = [
  { id: '5342', value: 'AachenMünchener Versicherung AG' },
  { id: '1069', value: 'AIG Europe Limited' },
  { id: '7066', value: 'AIG EUROPE ( Netherlands) N.V.' },
  { id: '5370', value: 'Allianz Global Corporate & Specialty SE (AGCS)' },
  { id: '5312', value: 'Allianz Versicherungs-AG' },
  { id: '5405', value: 'Alte Leipziger Versicherung AG' },
  { id: '5455', value: 'ARAG Allgemeine Versicherung AG' },
  { id: '5607', value: 'AXA Direkt Versicherungs AG' },
  { id: '5515', value: 'AXA Versicherung AG' },
  { id: '5792', value: 'Baden-Badener Versicherung AG' },
  { id: '5593', value: 'Badische Allgemeine Versicherung AG' },
  { id: '5317', value: 'Barmenia Allgem.Versicherungs-AG' },
  { id: '5318', value: 'Basler Versicherungs-Gesellschaft' },
  { id: '0510', value: 'Bayerische Versicherungskammer' },
  { id: '5310', value: 'Bayerische Beamten Versicherung AG' },
  { id: '5338', value: 'Concordia Versicherungs-G.A.G.' },
  { id: '5339', value: 'Condor Versicherung' },
  { id: '5340', value: 'Continentale Sachversicherung-AG' },
  { id: '5049', value: 'Cordial Versicherung AG' },
  { id: '5552', value: 'Cosmos Versicherung AG' },
  { id: '5343', value: 'DA Deutsche Allgemeine Versicherung AG' },
  { id: '5549', value: 'Debeka Allgemeine Vers. AG' },
  { id: '5349', value: 'Deutscher Lloyd' },
  { id: '5513', value: 'DEVK Allgemeine Versicherungs-AG' },
  { id: '6002', value: 'DUAL Deutschland GmbH' },
  { id: '2017', value: 'ERGO Versicherung AG' },
  { id: '5508', value: 'Europa Sachversicherung AG' },
  { id: '1179', value: 'GE Financial Insurance Deutschland' },
  { id: '5599', value: 'Generali Versicherung AG' },
  { id: '1126', value: 'Gothaer Allgemeine Versicherung AG' },
  { id: '5374', value: 'Haftpflichtkasse Darmstadt' },
  { id: '5501', value: 'Hanse-Merkur Allgem.Versicherungs-AG' },
  { id: '5377', value: 'HDI Haftpflichtverband der Deutschen Industrie' },
  { id: '5384', value: 'Helvetia Schweizerische Versicherungsgesellschaft' },
  { id: '6001', value: 'Hiscox Europe Underwriting Ltd.' },
  { id: '5086', value: 'HUK24 AG' },
  { id: '5521', value: 'HUK-Coburg Allgemeine Versicherung AG' },
  { id: '5573', value: 'Ideal Versicherung AG' },
  { id: '5072', value: 'IF Schadenversicherung AG' },
  { id: '5546', value: 'Inter Allgemeine Versicherung AG' },
  { id: '5057', value: 'InterLloyd Versicherung AG' },
  { id: '5780', value: 'InterRisk Versicherungs-AG' },
  { id: '1123', value: 'Itzehoer Versicherungs AG' },
  { id: '1200', value: 'Janitos Versicherung AG' },
  { id: '5509', value: 'Karlsruher Versicherung AG' },
  { id: '5399', value: 'KRAVAG SACH Versicherung des deut. Kraftverkehrs' },
  { id: '5534', value: 'KS Versicherungs-AG' },
  { id: '5402', value: 'LVM Landwitschaftlicher Versicherungsverein Münster' },
  { id: '5061', value: 'Mannheimer Vers.AG' },
  { id: '6003', value: 'Markel International Insurance Company Ltd.' },
  { id: '5426', value: 'NÜRNBERGER Allgemeine Versicherungs-AG' },
  { id: '0501', value: 'Öffentliche Versicherung Braunschweig' },
  { id: '0502', value: 'Öffentliche Versicherung Bremen (ÖVB)' },
  { id: '0522', value: 'Öffentliche Versicherungen Sachsen-Anhalt (ÖSA)' },
  { id: '0521', value: 'Öffentlichen Versicherungen Oldenburg' },
  { id: '5543', value: 'Öffentlichen Versicherungs-Anstalt (ÖVA)' },
  { id: '5074', value: 'PB Versicherung AG' },
  { id: '5436', value: 'Provinzial Rheinland Versicherung AG' },
  { id: '5438', value: 'R+V Allgemeine Vers.AG' },
  { id: '5798', value: 'Rheinland Versicherung AG' },
  { id: '5121', value: 'Rhion Versicherung AG' },
  { id: '5448', value: 'Schweizer-National' },
  { id: '5450', value: 'Securitas Bremer Allgemeine Versicherungs AG' },
  { id: '5390', value: 'Signal-Iduna Allgemeine Versicherung AG' },
  { id: '5051', value: 'Sparkassen direkt Versicherung AG' },
  { id: '5482', value: 'Sparkassen-Versicherung Allgem.Versicherung AG' },
  { id: '5516', value: 'Stuttgarter Allgemeine Versicherungs-AG' },
  { id: '5790', value: 'TARGO Versicherung AG' },
  { id: '5567', value: 'Trias Versicherung AG' },
  { id: '5463', value: 'UniVersa Allgemeine Versicherung AG' },
  { id: '5327', value: 'Vereinte Versicherung AG' },
  { id: '5042', value: 'Versicherungskammer Bayern' },
  { id: '5400', value: 'VGH Hannover' },
  { id: '5598', value: 'VHV Allgemeine Versicherung AG' },
  { id: '5484', value: 'Volkswohl Bund Sachversicherung AG' },
  { id: '5461', value: 'VPV Allgemeine Versicherung AG' },
  { id: '0509', value: 'Westfälische Provinzial Versicherung AG' },
  { id: '5525', value: 'WGV-Schwäbische Allgemeine Versicherung AG' },
  { id: '5479', value: 'Württembergische Gemeinde-Versicherung a. G. (wgv)' },
  { id: '5480', value: 'Württembergische und Badische Versicherungs-AG' },
  { id: '5590', value: 'Würzburger Versicherung AG' },
  { id: '6958', value: 'Wüstenrot &amp;  Württembergische AG' },
  { id: '5476', value: 'WWK Allgemeine Versicherung AG' },
  { id: '5050', value: 'Zürich Versicherung AG' },
  { id: '9999', value: 'anderer Versicherer' },
];
