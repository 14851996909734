import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import '@eg/elements/styles/ergo-one/styles.css';
import '@eg/elements/styles/ergo-one/core.css';
import 'client/scss/styles.scss';
import 'dom4';

import ErgoElementsProvider from '@eg/elements/Provider';
import { GpOnlineApp } from 'client/GpOnlineApp';
import { infoMessages } from 'client/i18n/infoMessages';
import { validationMessages } from 'client/i18n/validationMessages';
import React from 'react';
import ReactDOM from 'react-dom';
import { addLocaleData, IntlProvider } from 'react-intl';
import de from 'react-intl/locale-data/de';
import * as serviceWorker from 'serviceWorker';

addLocaleData(de);

ReactDOM.render(
  <IntlProvider locale="de-DE" messages={{ ...validationMessages, ...infoMessages }}>
    <ErgoElementsProvider theme="ergo-one">
      <GpOnlineApp />
    </ErgoElementsProvider>
  </IntlProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
