/* eslint-disable */
export enum ERisks {
  Fire = 'FIRE',
  Burglary = 'BURGLARY', // it is not spelled BULGARY
  PipeWater = 'PIPEWATER',
  StormHail = 'STORM_HAIL',
  Flooding = 'FLOODING',
  NaturalHazard = 'NATURAL_HAZARD',
  ExtendedCoverage = 'EXTENDED_COVERAGE',
  Unnamed = 'UNNAMED',
  LossRevenue = 'LOSS_REVENUE',
  Assistance = 'ASSISTANCE'
}
