import { BusinessContent, IBusinessContent } from 'common/BusinessContent';
import { EConsumer } from 'common/consumer.enum';
import { EFormOfPayment } from 'common/form-of-payment.enum';

export interface ITariffRequestDto {
  formOfPayment: EFormOfPayment;
  businessContent: IBusinessContent;
  consumer: EConsumer;
}

export class TariffRequestDto implements ITariffRequestDto {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public formOfPayment: EFormOfPayment,
    public businessContent: BusinessContent,
    public consumer: EConsumer, // eslint-disable-next-line no-empty-function
  ) { }

  public static fromRequest(payload: ITariffRequestDto): TariffRequestDto {
    const businessContent = BusinessContent.fromRequest(payload.businessContent);

    const result = new TariffRequestDto(payload.formOfPayment, businessContent, payload.consumer);

    return result;
  }
}
