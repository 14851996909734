import { EStatus } from 'common/status.enum';

export interface IRiskAddressResponse {
  status: EStatus;
  zuersClass: number | null;
  hazardZone: number | null;
}

export class RiskAddressResponseDto implements IRiskAddressResponse {
  /* eslint-disable-next-line no-useless-constructor,no-empty-function */
  constructor(public status: EStatus, public zuersClass: number | null, public hazardZone: number | null) { }

  public static fromZuersHazard(
    zuersStatus: EStatus,
    zuersClass: number | null,
    hazardStatus: EStatus,
    hazardZone: number | null,
  ) {
    const isOk = zuersStatus === EStatus.Ok && hazardStatus === EStatus.Ok;

    if (isOk) {
      return new RiskAddressResponseDto(EStatus.Ok, zuersClass, hazardZone);
    }

    return new RiskAddressResponseDto(EStatus.Nok, null, null);
  }
}
