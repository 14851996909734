/* eslint-disable max-len */
export const messages = {
  'business-type-page.info.link': 'Datenschutzbereich unserer Webseite',
  'business-type-page.business-details.heading': 'Angaben zum Betrieb',
  'business-type-page.business-details.business-type.label': 'Betrieb auswählen*',
  'business-type-page.business-details.business-type.placeholder': 'Betrieb hier eingeben...',
  'business-type-page.business-details.business-type.error':
    'Für “Dachbetrieb” bieten wir derzeit online keinen Versicherungsschutz an. Bitte wenden Sie sich an unseren Kundenservice.',
  'business-type-page.business-details.business-type.server-error':
    'Die Liste der Betriebsarten konnte nicht geladen werden.',
  'business-type-page.business-details.location.label': 'Versicherungsort*',
  'business-type-page.business-details.location.street-name.placeholder': 'Straße',
  'business-type-page.business-details.location.house-number.placeholder': 'Hausnummer',
  'business-type-page.business-details.location.zip.placeholder': 'PLZ',
  'business-type-page.business-details.location.place.placeholder': 'Ort',
  'business-type-page.business-details.location.country.value': 'Deutschland',
  'business-type-page.building-details.heading': 'Angaben zum Gebäude',
  'business-type-page.building-details.is-inclosed-building.question':
    'Die versicherten Sachen befinden sich in einem allseitig umschlossenen Gebäude. Nicht zu Gebäuden zählen z. B. Zelte, Gewächshäuser, Schiffe, Container, Verkaufswagen und halboffene sowie mobile Gebäude.*',
  'business-type-page.building-details.has-security-measures.info':
    'Die versicherten Sachen müssen sich Räumen befinden, die für die Gefahr Einbruchdiebstahl bestimmte Anforderungen an Sicherungen erfüllen (siehe „i“). Diese Sicherungsvereinbarungen gelten für Öffnungen in Wänden, Fußböden, Decken oder Dächern, die die Versicherungsräume begrenzen. Öffnungen sind z. B. Türen, Fenster und Tore. Alle Öffnungen sind durch einen mechanischen oder elektromechanischen Verschluss (z. B. Schlösser, Verriegelungen) gesichert.',
  'business-type-page.building-details.has-security-measures.question': 'Sind die Sicherungen vorhanden?*',
};
