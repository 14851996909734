import CheckIcon from '@eg/elements/Icons/CheckIcon';
import { TariffPriceBox } from 'client/components/molecules/TariffPriceBox/TariffPriceBox';
import { withI18n } from 'client/i18n/withI18n';
import { DocumentContext } from 'client/store/DocumentContext';
import React, { useContext } from 'react';
import { FormattedHTMLMessage, FormattedMessage, InjectedIntlProps } from 'react-intl';

import { messages } from './messages';
import styles from './styles.module.scss';

export interface IContributionBoxProps extends InjectedIntlProps {
  className?: string;
}

const ContributionBoxComponent = ({ intl, className }: IContributionBoxProps) => {
  const { getDocumentLink } = useContext(DocumentContext);

  const outerContainerClass = `esc_container esc_border-radius esc_border-radius--small
    esc_box-shadow esc_box-shadow--small ${styles.container}${className ? ` ${className}` : ''}`;
  const innerContainerClass = `esc_box esc_box--around ${styles.innerContainer}`;

  return (
    <div className={outerContainerClass}>
      <div className={innerContainerClass}>
        <span className={styles.title}>{intl.formatMessage({ id: 'contribution-box.title' })}</span>
        <div className={styles.priceRow}>
          <TariffPriceBox withTax variant="full" />
        </div>
        <div className={styles.benefitsBox}>
          <div className={styles.descriptionRow}>
            <strong>{intl.formatMessage({ id: 'contribution-box.subtitle' })}</strong>
          </div>
          <div className={styles.descriptionRow}>
            <p className={styles.descriptionRowText}>{intl.formatMessage({ id: 'contribution-box.text-1' })}</p>
            <div className={styles.descriptionRowIcon}>
              <CheckIcon />
            </div>
          </div>
          <div className={styles.descriptionRow}>
            <p className={styles.descriptionRowText}>{intl.formatMessage({ id: 'contribution-box.text-2' })}</p>
            <div className={styles.descriptionRowIcon}>
              <CheckIcon />
            </div>
          </div>
          <div className={styles.descriptionRow}>
            <p className={styles.descriptionRowText}>
              <FormattedHTMLMessage id="contribution-box.text-3" />
            </p>
            <div className={styles.descriptionRowIcon}>
              <CheckIcon />
            </div>
          </div>

          <a className="esc_text-link" href={getDocumentLink(false)} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="contribution-box.link" />
          </a>
        </div>
      </div>
    </div>
  );
};

export const ContributionBox = withI18n(ContributionBoxComponent, messages);
