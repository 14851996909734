import DatePicker from '@eg/elements/components/DatePicker';
import ControlWithHint from '@eg/elements/ControlWithHint';
import FormRow from '@eg/elements/FormRow';
import FormSection from '@eg/elements/FormSection';
import Input from '@eg/elements/Input';
import Select from '@eg/elements/Select';
import { AdvisoryTemplate } from 'client/components/templates/AdvisoryTemplate/AdvisoryTemplate';
import { ESelectValues } from 'client/helpers/ESelectValues';
import { generateMaxDateOfBirth } from 'client/helpers/functions/generateMaxDateOfBirth';
import { generateMinDateOfBirth } from 'client/helpers/functions/generateMinDateOfBirth';
import { isCompany } from 'client/helpers/functions/isCompany';
import { isNumber } from 'client/helpers/functions/isNumber';
import {
  validateConfirmEmail,
  validateDateOfBirth,
  validateEmail,
  validateNoSpaces,
  validatePhoneNumber,
  validateRequiredStringLength,
  validateSelectBox,
  validateZipCode,
} from 'client/helpers/functions/validations';
import { validateName1, validateName2 } from 'client/helpers/functions/validations/validateNameFields';
import { validateNoNumberOnEnd } from 'client/helpers/functions/validations/validateNoNumberOnEnd';
import { withI18n } from 'client/i18n/withI18n';
import { BusinessDataContext } from 'client/store/BusinessDataContext';
import { GlobalsContext } from 'client/store/GlobalsContext';
import { EInfoMessages } from 'common/EInfoMessages';
import { ESalutation } from 'common/salutation.enum';
import React, { useContext, useEffect } from 'react';
import { FormattedHTMLMessage, FormattedMessage, InjectedIntlProps } from 'react-intl';

import { messages } from './messages';

const ContactDataPageComponent = ({ intl }: InjectedIntlProps) => {
  const {
    salutation,
    setSalutation,
    companyOrInsurantName,
    setCompanyOrInsurantName,
    nameAffixOrInsurantFirstName,
    setNameAffixOrInsurantFirstName,
    telephoneNumber,
    setTelephoneNumber,
    eMail,
    setEMail,
    confirmEMail,
    setConfirmEMail,
    address,
    insurantAddress,
    setInsurantAddress,
    dateOfBirth,
    setDateOfBirth,
    customerNo,
    setCustomerNo,
  } = useContext(BusinessDataContext);

  const { setIsValidationError, isValidationOn } = useContext(GlobalsContext);

  // check for validation error
  useEffect(() => {
    setIsValidationError(
      !!validateName1(companyOrInsurantName) ||
      !!validateName2(nameAffixOrInsurantFirstName, !isCompany(salutation)) ||
      !!validateRequiredStringLength(30, insurantAddress.street) ||
      !!validateNoNumberOnEnd(insurantAddress.street) ||
      !!validateNoSpaces(insurantAddress.houseNumber) ||
      !!validateRequiredStringLength(10, insurantAddress.houseNumber) ||
      !!validateZipCode(insurantAddress.zipCode) ||
      !!validateRequiredStringLength(24, insurantAddress.city) ||
      !!validateRequiredStringLength(30, telephoneNumber) ||
      !!validateDateOfBirth(dateOfBirth, !isCompany(salutation)) ||
      !!validateEmail(eMail) ||
      !!validateConfirmEmail(eMail, confirmEMail) ||
      !!validateSelectBox(salutation) ||
      !!validatePhoneNumber(telephoneNumber),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    companyOrInsurantName,
    nameAffixOrInsurantFirstName,
    insurantAddress,
    telephoneNumber,
    confirmEMail,
    eMail,
    salutation,
    dateOfBirth,
  ]);

  // prefill isurant address from risk address, if the all values are empty
  useEffect(() => {
    if (!insurantAddress.city && !insurantAddress.street && !insurantAddress.houseNumber && !insurantAddress.zipCode) {
      setInsurantAddress({
        city: address.city,
        street: address.street,
        houseNumber: address.houseNumber,
        zipCode: address.zipCode,
      });
    }
  }, []);

  return (
    <AdvisoryTemplate>
      <FormSection
        heading={intl.formatMessage({
          id: 'contact-data-page.heading',
        })}
      >
        <FormRow
          colspans={[1, 2]}
          label={intl.formatMessage({
            id: 'contact-data-page.salutation.label',
          })}
        >
          <ControlWithHint error={validateSelectBox(salutation, isValidationOn)}>
            <Select
              name="salutation"
              aria-label={intl.formatMessage({ id: 'contact-data-page.salutation.label' })}
              value={salutation}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setSalutation(e.target.value as any);
              }}
            >
              <option value={ESelectValues.PleaseSelect} disabled>
                {intl.formatMessage({
                  id: 'contact-data-page.salutation.options.please-select',
                })}
              </option>
              <option value={ESalutation.Company}>
                {intl.formatMessage({
                  id: 'contact-data-page.salutation.options.company',
                })}
              </option>
              <option value={ESalutation.Mrs}>
                {intl.formatMessage({
                  id: 'contact-data-page.salutation.options.mrs',
                })}
              </option>
              <option value={ESalutation.Mr}>
                {intl.formatMessage({
                  id: 'contact-data-page.salutation.options.mr',
                })}
              </option>
              <option value={ESalutation.Divers}>
                {intl.formatMessage({
                  id: 'contact-data-page.salutation.options.divers',
                })}
              </option>
            </Select>
          </ControlWithHint>
        </FormRow>
        <FormRow
          label={
            isCompany(salutation)
              ? intl.formatMessage({
                id: 'contact-data-page.company-name.label',
              })
              : intl.formatMessage({
                id: 'contact-data-page.name.label',
              })
          }
        >
          <ControlWithHint error={validateName1(companyOrInsurantName, isValidationOn)}>
            <Input
              name="companyOrInsurantName"
              value={companyOrInsurantName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCompanyOrInsurantName(e.target.value)}
            />
          </ControlWithHint>
        </FormRow>
        <FormRow
          label={
            isCompany(salutation)
              ? intl.formatMessage({
                id: 'contact-data-page.company-name-affix.label',
              })
              : intl.formatMessage({
                id: 'contact-data-page.firstname.label',
              })
          }
        >
          <ControlWithHint error={validateName2(nameAffixOrInsurantFirstName, !isCompany(salutation), isValidationOn)}>
            <Input
              name="nameAffixOrInsurantFirstName"
              value={nameAffixOrInsurantFirstName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNameAffixOrInsurantFirstName(e.target.value)}
            />
          </ControlWithHint>
        </FormRow>
        <FormRow
          label={intl.formatMessage({
            id: 'contact-data-page.insurance-location.label',
          })}
          tooltip={<FormattedHTMLMessage id={EInfoMessages.I120} />}
        >
          <ControlWithHint
            error={
              validateRequiredStringLength(30, insurantAddress.street, isValidationOn) ||
              validateNoNumberOnEnd(insurantAddress.street)
            }
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'contact-data-page.insurance-location.street-name.placeholder',
              })}
              name="streetName"
              value={insurantAddress.street}
              /* eslint-disable-next-line max-len */
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInsurantAddress({ ...insurantAddress, ...{ street: e.target.value } })}
            />
          </ControlWithHint>
          <ControlWithHint
            error={
              validateNoSpaces(insurantAddress.houseNumber, isValidationOn) ||
              validateRequiredStringLength(10, insurantAddress.houseNumber, isValidationOn)
            }
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'business-type-page.business-details.location.house-number.placeholder',
              })}
              name="houseNumber"
              value={insurantAddress.houseNumber}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setInsurantAddress({ ...insurantAddress, ...{ houseNumber: e.target.value } });
              }}
            />
          </ControlWithHint>
        </FormRow>
        <FormRow label="" colspans={[1, 2]}>
          <ControlWithHint error={validateZipCode(insurantAddress.zipCode, isValidationOn)}>
            <Input
              placeholder={intl.formatMessage({
                id: 'contact-data-page.insurance-location.zip.placeholder',
              })}
              name="zip"
              pattern="[0-9]*"
              maxLength="5"
              minLength="5"
              value={insurantAddress.zipCode}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (isNumber(e.target.value) || e.target.value === '') {
                  setInsurantAddress({ ...insurantAddress, ...{ zipCode: e.target.value } });
                }
              }}
            />
          </ControlWithHint>
          <ControlWithHint error={validateRequiredStringLength(24, insurantAddress.city, isValidationOn)}>
            <Input
              placeholder={intl.formatMessage({
                id: 'contact-data-page.insurance-location.place.placeholder',
              })}
              name="place"
              value={insurantAddress.city}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInsurantAddress({ ...insurantAddress, ...{ city: e.target.value } })}
            />
          </ControlWithHint>
        </FormRow>
        <FormRow label="">
          <FormattedMessage id="contact-data-page.insurance-location.country.input-text" />
        </FormRow>
        {!isCompany(salutation) && (
          <FormRow
            label={intl.formatMessage({
              id: 'contact-data-page.date-of-birth.label',
            })}
          >
            <ControlWithHint error={validateDateOfBirth(dateOfBirth, isValidationOn)}>
              <DatePicker
                defaultDate={generateMaxDateOfBirth()}
                onChange={(e, date) => setDateOfBirth(date)}
                valueDate={dateOfBirth}
                maxDate={generateMaxDateOfBirth()}
                minDate={generateMinDateOfBirth()}
              />
            </ControlWithHint>
          </FormRow>
        )}
        <FormRow
          label={intl.formatMessage({
            id: 'contact-data-page.phone-number.label',
          })}
        >
          <ControlWithHint error={validatePhoneNumber(telephoneNumber, isValidationOn)}>
            <Input
              placeholder={intl.formatMessage({
                id: 'contact-data-page.phone-number.placeholder',
              })}
              name="telephoneNumber"
              value={telephoneNumber}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTelephoneNumber(e.target.value)}
            />
          </ControlWithHint>
        </FormRow>
        <FormRow
          label={intl.formatMessage({
            id: 'contact-data-page.email.label',
          })}
        >
          <ControlWithHint error={validateEmail(eMail, isValidationOn)}>
            <Input
              type="email"
              name="email"
              value={eMail}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEMail(e.target.value)}
            />
          </ControlWithHint>
        </FormRow>
        <FormRow
          label={intl.formatMessage({
            id: 'contact-data-page.confirm-email.label',
          })}
        >
          <ControlWithHint error={validateConfirmEmail(eMail, confirmEMail, isValidationOn)}>
            <Input
              type="email"
              name="confirmEmail"
              value={confirmEMail}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmEMail(e.target.value)}
            />
          </ControlWithHint>
        </FormRow>
        <FormRow
          label={intl.formatMessage({
            id: 'contact-data-page.customer-no.label',
          })}
        >
          <Input
            name="customerNo"
            value={customerNo}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCustomerNo(e.target.value)}
          />
        </FormRow>
      </FormSection>
    </AdvisoryTemplate>
  );
};

export const ContactDataPage = withI18n(ContactDataPageComponent, messages);
