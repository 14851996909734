import { IBusinessTypeDescription } from 'common/business-type-description.interface';
import { BusinessTypeDescription } from 'common/BusinessTypeDescription';
import { EStatus } from 'common/status.enum';

export interface IBusinessTypesResponse {
  status: EStatus;
  businessTypes: IBusinessTypeDescription[];
}

export class BusinessTypesResponseDto implements IBusinessTypesResponse {
  /* eslint-disable no-useless-constructor, no-empty-function */
  constructor(public status: EStatus, public businessTypes: BusinessTypeDescription[]) { }

  public static fromPayload({ status, businessTypes }: IBusinessTypesResponse): BusinessTypesResponseDto {
    return new BusinessTypesResponseDto(
      status,
      businessTypes ? businessTypes.map((businessType) => BusinessTypeDescription.fromPayload(businessType)) : [],
    );
  }
}
