import ControlWithHint from '@eg/elements/ControlWithHint';
import FormRow from '@eg/elements/FormRow';
import Input from '@eg/elements/Input';
import TextareaRow from '@eg/elements/TextareaRow';
import { EPlausiNr, ErrorMessage } from 'client/components/molecules/ErrorMessage/ErrorMessage';
import { messages } from 'client/components/organisms/DamagesForm/messages';
import { convertCurrencyFormatToNumber } from 'client/helpers/functions/convertCurrencyFormatToNumber';
import { validateRequiredField, validateRequiredStringLength } from 'client/helpers/functions/validations';
import { withI18n } from 'client/i18n/withI18n';
import { BusinessDataContext } from 'client/store/BusinessDataContext';
import { GlobalsContext } from 'client/store/GlobalsContext';
import { EInfoMessages } from 'common/EInfoMessages';
import React, {
  Dispatch, SetStateAction, useContext, useEffect,
} from 'react';
import { FormattedHTMLMessage, InjectedIntlProps } from 'react-intl';

interface IDamagesForm extends InjectedIntlProps {
  setIsFormValid: Dispatch<SetStateAction<boolean>>;
}

const DamagesFormComponent = ({ intl, setIsFormValid }: IDamagesForm) => {
  const {
    hadDamages,
    damagesQuantity,
    setDamagesQuantity,
    damagesOperationalExpenditure,
    setDamagesOperationalExpenditure,
    damagesDescription,
    setDamagesDescription,
  } = useContext(BusinessDataContext);

  const { setIsErrorMessage, isValidationOn } = useContext(GlobalsContext);

  const checkIsPredamagesErrorMessage = () => Number(damagesQuantity) > 1 || Number(damagesOperationalExpenditure) > 1000;

  // check for validation error
  useEffect(() => {
    setIsFormValid(
      !validateRequiredField(damagesQuantity) &&
      !validateRequiredField(damagesOperationalExpenditure) &&
      !validateRequiredStringLength(950, damagesDescription),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [damagesQuantity, damagesOperationalExpenditure, damagesDescription]);

  // check isErrorMessage on load of the component and each time if the properties are changed
  useEffect(() => {
    setIsErrorMessage(checkIsPredamagesErrorMessage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [damagesQuantity, damagesOperationalExpenditure]);

  return (
    <div data-testid="damagesForm" className={hadDamages ? '' : 'u-hidden'}>
      <FormRow
        label={intl.formatMessage({
          id: 'preinsurance-damages-page.damages.data.label',
        })}
        colspans={[1, 1]}
      >
        <ControlWithHint error={validateRequiredField(damagesQuantity, isValidationOn)}>
          <Input
            placeholder={intl.formatMessage({
              id: 'preinsurance-damages-page.damages.quantity.placeholder',
            })}
            value={damagesQuantity ? intl.formatNumber(damagesQuantity) : ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDamagesQuantity(convertCurrencyFormatToNumber(e.target.value))}
            name="quantity"
          />
        </ControlWithHint>
        <ControlWithHint error={validateRequiredField(damagesOperationalExpenditure, isValidationOn)}>
          <Input
            placeholder={intl.formatMessage({
              id: 'preinsurance-damages-page.damages.operational-expenditure.placeholder',
            })}
            value={damagesOperationalExpenditure ? intl.formatNumber(damagesOperationalExpenditure) : ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDamagesOperationalExpenditure(
              convertCurrencyFormatToNumber(e.target.value),
            )}
            name="operationalExpenditure"
            adornmentRight={intl.formatMessage({
              id: 'preinsurance-damages-page.damages.operational-expenditure.adornment',
            })}
          />
        </ControlWithHint>
      </FormRow>
      {checkIsPredamagesErrorMessage() && <ErrorMessage plausiNr={EPlausiNr.P003} />}
      <div className={checkIsPredamagesErrorMessage() ? 'u-hidden' : ''}>
        <TextareaRow
          label={intl.formatMessage({
            id: 'preinsurance-damages-page.damages.description.label',
          })}
          value={damagesDescription}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDamagesDescription(e.target.value)}
          placeholder={intl.formatMessage({
            id: 'preinsurance-damages-page.damages.description.placeholder',
          })}
          error={validateRequiredStringLength(950, damagesDescription, isValidationOn)}
          tooltip={<FormattedHTMLMessage id={EInfoMessages.I119} />}
        />
      </div>
    </div>
  );
};

export const DamagesForm = withI18n(DamagesFormComponent, messages);
