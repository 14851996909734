import { TariffResponseDto } from 'common/dto/tariff-response.dto';
import { EFormOfPayment } from 'common/form-of-payment.enum';
import { IPrice } from 'common/price.interface';
import { EStatus } from 'common/status.enum';

export class Tariff {
  // eslint-disable-next-line no-useless-constructor, no-empty-function
  constructor(public price: IPrice, public formOfPayment: EFormOfPayment, public isValid: boolean) { }

  public static fromTariffResponse(tariffResponse: TariffResponseDto | null) {
    if (tariffResponse && tariffResponse.price && tariffResponse.status === EStatus.Ok) {
      return new Tariff(tariffResponse.price, tariffResponse.formOfPayment, true);
    }
    return this.getDefaultTariff();
  }

  public static getDefaultTariff(): Tariff {
    return new Tariff(
      {
        netValue: 0, grossValue: 0, taxComponent: 0, minimumPremium: false,
      },
      EFormOfPayment.Yearly,
      false,
    );
  }
}
