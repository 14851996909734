/* eslint-disable import/prefer-default-export */
import { ESelectValues } from 'client/helpers/ESelectValues';
import { generateTomorrowDate } from 'client/helpers/functions/generateTomorrowDate';
import { ETransactionDate } from 'common/transaction-date.enum';

export class DirectDebitMandate {
  /* eslint-disable no-useless-constructor, no-empty-function */
  constructor(
    public inceptionDate: Date,
    public iban: string,
    public transactionDate: ETransactionDate | ESelectValues,
  ) { }

  public static getDefaultMandate(): DirectDebitMandate {
    return new DirectDebitMandate(generateTomorrowDate(), '', ESelectValues.PleaseSelect);
  }

  public setInceptionDate(inceptionDate: Date): DirectDebitMandate {
    return new DirectDebitMandate(inceptionDate, this.iban, this.transactionDate);
  }

  public setIban(iban: string): DirectDebitMandate {
    return new DirectDebitMandate(this.inceptionDate, iban, this.transactionDate);
  }

  public setTransactionDate(transactionDate: ETransactionDate | ESelectValues): DirectDebitMandate {
    return new DirectDebitMandate(this.inceptionDate, this.iban, transactionDate);
  }
}
