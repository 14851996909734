/* eslint-disable max-len */
export const messages = {
  // General
  'summary-page.data.heading': 'Zusammenfassung',
  'summary-page.data.text':
    'Bitte prüfen Sie Ihre Angaben, bevor Sie den Antrag an die ERGO schicken. Falls Sie Änderungen vornehmen möchten,<br/>klicken Sie bitte auf "Bearbeiten".',
  'summary-page.data.currency-value': '{value} €',
  // Business
  'summary-page.data.business.headline': 'Angaben zum Betrieb',
  'summary-page.data.business.business-type': 'Betriebart',
  'summary-page.data.business.risk-address': 'Risikoanschrift',
  'summary-page.building-details.is-inclosed-building.text':
    'Die versicherten Sachen befinden sich in einem allseitig umschlossenen Gebäude. Nicht zu Gebäuden zählen z. B. Zelte, Gewächshäuser, Schiffe, Container, Verkaufswagen und halboffene sowie mobile Gebäude.',
  'summary-page.building-details.has-security-measures.text':
    'Die versicherten Sachen müssen sich Räumen befinden, die für die Gefahr Einbruchdiebstahl bestimmte Anforderungen an Sicherungen erfüllen. Diese Sicherungsvereinbarungen gelten für Öffnungen in Wänden, Fußböden, Decken oder Dächern, die die Versicherungsräume begrenzen. Öffnungen sind z. B. Türen, Fenster und Tore. Alle Öffnungen sind durch einen mechanischen oder elektromechanischen Verschluss (z. B. Schlösser, Verriegelungen) gesichert.',
  'summary-page.yes': 'ja',
  // Contact
  'summary-page.data.contact.headline': 'Kontaktdaten des Antragstellers',
  'summary-page.data.contact.name': 'Name',
  'summary-page.data.contact.salutation': 'Anrede',
  'summary-page.data.contact.salutation.mr': 'Herr',
  'summary-page.data.contact.salutation.mrs': 'Frau',
  'summary-page.data.contact.salutation.divers': 'Keine Anrede/Divers',
  'summary-page.data.contact.address': 'Anschrift',
  'summary-page.data.contact.birthday': 'Geburtsdatum',
  'summary-page.data.contact.telephone': 'Telefon',
  'summary-page.data.contact.email': 'E-Mail',
  // Insurance
  'summary-page.data.insurance.headline': 'Geschäftsinhaltsversicherung',
  'summary-page.data.insurance.deductible': 'Selbstbeteiligung',
  'summary-page.data.insurance.deductible.none-info': 'Für {list} gilt eine Selbstbeteiligung von 500 €.',
  'summary-page.data.insurance.insurance-sum': 'Versicherungssumme',
  'summary-page.data.insurance.gp': 'Geschäftsinhaltsversicherung',
  'summary-page.data.insurance.extra-benefits': 'Zusätzliche Absicherung',
  'summary-page.data.insurance.extra-benefit.assistance': 'Service Plus',
  'summary-page.data.insurance.extra-benefit.electronics': 'Elektronik-Pauschal (Versicherungssumme: {insuranceSum})',
  'summary-page.data.insurance.extra-benefit.electronics.additional-coverage': ' inkl. Zusatzdeckung',
  'summary-page.data.insurance.extra-benefit.electronics.additional-info':
    ' (Selbstbeteiligung 250 € bzw. für mobile/beweglich einsetzbare Sachen bei Schäden durch Abhandenkommen infolge Diebstahl, Einbruchdiebstahl, Raub oder Plünderung außerhalb des Versicherungsorts 25 % des Schadens, mindestens 250 € je Versicherungsfall)',
  'summary-page.data.insurance.extra-benefit.glass': 'Verglasung',
  'summary-page.data.insurance.extra-benefit.loss-of-revenue-plus':
    'Ertragsausfall Plus (Entschädigungsgrenze: {rate})',
  'summary-page.data.insurance.risks': 'Versicherte Gefahren',
  'summary-page.data.insurance.risk.basic': 'Feuer, Einbruchdiebstahl, Leitungswasser, Sturm/Hagel',
  'summary-page.data.insurance.risk.flooding': 'Überschwemmung/Rückstau',
  'summary-page.data.insurance.risk.natural-hazard': 'Sonstige Naturgefahren',
  'summary-page.data.insurance.risk.extended-coverage': 'Extended Coverage Gefahren',
  'summary-page.data.insurance.risk.unnamed-dangers': 'Unbenannte Gefahren',
  'summary-page.data.insurance.risk.loss-revenue': 'Absicherung Ertragsausfall',
  'summary-page.data.insurance.risk.glass': 'Absicherung Ertragsausfall',
  'summary-page.data.insurance.risk.electronic': 'Absicherung Ertragsausfall',
  'summary-page.data.insurance.start.lable': 'Versicherungsbeginn',
  'summary-page.data.insurance.start.value': 'am {start}, mittags 12 Uhr',
  'summary-page.data.insurance.end.lable': 'Vertragsdauer',
  'summary-page.data.insurance.end.value': '1 Jahr',
  // Preinsurance
  'summary-page.data.preinsurance.headline': 'Angaben zur Vorversicherung',
  'summary-page.data.preinsurance.insurance': 'Gesellschaft: {insurance}',
  'summary-page.data.preinsurance.contractNumber': 'Nr. Versicherungsschein: {contractNumber}',
  'summary-page.data.preinsurance.cancelledBy': 'Gekündigt von: {cancelledBy}',
  'summary-page.data.preinsurance.gp': 'Geschäftsinhaltsversicherung',
  'summary-page.data.preinsurance.glass': 'Glas',
  'summary-page.data.preinsurance.electronic-flat': 'Elektronik-Pauschal',
  'summary-page.data.preinsurance.cancelled-by.insurant': 'Versicherungsnehmer',
  'summary-page.data.preinsurance.cancelled-by.insurance': 'Versicherer',
  'summary-page.data.preinsurance.cancelled-by.uncancelled': 'ungekündigt',
  'summary-page.data.preinsurance.none': 'keine',
  // Damages
  'summary-page.data.damages.headline': 'Vorschäden',
  'summary-page.data.damages.none': 'Keine',
  'summary-page.data.damages.quantity': 'Anzahl',
  'summary-page.data.damages.operational-expenditure': 'Gesamtaufwendungen',
  'summary-page.data.damages.description': 'Beschreibung',
  // Bank account
  'summary-page.data.bank-account.headline': 'SEPA-Lastschrift',
  'summary-page.data.bank-account.account-holder': 'Kontoinhaber',
  'summary-page.data.bank-account.iban': 'IBAN',
  'summary-page.data.bank-account.transaction-date': 'Erste Abbuchung am',
  // Print button
  'summary-page.print.button': 'Zusammenfassung drucken',
};
