import Button from '@eg/elements/Button';
import Modal from '@eg/elements/Modal';
import { NavigationUrls } from 'client/helpers/NavigationUrls';
import { withI18n } from 'client/i18n/withI18n';
import { history } from 'client/routers/history';
import { AgencyContext } from 'client/store/AgencyContext';
import { GlobalsContext } from 'client/store/GlobalsContext';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { consultModelMessages } from './messages';

const ConsultModalComponent = () => {
  const { isConsultModelOpen, setIsConsultModelOpen } = useContext(GlobalsContext);
  const { agency } = useContext(AgencyContext);
  const advisoryUrl =
    agency && agency.homepage ? agency.homepage : 'https://www.ergo.de/de/ResourcesUsability/Vermittlersuche';
  return (
    <Modal open={isConsultModelOpen} onDismiss={() => setIsConsultModelOpen(false)} dismissible>
      <h3 className="esc_h3">
        <FormattedMessage id="consult-modal.header" />
      </h3>
      <p className="u-margin-top-">
        <FormattedMessage id="consult-modal.p1" />
      </p>
      <p>
        <FormattedMessage id="consult-modal.p2" />
      </p>
      <div className="navigation-buttons__wrapper u-padding-top-m">
        <Button
          className="navigation-buttons__next-button"
          type="button"
          variant="primary"
          onClick={() => {
            setIsConsultModelOpen(false);
            history.push(NavigationUrls.preinsuranceDamagesPageUrl);
          }}
        >
          <FormattedMessage id="consult-modal.next.btn" />
        </Button>
        <Button
          variant="secondary"
          type="button"
          onClick={() => {
            setIsConsultModelOpen(false);
            window.open(advisoryUrl);
          }}
        >
          <FormattedMessage id="consult-modal.i-wich.btn" />
        </Button>
      </div>
    </Modal>
  );
};

export const ConsultModal = withI18n(ConsultModalComponent, consultModelMessages);
