import { MAX_INSURANCE_SUM, TARIFF_REQUEST_DELAY } from 'client/helpers/constants';
import { validateInsuranceSum } from 'client/helpers/functions/validations';
import { Tariff } from 'client/model/Tariff';
import { getTariff } from 'client/store/api';
/* eslint-disable import/no-cycle */
import { BusinessDataContext } from 'client/store/BusinessDataContext';
import { mapAddressToDto } from 'common/address.interface';
import { EBusinessTypeRequestLevel } from 'common/business-type-request-level.enum';
import { BusinessContent } from 'common/BusinessContent';
import { TariffRequestDto } from 'common/dto/tariff-request.dto';
import { ERisks } from 'common/risks.enum';
import React, {
  createContext, Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';

import { BusinessTypesContext } from './BusinessTypesContext';
import { ConsumerTypeContext } from './ConsumerTypeContext';
import { RiskAddressContext } from './RiskAddressContext';

export interface ITariffContext {
  tariff: Tariff;
  setTariff: Dispatch<SetStateAction<Tariff>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isError: boolean;
}

const TariffContext = createContext<ITariffContext>({
  tariff: Tariff.getDefaultTariff(),
  isLoading: false,
  isError: false,
  setTariff: () => { },
  setIsLoading: () => { },
});

const { Provider } = TariffContext;

interface ITariffProvider {
  children: React.ReactNode;
}

const TariffProvider = ({ children }: ITariffProvider) => {
  const {
    address,
    insuranceSum,
    risks,
    lossOfRevenuePlus,
    glass,
    electronics,
    businessType,
    formOfPayment,
    deductible,
    isInclosedBuilding,
    hasSecurityMeasures,
  } = useContext(BusinessDataContext);
  const { businessTypeDescriptions } = useContext(BusinessTypesContext);
  const { riskAddressState } = useContext(RiskAddressContext);
  const { consumerType } = useContext(ConsumerTypeContext);

  const [tariff, setTariff] = useState(Tariff.getDefaultTariff());
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(true);
  const [delayTimerId, setDelayTimerId] = useState(0);

  const doTariffRequest = (tariffRequest: TariffRequestDto) => {
    if (!tariffRequest) {
      return;
    }

    setIsLoading(true);

    if (delayTimerId) {
      window.clearTimeout(delayTimerId);
    }

    setDelayTimerId(
      window.setTimeout(() => {
        getTariff(tariffRequest).then((newTariffResponse) => {
          // skip old response
          if (!newTariffResponse.payload && !newTariffResponse.error) {
            return;
          }
          setIsLoading(false);
          setTariff(Tariff.fromTariffResponse(newTariffResponse.payload));
          setIsError(!!newTariffResponse.error);
        });
      }, TARIFF_REQUEST_DELAY),
    );
  };

  useEffect(() => {
    // TODO: check also here GPON-148: as a user, I should to be notified, that the Tariffberechnung is not possible
    // and some options are still required. Otherwise the user is confused.

    // only do a request if we have sufficient information. otherwise, the api returns an error message for sure.
    if (!businessType || !businessTypeDescriptions || !businessTypeDescriptions.length) {
      return;
    }
    const businessTypeDescription = businessTypeDescriptions.find((value) => value.businessTypeDescription === businessType);
    if (!businessTypeDescription || businessTypeDescription.requestLevel !== EBusinessTypeRequestLevel.CALC) {
      return;
    }
    if (!insuranceSum || validateInsuranceSum(insuranceSum, MAX_INSURANCE_SUM)) {
      setIsError(true);
      return;
    }
    if (!address || !address.street || !address.zipCode || !address.city) {
      return;
    }
    if (!isInclosedBuilding || !hasSecurityMeasures) {
      return;
    }

    const dtoAddress = mapAddressToDto(address);

    const businessContent = new BusinessContent(
      businessTypeDescription.businessTypeId,
      insuranceSum,
      deductible,
      { address: dtoAddress },
      [],
      isInclosedBuilding,
      hasSecurityMeasures,
      riskAddressState.zuersClass,
      riskAddressState.hazardZone,
    );

    if (risks.fews) {
      businessContent.risks.push(ERisks.Fire);
      businessContent.risks.push(ERisks.Burglary);
      businessContent.risks.push(ERisks.PipeWater);
      businessContent.risks.push(ERisks.StormHail);
    }
    if (risks.flooding) {
      businessContent.risks.push(ERisks.Flooding);
    }
    if (risks.extendedCoverage) {
      businessContent.risks.push(ERisks.ExtendedCoverage);
    }
    if (risks.naturalHazard) {
      businessContent.risks.push(ERisks.NaturalHazard);
    }
    if (risks.unnamedDangers) {
      businessContent.risks.push(ERisks.Unnamed);
    }
    if (risks.lossRevenue) {
      businessContent.risks.push(ERisks.LossRevenue);
    }
    if (risks.assistance) {
      businessContent.risks.push(ERisks.Assistance);
    }

    if (electronics.isSelected && electronics.insuranceSum) {
      businessContent.setElectronics(electronics.insuranceSum, electronics.isAdditionalCoverageSelected);
    }

    if (lossOfRevenuePlus.isSelected) {
      businessContent.setLossOfRevenuePlus(lossOfRevenuePlus.rate);
    }

    if (glass) {
      businessContent.setGlass(glass);
    }

    const newRequest = new TariffRequestDto(formOfPayment, businessContent, consumerType);

    doTariffRequest(newRequest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    address,
    insuranceSum,
    risks,
    lossOfRevenuePlus,
    glass,
    electronics,
    businessType,
    formOfPayment,
    deductible,
    isInclosedBuilding,
    hasSecurityMeasures,
  ]);

  const newValue: ITariffContext = {
    tariff,
    isLoading,
    isError,
    setTariff,
    setIsLoading,
  };

  return <Provider value={newValue}>{children}</Provider>;
};

export { TariffContext, TariffProvider };
