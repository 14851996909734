import ControlWithHint from '@eg/elements/ControlWithHint';
import FormRow from '@eg/elements/FormRow';
import FormSection from '@eg/elements/FormSection';
import Input from '@eg/elements/Input';
import MessageBox from '@eg/elements/MessageBox';
import Select from '@eg/elements/Select';
import { ExtendedCoverageIcon } from 'client/components/atoms/Icons/ExtendedCoverageIcon';
import { FireIcon } from 'client/components/atoms/Icons/FireIcon';
import { FloodingIcon } from 'client/components/atoms/Icons/FloodingIcon';
import { NaturalHazardIcon } from 'client/components/atoms/Icons/NaturalHazardIcon';
import { OperatingCostsIcon } from 'client/components/atoms/Icons/OperatingCostsIcon';
import { UnnamedIcon } from 'client/components/atoms/Icons/Unnamed';
import { CheckboxTile } from 'client/components/molecules/CheckboxTile/CheckboxTile';
import { ContributionBox } from 'client/components/molecules/ContributionsBox/ContributionBox';
import { EPlausiNr, ErrorMessage } from 'client/components/molecules/ErrorMessage/ErrorMessage';
import { AdvisoryTemplate } from 'client/components/templates/AdvisoryTemplate/AdvisoryTemplate';
import { MAX_INSURANCE_SUM } from 'client/helpers/constants';
import { collectSelectedRisks } from 'client/helpers/functions/collectSelectedRisks';
import { convertCurrencyFormatToNumber } from 'client/helpers/functions/convertCurrencyFormatToNumber';
import { roundCurrencyInStepsOf100 } from 'client/helpers/functions/roundCurrencyInStepsOf100';
import { showDeductibleInfo } from 'client/helpers/functions/showDeductibleInfo';
import { validateInsuranceSum } from 'client/helpers/functions/validations';
import { translateDeductible } from 'client/i18n/translateEnums';
import { withI18n } from 'client/i18n/withI18n';
import { BusinessDataContext } from 'client/store/BusinessDataContext';
import { GlobalsContext } from 'client/store/GlobalsContext';
import { RiskAddressContext } from 'client/store/RiskAddressContext';
import { EDeductible } from 'common/deductible.enum';
import { EInfoMessages } from 'common/EInfoMessages';
import React, { useContext, useEffect } from 'react';
import { FormattedHTMLMessage, FormattedMessage, InjectedIntlProps } from 'react-intl';

import { messages } from './messages';
import styles from './styles.module.scss';

const ContributionPageComponent = ({ intl }: InjectedIntlProps) => {
  const {
    insuranceSum, setInsuranceSum, deductible, setDeductible, risks, setRisks,
  } = useContext(BusinessDataContext);

  const { riskAddressState } = useContext(RiskAddressContext);

  const { setIsErrorMessage, isValidationOn, setIsValidationError } = useContext(GlobalsContext);

  const hasInsuranceSumException = () => insuranceSum && insuranceSum > MAX_INSURANCE_SUM;

  const hasFloodingException = () => !riskAddressState.canCalcByZuersClass() && risks.flooding;

  const hasHazardException = () => !riskAddressState.canCalcByHazardZone() && risks.naturalHazard;

  // check for validation error
  useEffect(() => {
    setIsValidationError(
      !!validateInsuranceSum(insuranceSum, MAX_INSURANCE_SUM) || hasFloodingException() || hasHazardException(),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insuranceSum]);

  // check isErrorMessage on load of the component
  useEffect(() => {
    setIsErrorMessage(hasInsuranceSumException() || hasFloodingException() || hasHazardException());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insuranceSum, risks.flooding, risks.naturalHazard]);

  // helper for a label, as eslint / prettier autofix where at their limits
  const LabelHelper = ({ id }: any) => <div className={styles.fatLabel}>{intl.formatMessage({ id })}</div>;

  return (
    <AdvisoryTemplate>
      <FormSection
        heading={intl.formatMessage({
          id: 'contribution-page.contribution-calculation-form.title',
        })}
        className={styles.emptyFormSection}
      />

      <FormSection className={`u-margin-bottom-mobile-m ${styles.paddingFix} ${styles.pseudoLabel}`}>
        <FormRow
          label={<LabelHelper id="contribution-page.contribution-calculation-form.select-sum.label" />}
          tooltip={<FormattedHTMLMessage id={EInfoMessages.I104} />}
        >
          <ControlWithHint
            error={validateInsuranceSum(insuranceSum, MAX_INSURANCE_SUM, isValidationOn)}
            description={intl.formatMessage({
              id: 'validation.description.insurance-sum',
            })}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'contribution-page.contribution-calculation-form.select-sum.placeholder',
              })}
              name="insuranceSum"
              value={insuranceSum ? intl.formatNumber(insuranceSum) : ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInsuranceSum(convertCurrencyFormatToNumber(e.target.value))}
              adornmentRight="€"
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => setInsuranceSum(roundCurrencyInStepsOf100(e.target.value))}
              data-testid="insuranceSum"
            />
          </ControlWithHint>
        </FormRow>
        {hasInsuranceSumException() && <ErrorMessage plausiNr={EPlausiNr.P003} />}
      </FormSection>

      <div
        className={`esc_grid ${hasInsuranceSumException() ? 'u-hidden' : ''}`}
        style={{ paddingLeft: '12px', paddingRight: '12px' }}
      >
        <div className="esc_grid__wrapper">
          <div className={`esc_col-s-12 esc_col-m-6 ${styles.contributionBoxCol}`}>
            <ContributionBox className={styles.contributionBox} />
            <div className={styles.lastChildElement} />
          </div>
          <div className="esc_col-s-12 esc_col-m-6">
            <FormSection
              heading={intl.formatMessage({
                id: 'contribution-page.business-content.title',
              })}
              className={styles.formSectionBorder}
            >
              <CheckboxTile
                icon={FireIcon}
                label={intl.formatMessage({
                  id: 'contribution-page.business-content.risk-tile.basic.title',
                })}
                value={risks.fews}
                onChange={() => setRisks(risks.setFews(!risks.fews))}
                isDisabled
                tooltip={<FormattedHTMLMessage id={EInfoMessages.I106} />}
              />
              <CheckboxTile
                icon={FloodingIcon}
                label={intl.formatMessage({
                  id: 'contribution-page.business-content.risk-tile.flooding.title',
                })}
                dataTestid="floodingSelect"
                className={styles.nextCheckboxTile}
                value={risks.flooding}
                onChange={() => setRisks(risks.setFlooding(!risks.flooding))}
                isDisabled={!riskAddressState.canCalcByZuersClass() && !hasFloodingException()}
                tooltip={<FormattedHTMLMessage id={EInfoMessages.I107} />}
              />
              <CheckboxTile
                icon={NaturalHazardIcon}
                label={intl.formatMessage({
                  id: 'contribution-page.business-content.risk-tile.natural-hazard.title',
                })}
                dataTestid="naturalHazardSelect"
                className={styles.nextCheckboxTile}
                value={risks.naturalHazard}
                onChange={() => setRisks(risks.setNaturalHazard(!risks.naturalHazard))}
                isDisabled={!riskAddressState.canCalcByHazardZone() && !hasHazardException()}
                tooltip={<FormattedHTMLMessage id={EInfoMessages.I108} />}
              />
              <CheckboxTile
                icon={ExtendedCoverageIcon}
                label={intl.formatMessage({
                  id: 'contribution-page.business-content.risk-tile.extended-coverage.title',
                })}
                dataTestid="extendedCoverageSelect"
                className={styles.nextCheckboxTile}
                value={risks.extendedCoverage}
                onChange={() => setRisks(risks.setExtendedCoverage(!risks.extendedCoverage))}
                tooltip={<FormattedHTMLMessage id={EInfoMessages.I109} />}
              />
              <CheckboxTile
                icon={UnnamedIcon}
                label={intl.formatMessage({
                  id: 'contribution-page.business-content.risk-tile.unnamed-dangers.title',
                })}
                dataTestid="unnamedDangersSelect"
                className={styles.nextCheckboxTile}
                value={risks.unnamedDangers}
                onChange={() => setRisks(risks.setUnnamedDangers(!risks.unnamedDangers))}
                tooltip={<FormattedHTMLMessage id={EInfoMessages.I110} />}
              />
              <CheckboxTile
                icon={OperatingCostsIcon}
                label={intl.formatMessage({
                  id: 'contribution-page.business-content.risk-tile.loss-revenue.title',
                })}
                value={risks.lossRevenue}
                isDisabled
                className={`${styles.nextCheckboxTile} u-margin-top-m`}
                tooltip={<FormattedHTMLMessage id={EInfoMessages.I111} />}
              />
            </FormSection>
          </div>
        </div>
      </div>
      <div>
        {riskAddressState.showPlausi008() && !riskAddressState.showPlausi009() && (
          <ErrorMessage plausiNr={EPlausiNr.P008} isWarning={!hasFloodingException()} />
        )}
        {riskAddressState.showPlausi009() && !riskAddressState.showPlausi008() && (
          <ErrorMessage plausiNr={EPlausiNr.P009} isWarning={!hasHazardException()} />
        )}
        {riskAddressState.showPlausi008() && riskAddressState.showPlausi009() && (
          <ErrorMessage
            plausiNr={EPlausiNr.CombinedP008P009}
            isWarning={!hasFloodingException() && !hasHazardException()}
          />
        )}
      </div>

      <FormSection className={`${styles.pseudoLabel} ${hasInsuranceSumException() ? 'u-hidden' : ''}`}>
        <FormRow
          label={<LabelHelper id="contribution-page.contribution-calculation-form.select-deductible.label" />}
          tooltip={<FormattedHTMLMessage id={EInfoMessages.I105} />}
        >
          <Select
            data-testid="deductibleSelect"
            value={deductible}
            onChange={(e: any) => setDeductible(e.target.value)}
          >
            <option value={EDeductible.None}>{translateDeductible(EDeductible.None)}</option>
            <option value={EDeductible.Fixed500}>{translateDeductible(EDeductible.Fixed500)}</option>
          </Select>
        </FormRow>
        <MessageBox
          data-testid="deductibleMessageBox"
          className={showDeductibleInfo(risks, deductible) ? '' : 'u-hidden'}
        >
          <FormattedMessage
            id="contribution-page.contribution-calculation-form.select-deductibl.info"
            values={{ list: collectSelectedRisks(risks, intl) }}
          />
        </MessageBox>
      </FormSection>
      {!riskAddressState.showPlausi008() && !riskAddressState.showPlausi009() && !hasInsuranceSumException() && (
        <ContributionBox className={styles.contributionBoxMobile} />
      )}
    </AdvisoryTemplate>
  );
};

export const ContributionPage = withI18n(ContributionPageComponent, messages);
