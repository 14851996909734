/* eslint-disable max-len */
export const messages = {
  'contact-data-page.heading': 'Kontaktdaten des Antragstellers',
  'contact-data-page.salutation.label': 'Anrede*',
  'contact-data-page.salutation.options.please-select': '-- Bitte auswählen --',
  'contact-data-page.salutation.options.company': 'Firma',
  'contact-data-page.salutation.options.mr': 'Herr',
  'contact-data-page.salutation.options.mrs': 'Frau',
  'contact-data-page.salutation.options.divers': 'Keine Anrede/Divers',
  'contact-data-page.name.label': 'Nachname*',
  'contact-data-page.firstname.label': 'Vorname*',
  'contact-data-page.company-name.label': 'Firmenname*',
  'contact-data-page.company-name-affix.label': 'Namenszusatz',
  'contact-data-page.insurance-location.label': 'Anschrift*',
  'contact-data-page.insurance-location.street-name.placeholder': 'Straße',
  'contact-data-page.insurance-location.zip.placeholder': 'PLZ',
  'contact-data-page.insurance-location.place.placeholder': 'Ort',
  'contact-data-page.insurance-location.country.input-text': 'Deutschland',
  'contact-data-page.date-of-birth.label': 'Geburtsdatum*',
  'contact-data-page.phone-number.label': 'Telefonnummer*',
  'contact-data-page.phone-number.placeholder': 'Vorwahl/Rufnummer',
  'contact-data-page.email.label': 'E-Mail*',
  'contact-data-page.confirm-email.label': 'E-Mail wiederholen*',
  'contact-data-page.customer-no.label': 'ERGO Kundennummer (falls vorhanden)',
};
