import Button from '@eg/elements/Button';
import Checkbox from '@eg/elements/Checkbox';
import FormFooter from '@eg/elements/FormFooter';
import FormSection from '@eg/elements/FormSection';
import RiskIcon from '@eg/elements/Icons/RiskIcon';
import Price from '@eg/elements/Price';
import { PremiumIconBox } from 'client/components/molecules/PremiumIconBox/PremiumIconBox';
import { ServerErrorModal } from 'client/components/molecules/ServerErrorModal/ServerErrorModal';
import { SummaryContent } from 'client/components/molecules/SummaryContent/SummaryContent';
import { translate } from 'client/components/molecules/TariffPriceBox/TariffPriceBox';
import { AdvisoryTemplate } from 'client/components/templates/AdvisoryTemplate/AdvisoryTemplate';
import { validateRequiredField } from 'client/helpers/functions/validations';
import { withI18n } from 'client/i18n/withI18n';
import { BusinessDataContext } from 'client/store/BusinessDataContext';
import { DocumentContext } from 'client/store/DocumentContext';
import { GlobalsContext } from 'client/store/GlobalsContext';
import { SendOrderContext } from 'client/store/SendOrderContext';
import { TariffContext } from 'client/store/TariffContext';
import React, { useContext, useEffect } from 'react';
import { FormattedHTMLMessage, FormattedMessage, InjectedIntlProps } from 'react-intl';

import { messages } from './messages';
import styles from './styles.module.scss';

const SummaryPageComponent = ({ intl }: InjectedIntlProps) => {
  const { advertisementPermission, setAdvertisementPermission } = useContext(BusinessDataContext);
  const { error: sendOrderErrorId, requestSendOrder } = useContext(SendOrderContext);
  const {
    hasAcceptedConsultingWaiver,
    hasAcceptedPrivacyPolicy,
    hasAcceptedTermsConditions,
    hasDownloadedDocument,
    getDocumentLink,
    setHasAcceptedConsultingWaiver,
    setHasAcceptedPrivacyPolicy,
    setHasAcceptedTermsConditions,
    setHasDownloadedDocument,
  } = useContext(DocumentContext);
  const downloadButtonDisabled = !(
    hasAcceptedConsultingWaiver &&
    hasAcceptedPrivacyPolicy &&
    hasAcceptedTermsConditions
  );

  const { tariff } = useContext(TariffContext);
  const { isValidationOn, setIsValidationError, setIsServerErrorModelOpen } = useContext(GlobalsContext);

  useEffect(() => {
    setIsServerErrorModelOpen(!!sendOrderErrorId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendOrderErrorId]);

  // check for validation error
  useEffect(() => {
    setIsValidationError(
      !!validateRequiredField(hasAcceptedConsultingWaiver) ||
      !!validateRequiredField(hasAcceptedPrivacyPolicy) ||
      !!validateRequiredField(hasAcceptedTermsConditions) ||
      !!validateRequiredField(hasDownloadedDocument),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAcceptedConsultingWaiver, hasAcceptedPrivacyPolicy, hasAcceptedTermsConditions, hasDownloadedDocument]);

  return (
    <AdvisoryTemplate>
      {/* Tariff information */}
      <FormSection
        heading={intl.formatMessage({
          id: 'summary-page.actual-tariff.heading',
        })}
      >
        <PremiumIconBox
          icon={RiskIcon}
          title={intl.formatMessage({
            id: 'summary-page.actual-tariff.box.title',
          })}
        />
      </FormSection>

      {/* User data summary */}
      <SummaryContent />

      {/* Confirm and download documents */}
      <FormSection
        className="print-hide"
        heading={intl.formatMessage({
          id: 'summary-page.document.heading',
        })}
      >
        <Checkbox
          label={intl.formatMessage({
            id: 'summary-page.terms-condititions.label',
          })}
          error={!!validateRequiredField(hasAcceptedTermsConditions, isValidationOn)}
          checked={hasAcceptedTermsConditions}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHasAcceptedTermsConditions(e.target.checked)}
        />
        <Checkbox
          label={intl.formatMessage({
            id: 'summary-page.consulting-waiver.label',
          })}
          error={!!validateRequiredField(hasAcceptedConsultingWaiver, isValidationOn)}
          checked={hasAcceptedConsultingWaiver}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHasAcceptedConsultingWaiver(e.target.checked)}
        />
        <Checkbox
          label={intl.formatMessage({
            id: 'summary-page.privacy-policy.label',
          })}
          error={!!validateRequiredField(hasAcceptedPrivacyPolicy, isValidationOn)}
          checked={hasAcceptedPrivacyPolicy}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHasAcceptedPrivacyPolicy(e.target.checked)}
        />
        <div className={styles.downloadButtonWrapper}>
          {!downloadButtonDisabled ? (
            <a
              className="esc_button esc_button--primary"
              href={getDocumentLink(true)}
              onClick={() => setHasDownloadedDocument(true)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="summary-page.document.button" />
            </a>
          ) : (
            <Button variant="disabled">
              <FormattedMessage id="summary-page.document.button" />
            </Button>
          )}
        </div>
      </FormSection>

      {/* Advertisement permission */}
      <FormSection
        className="print-hide"
        heading={intl.formatMessage({
          id: 'summary-page.advertisement-permission.headline',
        })}
      >
        <Checkbox
          label={intl.formatMessage({
            id: 'summary-page.advertisement-permission.text',
          })}
          checked={advertisementPermission}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAdvertisementPermission(e.target.checked)}
        />
        <FormFooter>
          <FormattedHTMLMessage id="summary-page.advertisement-permission.footnote" />
        </FormFooter>
      </FormSection>

      {/* Send order summary */}
      <FormSection className="print-hide">
        <p className={styles.sendOrderWrapper}>
          <FormattedMessage id="summary-page.send-order.text" />
          <Price value={tariff.price.grossValue} suffix={translate(tariff.formOfPayment)} />
        </p>
      </FormSection>
      <ServerErrorModal
        className="print-hide"
        message={intl.formatMessage({ id: 'summary-page.send-order.server-error' })}
        action={requestSendOrder}
      />
    </AdvisoryTemplate>
  );
};

export const SummaryPage = withI18n(SummaryPageComponent, messages);
