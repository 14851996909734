export const messages: { [key: string]: string } = {
  /* eslint-disable max-len */
  'plausi.p001.message': 'Für “{businessType}” bieten wir derzeit online keinen Versicherungsschutz an.',
  'plausi.p002.message': 'Für “{businessType}” bieten wir online keinen Versicherungsschutz an.',
  'plausi.p003.message': 'Bei dieser Angabe ist ein Onlineabschluss nicht möglich.',
  'plausi.p004.message':
    'Der Onlineabschluss einer Geschäftsinhaltsversicherung ist nur möglich, wenn die beschriebenen Sicherungen vorhanden sind.',
  'plausi.p005.message': 'Bitte prüfen Sie Ihre Eingabe.',
  'plausi.p006.message': 'Wir können die Anschrift nicht zuordnen. Bitte prüfen Sie die eingegebene Adresse.',
  'plausi.p006.contact.text':
    'Sofern Sie sicher sind, dass die Anschrift richtig ist und Sie die Gefahr „Überschwemmung/Rückstau“ absichern möchten, wenden Sie sich bitte an unseren Kundenservice unter der Telefonnummer',
  'plausi.p007.message':
    'Sofern Sie diese Frage nicht mit „ja“ beantworten können, ist der Onlineabschluss einer Geschäftsversicherung nicht möglich.',
  'plausi.p008.message':
    'Die Anschrift liegt in einem Gebiet mit einer hohen Gefährdung für die Gefahr „Überschwemmung/Rückstau“. Falls Sie diese Gefahr absichern möchten, ist eine individuelle Risikoprüfung erforderlich.',
  'plausi.p009.message':
    'Die Anschrift liegt in einem Gebiet mit einer hohen Gefährdung für die Gefahr „Sonstige Naturgefahren“. Falls Sie diese Gefahr absichern möchten, ist eine individuelle Risikoprüfung erforderlich.',
  'contact.text': 'Bitte wenden Sie sich an unseren Kundenservice unter der Telefonnummer:',
  'contact.phone-number': 'Tel. 0800/3746-302',
  'contact.phone-number.p002': 'Tel. 0800/3746-514',
  'contact.mail.text': 'Oder senden Sie uns eine E-Mail an:',
  'contact.mail.link': 'KBI-Sach-Muenchen@ergo.de',
  'contact.mail.title': 'KBI Gruppenpostfach',
  'plausi.comb008009.message':
    'Die Anschrift liegt in einem Gebiet mit einer hohen Gefährdung für die Gefahren „Überschwemmung/Rückstau“ und „Sonstige Naturgefahren“. Falls Sie diese Gefahren absichern möchten, ist eine individuelle Risikoprüfung erforderlich.',
};
