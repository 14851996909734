/* eslint-disable max-len */
export const messages = {
  'contribution-box.title': 'Geschäftsinhaltsversicherung ',
  'contribution-box.subtitle': 'Leistungen Geschäftsinhaltsversicherung',
  'contribution-box.text-1':
    'Bei Zerstörung oder Beschädigung Ihrer Einrichtung, Vorräte oder Waren ersetzen wir Ihren finanziellen Schaden. Und das meist zum Neuwert, damit bei der Neuanschaffung keine weiteren Kosten auf Sie zukommen.',
  'contribution-box.text-2':
    'Ist die Leistung Ihres Betriebs nach einem Sachschaden eingeschränkt oder steht sogar still, ersetzen wir die fortlaufenden Kosten und den entgangenen Gewinn.',
  'contribution-box.text-3':
    'Abrundung des Versicherungsschutzes durch Unterversicherungs- und Quotelungsverzicht für Sch&auml;den bis 50.000&nbsp;&euro;.',
  'contribution-box.link': 'Alle Produktdetails',
};
