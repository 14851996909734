import { generateMaxDateOfBirth } from 'client/helpers/functions/generateMaxDateOfBirth';
import { generateMinDateOfBirth } from 'client/helpers/functions/generateMinDateOfBirth';
import { validateRequiredField } from 'client/helpers/functions/validations/validateRequiredField';
import { validationMessages } from 'client/i18n/validationMessages';
import { toISODate } from 'common/helper/toISODate';
import moment from 'moment';

const isValidDateOfBirth = (dateOfBirth: Date | '') => (
  dateOfBirth !== '' &&
  moment(toISODate(dateOfBirth)).isSameOrAfter(toISODate(generateMinDateOfBirth())) &&
  moment(toISODate(dateOfBirth)).isSameOrBefore(toISODate(generateMaxDateOfBirth()))
);

export const validateDateOfBirth = (dateOfBirth: Date | '', isValidationOn = true) => {
  let isValid: string | boolean = true;
  isValid = validateRequiredField(dateOfBirth, isValidationOn);
  if (!isValid && isValidationOn && !isValidDateOfBirth(dateOfBirth)) {
    isValid = validationMessages['validation.error.date-of.birth-is-not-valid'];
  }
  return isValid;
};
