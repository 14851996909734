import { defaultConsumerType, EConsumer } from 'common/consumer.enum';

interface IBusinessTypeRequestDto {
  consumer?: EConsumer;
}

export class BusinessTypeRequestDto {
  consumer: EConsumer;

  constructor(consumerType: EConsumer) {
    this.consumer = consumerType;
  }

  public static fromRequest(payload: IBusinessTypeRequestDto) {
    return new BusinessTypeRequestDto(payload.consumer || defaultConsumerType);
  }
}
