/* eslint-disable max-len */
import { ErgoElementsContext } from '@eg/elements/Provider';
import SvgIcon from '@eg/elements/SvgIcon';
import React, { useContext } from 'react';

interface INaturalHazardProps {
  width?: number;
  height?: number;
}

export const NaturalHazardIcon = ({ width, height }: INaturalHazardProps) => {
  const { idGenerator } = useContext(ErgoElementsContext);

  return (
    <SvgIcon idGenerator={idGenerator} title="fire" viewBox="0 0 52 52" width={width} height={height}>
      <path d="m6.4 2.2h39c2.2 0 4 1.8 4 4v39c0 2.2-1.8 4-4 4h-39c-2.2 0-4-1.8-4-4v-39c0-2.2 1.8-4 4-4z" fill="none" />
      <g>
        <path d="m31.5 29c0-.2-.2-.4-.4-.5-.1 0-.2 0-.4 0-.4.1-.8.1-1.2.2-.1 0-.2 0-.4 0 0-.3-.1-.5-.1-.8-.3-2.1-.6-4.3-.9-6.4 0-.1.1 0 .1 0 .1.1.3.2.5.2.3 0 .5-.2.5-.4 0-.1 0-.3 0-.4-.1-.1-.2-.3-.3-.4-.9-.8-1.7-1.5-2.6-2.3-.3-.2-.5-.5-.7-.7-1.2-1.1-2.5-2.3-3.8-3.4-.2-.2-.4-.4-.6-.5-.2-.2-.6-.1-.8.1-.1.1-.2.3-.3.4-1.1 1.9-2.3 3.9-3.5 5.8-.3.4-.5.8-.8 1.2-.2.4-.5.8-.8 1.2-.1.2-.2.3-.2.5s0 .4.2.6c.1.1.2.1.3.2.1 0 .3 0 .4-.1s.2-.2.2-.3c.3 2 .5 4 .8 6 .1.4.1.8.2 1.3-.7.1-1.5.2-2.2.3-.3 0-.5.4-.4.7.1.2.3.4.6.4 5.3-.7 10.5-1.4 15.8-2.1.2 0 .3 0 .5-.1.2-.3.3-.5.3-.7zm-7 .3c-1 .1-1.9.3-2.9.4-.1 0-.3 0-.4 0v-.1c-.2-1.3-.3-2.6-.5-3.8 0-.2-.1-.4-.1-.6 0-.3.3-.5.6-.5.8-.1 1.5-.2 2.3-.3h.4c.2 0 .3.1.4.3s.1.4.1.5c.1.4.1.9.2 1.3.1.7.2 1.4.3 2.2 0 .2.1.4.1.5-.2.1-.3.1-.5.1z" />
        <path d="m28 31.5c-.2.2-.3.3-.5.5-1 1-2 2.1-2.9 3.1-.1.1-.3.3-.4.4h4.8c-.1.1-.2.1-.2.2-1.1.9-2.2 1.8-3.4 2.7-.1.1-.2.1-.3.2-.1 0-.2 0-.2 0-.7 0-1.5 0-2.2 0-.1 0-.2 0-.3 0-1 0-2.1 0-3.1 0-.1 0-.2 0-.4 0-1.1 0-2.2 0-3.3 0-.1 0-.2 0-.3 0-.7 0-1.4 0-2 0v-5.1c.2 0 .4-.1.7-.1 4.5-.6 9-1.2 13.5-1.9.1.1.3 0 .5 0z" />
        <path d="m30.6 31.9c2.8.5 5.5 1.1 8.3 1.6h.1v5.1c-1.9 0-3.8 0-5.7 0-.1 0-.2 0-.3 0-.1-.1-.1-.2-.2-.3-.3-.5-.6-1.1-.9-1.6 0-.1 0-.1 0-.2.2-1 .4-2 .7-3.1-1.2 0-2.4 0-3.7 0 .1-.1.2-.2.2-.3l1.3-1.3c.2.1.2.1.2.1z" />
      </g>
    </SvgIcon>
  );
};
