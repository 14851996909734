import { validateEmail } from 'client/helpers/functions/validations/validateEmail';
import { validationMessages } from 'client/i18n/validationMessages';

export const validateConfirmEmail = (eMail: string, confirmEMail: string, isValidationOn = true) => {
  let isValid: string | boolean = true;
  isValid = validateEmail(confirmEMail, isValidationOn);
  if (!isValid && isValidationOn && eMail !== confirmEMail) {
    isValid = validationMessages['validation.error.confirmEMail-is-not-valid'];
  }
  return isValid;
};
