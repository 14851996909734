import { primaryStepNames, steps } from 'client/helpers/StepNavigationConfig';
import { history } from 'client/routers/history';
import React, { createContext, useEffect, useState } from 'react';

export interface IStepNavigationContext {
  activePrimaryStepIndex: number;
  activeStepIndex: number;
  goToNextStep: () => void;
  goToPrevStep: () => void;
  goToStepByUrl: (url: string) => void;
  goToPrimaryStepByIndex: (index: number) => void;
  updateNavigationByUrl: (url: string) => void;
}

const StepNavigationContext = createContext<IStepNavigationContext>({
  activePrimaryStepIndex: 0,
  activeStepIndex: 0,
  goToNextStep: () => { },
  goToPrevStep: () => { },
  goToStepByUrl: () => { },
  goToPrimaryStepByIndex: () => { },
  updateNavigationByUrl: () => { },
});
const { Provider } = StepNavigationContext;

interface IStepNavigationProvider {
  children: React.ReactNode;
}

const StepNavigationProvider = ({ children }: IStepNavigationProvider) => {
  const [activePrimaryStepIndex, setActivePrimaryStepIndex] = useState(0);
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const goToStepByIndex = (index: number) => {
    if (steps.length > index) {
      setActiveStepIndex(index);
      setActivePrimaryStepIndex(
        primaryStepNames.findIndex((primaryStepName) => primaryStepName === steps[index].primaryStepName),
      );
      history.push({
        pathname: steps[index].url,
        search: history.location.search,
      });
    }
  };

  const goToStepByUrl = (url: string) => {
    const activeStepIndexByUrl = steps.findIndex((step) => step.url === url);
    if (activeStepIndexByUrl >= 0) {
      goToStepByIndex(activeStepIndexByUrl);
    }
  };

  const goToPrimaryStepByIndex = (index: number) => {
    if (primaryStepNames.length > index) {
      const activeStep = steps.find(
        (step) => primaryStepNames.findIndex((primaryStepName) => primaryStepName === step.primaryStepName) === index,
      );
      if (activeStep) {
        goToStepByIndex(steps.findIndex((step) => step.primaryStepName === activeStep.primaryStepName));
      }
    }
  };

  const goToPrevStep = () => {
    if (activeStepIndex - 1 >= 0) {
      goToStepByIndex(activeStepIndex - 1);
    }
  };

  const goToNextStep = () => {
    if (steps.length > activeStepIndex + 1) {
      goToStepByIndex(activeStepIndex + 1);
    }
  };

  const updateNavigationByUrl = (url: string) => {
    const activeStepIndexByUrl = steps.findIndex((step) => step.url === url);
    if (activeStepIndexByUrl >= 0 && steps.length > activeStepIndexByUrl) {
      setActiveStepIndex(activeStepIndexByUrl);
      setActivePrimaryStepIndex(
        primaryStepNames.findIndex((primaryStepName) => primaryStepName === steps[activeStepIndexByUrl].primaryStepName),
      );
    }
  };

  // set active step index by url for the first time of this contexts load
  useEffect(() => {
    goToStepByUrl(history.location.pathname);
  }, []);

  return (
    <Provider
      value={{
        activePrimaryStepIndex,
        activeStepIndex,
        goToNextStep,
        goToPrevStep,
        goToStepByUrl,
        goToPrimaryStepByIndex,
        updateNavigationByUrl,
      }}
    >
      {children}
    </Provider>
  );
};

export { StepNavigationContext, StepNavigationProvider };
