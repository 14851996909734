import { EFormOfPayment } from 'common/form-of-payment.enum';
import { IPrice } from 'common/price.interface';
import { EStatus } from 'common/status.enum';

export interface ITariffResponse {
  price: IPrice | null;
  formOfPayment: EFormOfPayment;
  status: EStatus;
}

export class TariffResponseDto implements ITariffResponse {
  // eslint-disable-next-line no-useless-constructor,no-empty-function
  constructor(public price: IPrice | null, public formOfPayment: EFormOfPayment, public status: EStatus) { }
}
