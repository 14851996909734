import { IAgency } from 'client/model/IAgency';
import { defaultConsumerType, EConsumer } from 'common/consumer.enum';
import React, { createContext, useContext, useState } from 'react';

import { AgencyContext } from './AgencyContext';

interface IConsumerTypeContext {
  consumerType: EConsumer;
}

const ConsumerTypeContext = createContext<IConsumerTypeContext>({
  consumerType: defaultConsumerType,
});
const { Provider } = ConsumerTypeContext;

interface IConsumerTypeProviderProps {
  children?: React.ReactNode;
}

const detectConsumerType = (hasOenr: boolean, hasPnr: boolean): EConsumer => {
  if (hasOenr && hasPnr) {
    return EConsumer.OtcAgency;
  }

  if (hasOenr) {
    return EConsumer.OtcBroker;
  }

  return EConsumer.OtcInternet;
};

const ConsumerTypeProvider = ({ children }: IConsumerTypeProviderProps) => {
  const { agency } = useContext(AgencyContext);
  const [consumerType, setConsumerType] = useState<EConsumer>(defaultConsumerType);

  const has = (field: keyof IAgency): boolean => (agency && agency[field] ? agency[field].replace(/\D/g, '').length > 0 : false);

  const hasOenr = has('oeNr');
  const hasPnr = has('pnr');

  const newConsumerType = detectConsumerType(hasOenr, hasPnr);

  if (newConsumerType !== consumerType) {
    setConsumerType(newConsumerType);
  }

  return <Provider value={{ consumerType }}>{children}</Provider>;
};

export { ConsumerTypeContext, ConsumerTypeProvider };
