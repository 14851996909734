/* eslint-disable max-len */
import { ErgoElementsContext } from '@eg/elements/Provider';
import SvgIcon from '@eg/elements/SvgIcon';
import React, { useContext } from 'react';

interface IElectronicsIconProps {
  width?: number;
  height?: number;
}

export const ElectronicsIcon = ({ width, height }: IElectronicsIconProps) => {
  const { idGenerator } = useContext(ErgoElementsContext);

  return (
    <SvgIcon idGenerator={idGenerator} title="fire" viewBox="0 0 52 52" width={width} height={height}>
      <rect fill="none" height="47" rx="4" width="47" x="2.5" y="2.5" />
      <path d="m30.14 17.55a47.77 47.77 0 0 0 4.25 4.45c.35 0 4.36-4.6 5-4.73a2.29 2.29 0 0 1 2.61 2.39c-.12.76-4.1 4.23-5 5.24 1 1.32 2 2.67 3 4a3.36 3.36 0 0 1 -2 2.1 13.49 13.49 0 0 1 -4.35-.5c-2.52 2.62-6 6.59-11.44 4.83-1.12.66-2 2.12-2.93 2.61a4 4 0 0 1 -1.82-.7 63.82 63.82 0 0 0 -4.83 4.76 15.2 15.2 0 0 1 -2-1.81 8.22 8.22 0 0 0 -.61-.6c0-.24 1-1 1.21-1.21 1.16-1.15 2.58-2.63 3.55-3.62-.12-.83-.87-1.2-.81-1.91.07-1 2.24-2 2.63-2.92-1.6-5.62 2.09-8.93 4.83-11.47a26.7 26.7 0 0 1 -.5-4.13 3.24 3.24 0 0 1 2.07-2.33 40.57 40.57 0 0 1 4 3c1.52-1.51 3.38-3.76 5.16-4.93.45-.3 1.06.1 1.32.2a2.46 2.46 0 0 1 1.41 2.21c-.1.85-4.04 4.21-4.75 5.07zm-8.2 9.06h2.55c-.28 1.77-.79 3.77-1.13 5.32 1.45-1.93 5.51-6.93 5.25-6.93h-2.32c.25-1.79 1-3.67 1.11-5.23-1.8 2.3-3.72 4.48-5.46 6.84z" />
    </SvgIcon>
  );
};
