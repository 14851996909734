import { ELECTRONICS_INSURANCE_SUM_LOCKLIMIT } from 'client/helpers/constants';

import { IElectronics } from './IElectronics';

/* eslint-disable import/prefer-default-export */
export class Electronics implements IElectronics {
  /* eslint-disable no-useless-constructor, no-empty-function */
  constructor(
    public isSelected: boolean,
    public insuranceSum: number | null,
    public isInsuranceSumLocked: boolean,
    public isAdditionalCoverageAllowed: boolean,
    public isAdditionalCoverageSelected: boolean,
  ) {}

  public static getDefaultElectronics() {
    return new Electronics(false, null, true, false, false);
  }

  public static calcIsInsuranceSumLocked(insuranceSum: number | null): boolean {
    return insuranceSum === null || insuranceSum <= ELECTRONICS_INSURANCE_SUM_LOCKLIMIT;
  }

  // eslint-disable-next-line class-methods-use-this
  public setIsAdditionalCoverageAllowed(isAllowed: boolean): Electronics {
    return new Electronics(false, null, true, isAllowed, false);
  }

  public setSelected(isSelected: boolean, gpInsuranceSum: number | null): Electronics {
    if (isSelected) {
      const isLocked = Electronics.calcIsInsuranceSumLocked(gpInsuranceSum);
      return new Electronics(
        true,
        isLocked ? gpInsuranceSum : null,
        isLocked,
        this.isAdditionalCoverageAllowed,
        this.isAdditionalCoverageAllowed,
      );
    }
    return new Electronics(false, null, true, this.isAdditionalCoverageAllowed, false);
  }

  public setInsuranceSum(insuranceSum: number | null): Electronics {
    if (this.isInsuranceSumLocked) {
      return this;
    }
    return new Electronics(
      this.isSelected,
      insuranceSum,
      this.isInsuranceSumLocked,
      this.isAdditionalCoverageAllowed,
      this.isAdditionalCoverageSelected,
    );
  }

  public updateFromGpInsuranceSum(gpInsuranceSum: number | null): Electronics {
    if (Electronics.calcIsInsuranceSumLocked(gpInsuranceSum)) {
      return new Electronics(
        this.isSelected,
        gpInsuranceSum,
        true,
        this.isAdditionalCoverageAllowed,
        this.isAdditionalCoverageSelected,
      );
    }
    return new Electronics(
      this.isSelected,
      this.insuranceSum,
      false,
      this.isAdditionalCoverageAllowed,
      this.isAdditionalCoverageSelected,
    );
  }

  public setAdditionalCoverageSelected(isAdditionalCoverageSelected: boolean): Electronics {
    if (!this.isAdditionalCoverageAllowed) {
      return this;
    }
    return new Electronics(
      this.isSelected,
      this.insuranceSum,
      this.isInsuranceSumLocked,
      this.isAdditionalCoverageAllowed,
      isAdditionalCoverageSelected,
    );
  }
}
