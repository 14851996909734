/* eslint-disable no-useless-constructor */
import { IAddress } from './address.interface';
import { EDeductible } from './deductible.enum';
import { IInsuredPerson } from './InsuredPerson';
import { ELossOfRevenue } from './loss-of-revenue.enum';
import { EOperationCosts } from './operation-costs.enum';
import { ERisks } from './risks.enum';
import { EWaitingPeriod } from './waiting-period.enum';

export interface IBusinessContent {
  businessTypeId: string;
  insuranceSum: number;
  deductible: EDeductible;
  riskAddress: {
    address: IAddress;
  };
  risks: ERisks[];
  lossOfRevenuePlus?: ELossOfRevenue;
  constructionType: boolean;
  burglaryProtection: boolean;
  zuersClass: number;
  earthQuakeZone: number;
  glass?: {
    glazing: boolean;
  };
  firmClosure?: {
    covered: boolean;
  };
  operatingCosts?: {
    insuranceSum: EOperationCosts;
    waitingPeriod: EWaitingPeriod;
    healthConfirmation: boolean;
    insuredPerson: IInsuredPerson;
  };
  machine?: {
    insuranceSum: number;
  };
  electronics?: {
    insuranceSum: number;
    additionalCoverage: boolean;
  };
  vehicle?: {
    insuranceSum: number;
    numberOfVehicles: number;
  };
}

export class BusinessContent implements IBusinessContent {
  electronics?: {
    insuranceSum: number;
    additionalCoverage: boolean;
  };

  glass?: {
    glazing: boolean;
  };

  firmClosure?: {
    covered: boolean;
  };

  operatingCosts?: {
    insuranceSum: EOperationCosts;
    waitingPeriod: EWaitingPeriod;
    healthConfirmation: boolean;
    insuredPerson: IInsuredPerson;
  };

  machine?: {
    insuranceSum: number;
  };

  vehicle?: {
    insuranceSum: number;
    numberOfVehicles: number;
  };

  lossOfRevenuePlus?: ELossOfRevenue;

  constructor(
    public businessTypeId: string,
    public insuranceSum: number,
    public deductible: EDeductible,
    public riskAddress: {
      address: IAddress;
    },
    public risks: ERisks[],
    public constructionType: boolean,
    public burglaryProtection: boolean,
    public zuersClass: number,
    public earthQuakeZone: number,
  ) {
    this.glass = {
      glazing: false,
    };
  }

  public static fromRequest(payload: IBusinessContent | string): BusinessContent {
    if (typeof payload === 'string') {
      // eslint-disable-next-line no-param-reassign
      payload = JSON.parse(payload) as IBusinessContent;
    }
    const result = new BusinessContent(
      payload.businessTypeId,
      payload.insuranceSum,
      payload.deductible,
      payload.riskAddress,
      payload.risks,
      payload.constructionType,
      payload.burglaryProtection,
      payload.zuersClass,
      payload.earthQuakeZone,
    );

    if (payload.glass && payload.glass.glazing) {
      result.setGlass(payload.glass.glazing);
    }

    if (payload.electronics) {
      result.setElectronics(payload.electronics.insuranceSum, payload.electronics.additionalCoverage);
    }

    if (payload.lossOfRevenuePlus) {
      result.setLossOfRevenuePlus(payload.lossOfRevenuePlus);
    }

    return result;
  }

  public setElectronics(insuranceSum: number, additionalCoverage: boolean) {
    this.electronics = { insuranceSum, additionalCoverage };
  }

  public setLossOfRevenuePlus(lossOfRevenue: ELossOfRevenue) {
    this.lossOfRevenuePlus = lossOfRevenue;
  }

  public setGlass(glass: boolean) {
    if (glass) {
      this.glass = {
        glazing: true,
      };
    }
  }
}
