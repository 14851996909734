import { TariffPriceBox } from 'client/components/molecules/TariffPriceBox/TariffPriceBox';
import React from 'react';

import styles from './styles.module.scss';

export interface IPremiumIconBoxProps {
  icon: any;
  title: string;
  subtitle?: string;
  className?: string;
}

export const PremiumIconBox = ({
  icon: Icon, title, subtitle, className,
}: IPremiumIconBoxProps) => {
  const outerContainerClass = `esc_container esc_border-radius esc_border-radius--small
    esc_box-shadow esc_box-shadow--small ${styles.container}${className ? ` ${className}` : ''}`;

  return (
    <div className={outerContainerClass} id="premium-box">
      <div className={styles.innerContainer}>
        <div className={`${styles.ribbon} print-hide`} />
        <div className={styles.iconBox}>
          <Icon height={48} />
        </div>
        <div className={styles.contentBox}>
          {title ? <div className={styles.title}>{title}</div> : null}
          {subtitle ? <div className={styles.subtitle}>{subtitle}</div> : null}
        </div>
        <div className={styles.tariffBox}>
          <TariffPriceBox withTax variant="full" />
        </div>
      </div>
    </div>
  );
};
