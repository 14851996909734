/* eslint-disable max-len */
export const messages = {
  'preinsurance-damages-page.preinsurance.heading': 'Angaben zur Vorversicherung',
  'preinsurance-damages-page.preinsurance.question':
    'Bestand oder besteht gleichartiger Versicherungsschutz für Ihren Betrieb an diesem Ort bei einem anderen Versicherer?*',
  'preinsurance-damages-page.preinsurance.ensurer.label': 'Versicherer',
  'preinsurance-damages-page.preinsurance.insurant.label': 'Versicherungsnehmer',
  'preinsurance-damages-page.preinsurance.is-existing.label': 'Noch bestehend',
  'preinsurance-damages-page.preinsurance.product.combined-shop-contents.label': 'Geschäftsinhaltsversicherung',
  'preinsurance-damages-page.preinsurance.product.glass.label': 'Glas',
  'preinsurance-damages-page.preinsurance.product.electronic-flat.label': 'Elektronik-Pauschal',
  'preinsurance-damages-page.damages.heading': 'Vorschäden',
  'preinsurance-damages-page.damages.question':
    'Sind innerhalb der letzten 5 Jahre - bzw. der letzten 10 Jahre für Überschwemmung/Rückstau sowie Sonstige Naturgefahren - Schäden (auch nicht versicherte) für Ihren Betrieb an diesem Ort eingetreten?*',
};
