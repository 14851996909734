import { formOfPaymentFullnames } from 'client//helpers/data/formOfPaymentFullnames';
import { PriceBox } from 'client/components/molecules/PriceBox/PriceBox';
import { formOfPaymentShortnames } from 'client/helpers/data/formOfPaymentShortnames';
import { withI18n } from 'client/i18n/withI18n';
import { TariffContext } from 'client/store/TariffContext';
import { EFormOfPayment } from 'common/form-of-payment.enum';
import React, { useContext } from 'react';
import { InjectedIntlProps } from 'react-intl';

import { messages } from './messages';
import styles from './styles.module.scss';

export const translate = (fop: EFormOfPayment): string => {
  switch (fop) {
    case EFormOfPayment.Yearly:
      return formOfPaymentShortnames.YEARLY;

    case EFormOfPayment.HalfYearly:
      return formOfPaymentShortnames.HALF_YEARLY;

    case EFormOfPayment.Quarterly:
      return formOfPaymentShortnames.QUARTER_YEARLY;

    default:
      return '';
  }
};

const translateLong = (fop: EFormOfPayment): string => {
  switch (fop) {
    case EFormOfPayment.Yearly:
      return formOfPaymentFullnames.YEARLY;

    case EFormOfPayment.HalfYearly:
      return formOfPaymentFullnames.HALF_YEARLY;

    case EFormOfPayment.Quarterly:
      return formOfPaymentFullnames.QUARTER_YEARLY;

    default:
      return '';
  }
};

export interface ITariffPriceBoxProps extends InjectedIntlProps {
  withTax?: boolean;
  variant?: 'default' | 'full'; // form of payment is shown as a short suffix (default) or on top (full)
}

const TariffPriceBoxComponent = ({ intl, withTax, variant }: ITariffPriceBoxProps) => {
  const { tariff, isLoading, isError } = useContext(TariffContext);

  const { grossValue, minimumPremium } = tariff.price;
  const suffix = variant === 'default' ? translate(tariff.formOfPayment) : '';
  const heading = variant === 'full' ? translateLong(tariff.formOfPayment) : '';
  const formattedTax = intl.formatNumber(tariff.price.taxComponent, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  });
  const taxMessage = intl.formatMessage({ id: 'tariff-price-box.taxmessage' }, { tax: formattedTax });
  return (
    <>
      {heading && tariff.isValid && !isLoading && <span className={styles.headingMessage}>{heading}</span>}
      <PriceBox
        value={grossValue}
        minimumPremium={minimumPremium}
        suffix={suffix}
        isLoading={isLoading}
        isError={isError || !tariff.isValid}
      />

      {withTax && tariff.isValid && !isLoading && <span className={styles.taxMessage}>{taxMessage}</span>}
    </>
  );
};

export const TariffPriceBox = withI18n(TariffPriceBoxComponent, messages);
