import LoadingSpinner from '@eg/elements/LoadingSpinner';
import Price from '@eg/elements/Price';
import { withI18n } from 'client/i18n/withI18n';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from './messages';
import styles from './styles.module.scss';

export interface IPriceBoxProps {
  value: number;
  minimumPremium?: boolean;
  suffix?: string;
  isLoading?: boolean;
  isError?: boolean;
}

const PriceBoxComponent = ({
  value, minimumPremium, isLoading, suffix, isError,
}: IPriceBoxProps) => {
  const renderSpinner = () => (
    <div className={styles.spinnerContainer}>
      <LoadingSpinner viewport="relative" show />
    </div>
  );

  const renderError = () => <div className={styles.errorContainer}>-</div>;

  const priceboxContainerStyle = `${styles.priceboxContainer} ${!isLoading && !isError ? styles.visible : ''} ${isError ? styles.error : ''
  }`;

  return (
    <div className={priceboxContainerStyle}>
      <Price value={value || 0} suffix={suffix} />
      {!isError && !isLoading && minimumPremium && (
        <h4>
          <FormattedMessage id="price-box.message" />
        </h4>
      )}
      {isLoading ? renderSpinner() : null}
      {isError && !isLoading ? renderError() : null}
    </div>
  );
};

export const PriceBox = withI18n(PriceBoxComponent, messages);
