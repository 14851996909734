import { ERisks } from 'common/risks.enum';

import { IRisks } from './IRisks';

// eslint-disable-next-line import/prefer-default-export
export class Risks implements IRisks {
  // abbreviation for Feuer, Einbruch, leitungsWasser, Sturm/hagel
  public fews: boolean;

  public flooding: boolean;

  public naturalHazard: boolean;

  public extendedCoverage: boolean;

  public unnamedDangers: boolean;

  public lossRevenue: boolean;

  public assistance: boolean;

  private static defaultRisks = new Risks({ fews: true, lossRevenue: true });

  public constructor(defaultValues?: Partial<IRisks>) {
    this.fews = (defaultValues && defaultValues.fews) || false;
    this.flooding = (defaultValues && defaultValues.flooding) || false;
    this.naturalHazard = (defaultValues && defaultValues.naturalHazard) || false;
    this.extendedCoverage = (defaultValues && defaultValues.extendedCoverage) || false;
    this.unnamedDangers = (defaultValues && defaultValues.unnamedDangers) || false;
    this.lossRevenue = (defaultValues && defaultValues.lossRevenue) || false;
    this.assistance = (defaultValues && defaultValues.assistance) || false;
  }

  public static getDefaultRisks() {
    return Risks.defaultRisks;
  }

  public setFews(newValue: boolean): Risks {
    return this.set({ fews: newValue });
  }

  public setFlooding(newValue: boolean): Risks {
    return this.set({ flooding: newValue });
  }

  public setNaturalHazard(newValue: boolean): Risks {
    return this.set({ naturalHazard: newValue });
  }

  public setExtendedCoverage(newValue: boolean): Risks {
    return this.set({ extendedCoverage: newValue });
  }

  public setUnnamedDangers(newValue: boolean): Risks {
    return this.set({ unnamedDangers: newValue });
  }

  public setLossRevenue(newValue: boolean): Risks {
    return this.set({ lossRevenue: newValue });
  }

  public setAssistance(newValue: boolean): Risks {
    return this.set({ assistance: newValue });
  }

  public toArray(): ERisks[] {
    const result: ERisks[] = [];

    if (this.fews) {
      result.push(ERisks.Fire);
      result.push(ERisks.Burglary);
      result.push(ERisks.PipeWater);
      result.push(ERisks.StormHail);
    }
    if (this.flooding) {
      result.push(ERisks.Flooding);
    }
    if (this.extendedCoverage) {
      result.push(ERisks.ExtendedCoverage);
    }
    if (this.naturalHazard) {
      result.push(ERisks.NaturalHazard);
    }
    if (this.unnamedDangers) {
      result.push(ERisks.Unnamed);
    }
    if (this.lossRevenue) {
      result.push(ERisks.LossRevenue);
    }
    if (this.assistance) {
      result.push(ERisks.Assistance);
    }

    return result;
  }

  private set(newValues: Partial<IRisks>): Risks {
    return new Risks({ ...this, ...newValues });
  }
}
