import { AppRouter } from 'client/routers/AppRouter';
import { BusinessDataProvider } from 'client/store/BusinessDataContext';
import { BusinessTypesProvider } from 'client/store/BusinessTypesContext';
import { ConsumerTypeProvider } from 'client/store/ConsumerTypeContext';
import { DocumentProvider } from 'client/store/DocumentContext';
import { GlobalsProvider } from 'client/store/GlobalsContext';
import { RiskAddressProvider } from 'client/store/RiskAddressContext';
import { StepNavigationProvider } from 'client/store/StepNavigationContext';
import { TariffProvider } from 'client/store/TariffContext';
import React from 'react';
import { Router } from 'react-router-dom';

import { history } from './routers/history';
import { AgencyProvider } from './store/AgencyContext';
import { SendOrderProvider } from './store/SendOrderContext';

// the order of provider is important, because some providers depends on eachother
export const GpOnlineApp = () => (
  <Router history={history}>
    <AgencyProvider>
      <ConsumerTypeProvider>
        <GlobalsProvider>
          <StepNavigationProvider>
            <RiskAddressProvider>
              <BusinessTypesProvider>
                <BusinessDataProvider>
                  <DocumentProvider>
                    <TariffProvider>
                      <SendOrderProvider>
                        <AppRouter />
                      </SendOrderProvider>
                    </TariffProvider>
                  </DocumentProvider>
                </BusinessDataProvider>
              </BusinessTypesProvider>
            </RiskAddressProvider>
          </StepNavigationProvider>
        </GlobalsProvider>
      </ConsumerTypeProvider>
    </AgencyProvider>
  </Router>
);
