import { isNumber } from 'client/helpers/functions/isNumber';
import { validateRequiredField } from 'client/helpers/functions/validations/validateRequiredField';
import { validationMessages } from 'client/i18n/validationMessages';

export const validateZipCode = (value: string, isValidationOn = true) => {
  let isValid: string | boolean = true;
  isValid = validateRequiredField(value, isValidationOn);
  if ((!isValid && isValidationOn && value.length !== 5) || (!isNumber(value) && isValidationOn && value !== '')) {
    isValid = validationMessages['validation.error.zip-code-is-not-valid'];
  }
  return isValid;
};
