import Button from '@eg/elements/Button';
import FormSection from '@eg/elements/FormSection';
import { SummaryBox } from 'client/components/molecules/SummaryBox/SummaryBox';
import { preInsuringCompanies } from 'client/helpers/data/preInsiringCompanies';
import { ESelectValues } from 'client/helpers/ESelectValues';
import { collectSelectedRisks } from 'client/helpers/functions/collectSelectedRisks';
import { isCompany } from 'client/helpers/functions/isCompany';
import { showDeductibleInfo } from 'client/helpers/functions/showDeductibleInfo';
import { NavigationUrls } from 'client/helpers/NavigationUrls';
import {
  translateCancellationStatus,
  translateDeductible,
  translateLossOfRevenue,
  translateTransactionDate,
} from 'client/i18n/translateEnums';
import { withI18n } from 'client/i18n/withI18n';
import { BusinessDataContext } from 'client/store/BusinessDataContext';
import { ECancellationStatus } from 'common/cancellation-status.enum';
import { ETransactionDate } from 'common/transaction-date.enum';
import moment from 'moment';
import React, { useContext } from 'react';
import { FormattedHTMLMessage, FormattedMessage, InjectedIntlProps } from 'react-intl';

import { messages } from './messages';
import styles from './styles.module.scss';

const SummaryContentComponent = ({ intl }: InjectedIntlProps) => {
  const {
    // Business data
    businessType,
    address,
    isInclosedBuilding,
    hasSecurityMeasures,
    // Contact data
    salutation,
    companyOrInsurantName,
    nameAffixOrInsurantFirstName,
    insurantAddress,
    dateOfBirth,
    telephoneNumber,
    eMail,
    // Insurance data
    insuranceSum,
    deductible,
    electronics,
    glass,
    lossOfRevenuePlus,
    risks,
    // Preinsurance data
    hasPreinsurance,
    combinedShopContentsProduct,
    glassProduct,
    electronicFlatProduct,
    // Damages data
    hadDamages,
    damagesQuantity,
    damagesOperationalExpenditure,
    damagesDescription,
    // Payment data
    directDebitMandate,
  } = useContext(BusinessDataContext);
  const fullName = `${companyOrInsurantName} ${nameAffixOrInsurantFirstName}`;
  const insurantAddressStr = insurantAddress
    ? `${insurantAddress.street} ${insurantAddress.houseNumber}, ${insurantAddress.zipCode} ${insurantAddress.city}`
    : '';
  const addressStr = address ? `${address.street} ${address.houseNumber}, ${address.zipCode} ${address.city}` : '';

  const formatCurrencyValue = (value: number | null) => (value
    ? intl.formatMessage({ id: 'summary-page.data.currency-value' }, { value: intl.formatNumber(value) })
    : '');

  const getSelectedRisks = () => {
    const collection = [intl.formatMessage({ id: 'summary-page.data.insurance.risk.basic' })];
    if (risks.flooding) {
      collection.push(intl.formatMessage({ id: 'summary-page.data.insurance.risk.flooding' }));
    }
    if (risks.naturalHazard) {
      collection.push(intl.formatMessage({ id: 'summary-page.data.insurance.risk.natural-hazard' }));
    }
    if (risks.extendedCoverage) {
      collection.push(intl.formatMessage({ id: 'summary-page.data.insurance.risk.extended-coverage' }));
    }
    if (risks.unnamedDangers) {
      collection.push(intl.formatMessage({ id: 'summary-page.data.insurance.risk.unnamed-dangers' }));
    }
    if (risks.lossRevenue) {
      collection.push(intl.formatMessage({ id: 'summary-page.data.insurance.risk.loss-revenue' }));
    }
    return collection.join(', ');
  };

  const getExtraBenefits = () => {
    const collection = [];
    if (lossOfRevenuePlus.isSelected) {
      const lorp = intl.formatMessage(
        { id: 'summary-page.data.insurance.extra-benefit.loss-of-revenue-plus' },
        { rate: translateLossOfRevenue(lossOfRevenuePlus.rate) },
      );
      collection.push(lorp);
    }
    if (risks.assistance) {
      collection.push(intl.formatMessage({ id: 'summary-page.data.insurance.extra-benefit.assistance' }));
    }
    if (glass) {
      collection.push(intl.formatMessage({ id: 'summary-page.data.insurance.extra-benefit.glass' }));
    }
    if (electronics.isSelected) {
      let elec = intl.formatMessage(
        { id: 'summary-page.data.insurance.extra-benefit.electronics' },
        { insuranceSum: formatCurrencyValue(electronics.insuranceSum) },
      );
      if (electronics.isAdditionalCoverageSelected) {
        elec += intl.formatMessage({ id: 'summary-page.data.insurance.extra-benefit.electronics.additional-coverage' });
      }
      elec += intl.formatMessage({ id: 'summary-page.data.insurance.extra-benefit.electronics.additional-info' });
      collection.push(elec);
    }
    return collection.join(', ');
  };

  const getInsuranceName = (key: string) => {
    const company = preInsuringCompanies.find((c) => c.id === key);
    return company ? company.value : '';
  };

  const getPreinsurances = () => {
    if (!hasPreinsurance) {
      return [
        {
          name: intl.formatMessage({ id: 'summary-page.data.damages.none' }),
          value: '-',
        },
      ];
    }

    const collection = [];
    if (combinedShopContentsProduct) {
      collection.push({
        name: intl.formatMessage({ id: 'summary-page.data.preinsurance.gp' }),
        value:
          combinedShopContentsProduct.insuranceKey !== ESelectValues.NoPreinsuarance
            ? [
              intl.formatMessage(
                { id: 'summary-page.data.preinsurance.insurance' },
                { insurance: getInsuranceName(combinedShopContentsProduct.insuranceKey) },
              ),
              intl.formatMessage(
                { id: 'summary-page.data.preinsurance.contractNumber' },
                { contractNumber: combinedShopContentsProduct.contractNumber },
              ),
              intl.formatMessage(
                { id: 'summary-page.data.preinsurance.cancelledBy' },
                {
                  cancelledBy: translateCancellationStatus(
                    combinedShopContentsProduct.cancellationStatus as ECancellationStatus,
                  ),
                },
              ),
            ]
            : intl.formatMessage({ id: 'summary-page.data.preinsurance.none' }),
      });
    }
    if (glass && glassProduct) {
      collection.push({
        name: intl.formatMessage({ id: 'summary-page.data.preinsurance.glass' }),
        value:
          glassProduct.insuranceKey !== ESelectValues.NoPreinsuarance
            ? [
              intl.formatMessage(
                { id: 'summary-page.data.preinsurance.insurance' },
                { insurance: getInsuranceName(glassProduct.insuranceKey) },
              ),
              intl.formatMessage(
                { id: 'summary-page.data.preinsurance.contractNumber' },
                { contractNumber: glassProduct.contractNumber },
              ),
              intl.formatMessage(
                { id: 'summary-page.data.preinsurance.cancelledBy' },
                { cancelledBy: translateCancellationStatus(glassProduct.cancellationStatus as ECancellationStatus) },
              ),
            ]
            : intl.formatMessage({ id: 'summary-page.data.preinsurance.none' }),
      });
    }
    if (electronics.isSelected && electronicFlatProduct) {
      collection.push({
        name: intl.formatMessage({ id: 'summary-page.data.preinsurance.electronic-flat' }),
        value:
          electronicFlatProduct.insuranceKey !== ESelectValues.NoPreinsuarance
            ? [
              intl.formatMessage(
                { id: 'summary-page.data.preinsurance.insurance' },
                { insurance: getInsuranceName(electronicFlatProduct.insuranceKey) },
              ),
              intl.formatMessage(
                { id: 'summary-page.data.preinsurance.contractNumber' },
                { contractNumber: electronicFlatProduct.contractNumber },
              ),
              intl.formatMessage(
                { id: 'summary-page.data.preinsurance.cancelledBy' },
                {
                  cancelledBy: translateCancellationStatus(
                    electronicFlatProduct.cancellationStatus as ECancellationStatus,
                  ),
                },
              ),
            ]
            : intl.formatMessage({ id: 'summary-page.data.preinsurance.none' }),
      });
    }

    return collection;
  };

  const getDeductibleInfo = () => {
    if (showDeductibleInfo(risks, deductible)) {
      return [
        intl.formatMessage(
          { id: 'contribution-page.contribution-calculation-form.select-deductibl.info' },
          { list: collectSelectedRisks(risks, intl) },
        ),
        translateDeductible(deductible),
      ];
    }
    return [translateDeductible(deductible)];
  };

  return (
    <FormSection heading={intl.formatMessage({ id: 'summary-page.data.heading' })}>
      <p className="print-hide">
        <FormattedHTMLMessage id="summary-page.data.text" />
      </p>

      <SummaryBox
        title={intl.formatMessage({ id: 'summary-page.data.business.headline' })}
        editLink={NavigationUrls.businessTypePageUrl}
        data={[
          { name: intl.formatMessage({ id: 'summary-page.data.business.business-type' }), value: businessType },
          { name: intl.formatMessage({ id: 'summary-page.data.business.risk-address' }), value: addressStr },
        ]}
        renderAdditional={() => (
          <>
            {isInclosedBuilding && (
              <>
                <div className="esc_col esc_col-12 esc_col-m-11">
                  <FormattedMessage id="summary-page.building-details.is-inclosed-building.text" />
                </div>
                <div className="esc_col esc_col-12 esc_col-m-1">
                  <FormattedMessage id="summary-page.yes" />
                </div>
              </>
            )}
            {hasSecurityMeasures && (
              <>
                <div className="esc_col esc_col-12 esc_col-m-11">
                  <FormattedMessage id="summary-page.building-details.has-security-measures.text" />
                </div>
                <div className="esc_col esc_col-12 esc_col-m-1">
                  <FormattedMessage id="summary-page.yes" />
                </div>
              </>
            )}
          </>
        )}
      />

      <SummaryBox
        title={intl.formatMessage({ id: 'summary-page.data.contact.headline' })}
        editLink={NavigationUrls.contactDataPageUrl}
        data={[
          {
            name: intl.formatMessage({ id: 'summary-page.data.contact.salutation' }),
            value:
              isCompany(salutation) || salutation === ESelectValues.PleaseSelect
                ? ''
                : intl.formatMessage({ id: `summary-page.data.contact.salutation.${salutation.toLowerCase()}` }),
          },
          { name: intl.formatMessage({ id: 'summary-page.data.contact.name' }), value: fullName },
          { name: intl.formatMessage({ id: 'summary-page.data.contact.address' }), value: insurantAddressStr },
          {
            name: intl.formatMessage({ id: 'summary-page.data.contact.birthday' }),
            value: dateOfBirth && !isCompany(salutation) ? moment(dateOfBirth).format('DD.MM.YYYY') : '',
          },
          { name: intl.formatMessage({ id: 'summary-page.data.contact.telephone' }), value: telephoneNumber },
          { name: intl.formatMessage({ id: 'summary-page.data.contact.email' }), value: eMail },
        ]}
      />

      <SummaryBox
        title={intl.formatMessage({ id: 'summary-page.data.insurance.headline' })}
        editLink={NavigationUrls.contributionPageUrl}
        data={[
          {
            name: intl.formatMessage({ id: 'summary-page.data.insurance.insurance-sum' }),
            value: formatCurrencyValue(insuranceSum),
          },
          { name: intl.formatMessage({ id: 'summary-page.data.insurance.risks' }), value: getSelectedRisks() },
          {
            name: intl.formatMessage({ id: 'summary-page.data.insurance.deductible' }),
            value: getDeductibleInfo(),
          },
          { name: intl.formatMessage({ id: 'summary-page.data.insurance.extra-benefits' }), value: getExtraBenefits() },
          {
            name: intl.formatMessage({ id: 'summary-page.data.insurance.start.lable' }),
            value: intl.formatMessage(
              { id: 'summary-page.data.insurance.start.value' },
              { start: moment(directDebitMandate.inceptionDate).format('DD.MM.YYYY') },
            ),
          },
          {
            name: intl.formatMessage({ id: 'summary-page.data.insurance.end.lable' }),
            value: intl.formatMessage({ id: 'summary-page.data.insurance.end.value' }),
          },
        ]}
      />

      <SummaryBox
        title={intl.formatMessage({ id: 'summary-page.data.preinsurance.headline' })}
        editLink={NavigationUrls.preinsuranceDamagesPageUrl}
        data={getPreinsurances()}
      />

      <SummaryBox
        title={intl.formatMessage({ id: 'summary-page.data.damages.headline' })}
        editLink={NavigationUrls.preinsuranceDamagesPageUrl}
        data={
          hadDamages
            ? [
              {
                name: intl.formatMessage({ id: 'summary-page.data.damages.quantity' }),
                value: damagesQuantity !== null ? damagesQuantity : '',
              },
              {
                name: intl.formatMessage({ id: 'summary-page.data.damages.operational-expenditure' }),
                value:
                  damagesOperationalExpenditure !== null ? formatCurrencyValue(damagesOperationalExpenditure) : '',
              },
              {
                name: intl.formatMessage({ id: 'summary-page.data.damages.description' }),
                value: damagesDescription,
              },
            ]
            : [
              {
                name: intl.formatMessage({ id: 'summary-page.data.damages.none' }),
                value: '-',
              },
            ]
        }
      />

      <SummaryBox
        title={intl.formatMessage({ id: 'summary-page.data.bank-account.headline' })}
        editLink={NavigationUrls.paymentDataPageUrl}
        isLast
        data={[
          {
            name: intl.formatMessage({ id: 'summary-page.data.bank-account.account-holder' }),

            value: isCompany(salutation)
              ? `${companyOrInsurantName} ${nameAffixOrInsurantFirstName}`
              : `${nameAffixOrInsurantFirstName} ${companyOrInsurantName}`,
          },
          { name: intl.formatMessage({ id: 'summary-page.data.bank-account.iban' }), value: directDebitMandate.iban },
          {
            name: intl.formatMessage({ id: 'summary-page.data.bank-account.transaction-date' }),
            value: translateTransactionDate(directDebitMandate.transactionDate as ETransactionDate),
          },
        ]}
      />

      <div className={styles.printButtonWrapper}>
        <Button className="print-hide" variant="primary" onClick={() => window && window.print()}>
          <FormattedMessage id="summary-page.print.button" />
        </Button>
      </div>
    </FormSection>
  );
};

export const SummaryContent = withI18n(SummaryContentComponent, messages);
