/* eslint-disable max-len */
export const messages = {
  'preinsurance-damages-page.damages.data.label': 'Anzahl und Gesamtaufwendungen*',
  'preinsurance-damages-page.damages.quantity.placeholder': 'Anzahl',
  'preinsurance-damages-page.damages.operational-expenditure.placeholder': 'Gesamtaufwendungen',
  'preinsurance-damages-page.damages.description.label': 'Beschreibung*',
  'preinsurance-damages-page.damages.description.placeholder':
    'z.B. Art des Schadens, Ursachen, Zeitpunkt, Höhe, Versicherer',
  'preinsurance-damages-page.damages.operational-expenditure.adornment': '€',
};
