/* eslint-disable max-len */
import { ErgoElementsContext } from '@eg/elements/Provider';
import SvgIcon from '@eg/elements/SvgIcon';
import React, { useContext } from 'react';

interface IGlassIconProps {
  width?: number;
  height?: number;
}

export const GlassIcon = ({ width, height }: IGlassIconProps) => {
  const { idGenerator } = useContext(ErgoElementsContext);

  return (
    <SvgIcon idGenerator={idGenerator} title="fire" viewBox="0 0 52 52" width={width} height={height}>
      <rect fill="none" height="47" rx="4" width="47" x="2.5" y="2.5" />
      <g>
        <path d="m13 39h26v-26h-26zm.64-29h24.72c2 0 3.64 1.24 3.64 2.76v26.48c0 1.52-1.63 2.76-3.64 2.76h-24.72c-2 0-3.64-1.24-3.64-2.76v-26.48c0-1.52 1.63-2.76 3.64-2.76zm-1.79 19.1a.51.51 0 0 0 .15-.36v-4.24a.5.5 0 0 0 -1 0v4.24a.5.5 0 0 0 .5.5.48.48 0 0 0 .35-.14z" />
        <path d="m15.5 19a.47.47 0 0 0 .35-.15l3-3a.49.49 0 1 0 -.7-.7l-3 3a.48.48 0 0 0 0 .7.47.47 0 0 0 .35.15m0 3a.47.47 0 0 0 .35-.15l6-6a.49.49 0 1 0 -.7-.7l-6 6a.48.48 0 0 0 0 .7.47.47 0 0 0 .35.15m22.56-3v19h-18.9l.2-.23 4.72-3.15h-5.35l4.54-3.47-3.91-.15 3.46-1.43-4.32-4.33v-.24l10.41 4.1-4.24-4.8 5 1-3.63-5.5 8.07 4.63-.77-3.43z" />
      </g>
    </SvgIcon>
  );
};
