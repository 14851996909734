/* eslint-disable max-len */
import { ErgoElementsContext } from '@eg/elements/Provider';
import SvgIcon from '@eg/elements/SvgIcon';
import React, { useContext } from 'react';

interface IFireIconProps {
  width?: number;
  height?: number;
}

export const FireIcon = ({ width, height }: IFireIconProps) => {
  const { idGenerator } = useContext(ErgoElementsContext);

  return (
    <SvgIcon idGenerator={idGenerator} title="fire" viewBox="0 0 52 52" width={width} height={height}>
      <mask id="a" height="31.79" maskUnits="userSpaceOnUse" width="25.65" x="13.18" y="10.12">
        <path d="m13.18 10.12h25.64v31.79h-25.64z" fill="#fff" />
      </mask>
      <rect fill="none" height="47" rx="4" width="47" x="2.5" y="2.5" />
      <g mask="url(#a)">
        <path d="m31.17 23.5c-2.8-.5-6.94-8.5-6.42-13.38 0 0-5.29 3.89-4.32 9.61s2.36 9.94-1.31 9.8-1.57-4.55-.81-6.66c-6.12 3.57-5.2 9.55-4.9 11.52a8.26 8.26 0 0 0 5.2 7c6.37 1.38 8.86-9 8.65-12.49 1.73 2 3.49 3.37 3.49 6 0 3.7-6 6.94-1.45 6.94s7.84-2.63 9.08-8.13c1.36-6-.18-13.34-8-17 .32.73 3.62 7.29.79 6.79z" />
      </g>
    </SvgIcon>
  );
};
