import { IRisks } from 'client/model/IRisks';
import { InjectedIntl } from 'react-intl';

export const collectSelectedRisks = (risks: IRisks, intl: InjectedIntl) => {
  const risksCollection = [];
  if (risks.flooding) {
    risksCollection.push(
      intl.formatMessage({
        id: 'contribution-page.business-content.risk-tile.flooding.title',
      }),
    );
  }
  if (risks.naturalHazard) {
    risksCollection.push(
      intl.formatMessage({
        id: 'contribution-page.business-content.risk-tile.natural-hazard.title',
      }),
    );
  }
  if (risks.extendedCoverage) {
    risksCollection.push(
      intl.formatMessage({
        id: 'contribution-page.business-content.risk-tile.extended-coverage.title',
      }),
    );
  }
  if (risks.unnamedDangers) {
    risksCollection.push(
      intl.formatMessage({
        id: 'contribution-page.business-content.risk-tile.unnamed-dangers.title',
      }),
    );
  }
  return risksCollection.join(', ');
};
