import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';

// adapted from
// https://medium.com/@dave.draper20/i18n-per-component-in-react-using-the-higher-order-component-pattern-b04f32e7cd6a
export function withI18n<P>(WrappedComponent: any, messages: { [key: string]: string }): any {
  return injectIntl(
    class extends React.Component<P & InjectedIntlProps> {
      constructor(props: P & InjectedIntlProps) {
        super(props);

        const { intl } = this.props;

        const keys = Object.keys(messages);

        keys.forEach((key) => {
          if (!intl.messages[key]) {
            intl.messages[key] = messages[key];
          }
        });
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    },
  );
}
