import FormFooter from '@eg/elements/FormFooter';
import { GpOnlineStepNavigation } from 'client/components/molecules/GpOnlineStepNavigation/GpOnlineStepNavigation';
import { BasicTemplate } from 'client/components/templates/BasicTemplate/BasicTemplate';
import { isPageValidationDisabled } from 'client/helpers/functions/isPageValidationDisabled';
import { NavigationUrls } from 'client/helpers/NavigationUrls';
import { steps } from 'client/helpers/StepNavigationConfig';
import { withI18n } from 'client/i18n/withI18n';
import { history } from 'client/routers/history';
import { GlobalsContext } from 'client/store/GlobalsContext';
import { StepNavigationContext } from 'client/store/StepNavigationContext';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from './messages';
import styles from './styles.module.scss';

interface IAdvisoryTemplate {
  children: React.ReactNode;
}

const AdvisoryTemplateComponent = ({ children }: IAdvisoryTemplate) => {
  const { visitedSteps, setVisitedSteps } = useContext(GlobalsContext);
  const { goToStepByUrl } = useContext(StepNavigationContext);

  // on each first load check if the step is permite to load
  useEffect(() => {
    // skip the validation of the steps order if the page validation is disabled
    if (!isPageValidationDisabled()) {
      // create a new updated list of visited steps
      const newVisitedSteps = new Map<string, boolean>();
      visitedSteps.forEach((_: boolean, pathname: string) => {
        newVisitedSteps.set(pathname, false);
      });
      newVisitedSteps.set(history.location.pathname, true);

      // define, if the current step is allowed
      let index = 0;
      let lastCorrectStepUrl: null | string = null;
      newVisitedSteps.forEach((_: boolean, pathname: string) => {
        if (steps[index].url === pathname) {
          index += 1;
        } else {
          lastCorrectStepUrl = steps[index - 1] ? steps[index - 1].url : NavigationUrls.businessTypePageUrl;
        }
      });

      if (lastCorrectStepUrl) {
        // it means the current step is not allowed, therefore we should redirect to last correct step
        goToStepByUrl(lastCorrectStepUrl);
      } else {
        // stay at the step and update visitedSteps
        setVisitedSteps(newVisitedSteps);
      }
    }
  }, []);

  return (
    <BasicTemplate>
      <h1 className={styles.header} id="page-headline">
        <FormattedMessage id="advisory-template.title" />
      </h1>
      <GpOnlineStepNavigation>{children}</GpOnlineStepNavigation>
      <div className="print-hide">
        <FormFooter />
      </div>
    </BasicTemplate>
  );
};

export const AdvisoryTemplate = withI18n(AdvisoryTemplateComponent, messages);
