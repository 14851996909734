/* eslint-disable max-len */
export const messages = {
  // Tariff
  'summary-page.actual-tariff.box.title': 'Geschäftsinhaltsversicherung',
  'summary-page.actual-tariff.heading': 'Aktueller Tarifpreis',
  // Document download
  'summary-page.document.button': 'Alle Vertragsdokumente herunterladen',
  'summary-page.document.heading':
    'Datenschutzinformationen, Vertragsunterlagen und Beratungsverzicht zur ERGO-Geschäftsversicherung',
  'summary-page.consulting-waiver.label':
    'Ich verzichte für diesen Vertragsabschluss auf eine persönliche Beratung sowie deren Dokumentation. Dies kann sich eventuell auf die Geltendmachung von Schadensersatzansprüchen nachteilig auswirken. Die Informationen zum Beratungsverzicht habe ich gespeichert oder ausgedruckt.',
  'summary-page.privacy-policy.label':
    'Ich habe mich über die DSGVO informiert und die Datenschutzhinweise zur Kenntnis genommen. Die Informationen zum Datenschutz habe ich gespeichert oder ausgedruckt.',
  'summary-page.terms-condititions.label':
    'Ich habe die Vertragsunterlagen und Informationen zur Widerrufsbelehrung zur Kenntnis genommen und erkläre mich damit einverstanden. Die Versicherungsbedingungen sind Grundlage des Vertrags. Die Vertragsunterlagen und Informationen zur Widerrufsbelehrung habe ich gespeichert oder ausgedruckt.',
  'summary-page.document.error': 'Beim Erstellen Ihrer Vertragsdokumente ist ein Fehler aufgetreten.',
  // Advertisement permission
  'summary-page.advertisement-permission.headline': 'Einwilligungserklärung',
  'summary-page.advertisement-permission.text':
    'Ich willige ein, dass ich künftig per elektronischer Post und Telefon über Versicherungs- und Finanzprodukte von Unternehmen und Vermittlern der ERGO Group* informiert werde. Sie können mich auch zur Kundenbefragung kontaktieren. Meine Daten dürfen hierfür verarbeitet werden. Diese Einwilligung gilt unabhängig davon, ob ein Vertrag besteht. Ich kann sie jederzeit formlos für die Zukunft widerrufen.',
  'summary-page.advertisement-permission.footnote':
    '* ERGO Lebensversicherung AG, ERGO Versicherung AG, DKV Deutsche Krankenversicherung AG, ERGO Beratung und Vertrieb AG, ERGO Krankenversicherung AG, ERGO Direkt Versicherung AG, ERGO Life S.A., ERGO Vorsorge Lebensversicherung AG, ERGO Reiseversicherung AG, ERGO Direkt AG',
  // Order
  'summary-page.send-order.text': 'Sie können nun Ihren Vertrag abschließen.',
  'summary-page.send-order.server-error':
    'Es gibt ein Problem mit unserem Server. Ihr Antrag konnte nicht gesendet werden.',
};
