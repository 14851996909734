/* eslint-disable max-len */
import { ErgoElementsContext } from '@eg/elements/Provider';
import SvgIcon from '@eg/elements/SvgIcon';
import React, { useContext } from 'react';

interface IDefaultIconProps {
  width?: number;
  height?: number;
}

export const DefaultIcon = ({ width, height }: IDefaultIconProps) => {
  const { idGenerator } = useContext(ErgoElementsContext);

  return (
    <SvgIcon idGenerator={idGenerator} title="fire" viewBox="0 0 52 52" width={width} height={height}>
      <rect fill="none" height="47" rx="4" width="47" x="2.5" y="2.5" />
      <path d="m26 35a2.65 2.65 0 0 1 -.5 0 10.33 10.33 0 0 1 -6.95-9.77v-4.5a.83.83 0 0 1 .45-.73l6.64-2.95a1.07 1.07 0 0 1 .36-.05zm9.26-17-8.83-3.93a1 1 0 0 0 -.85 0l-8.85 3.93a1.05 1.05 0 0 0 -.64 1v5.9a13.73 13.73 0 0 0 9.26 13 2.39 2.39 0 0 0 1.3 0 13.76 13.76 0 0 0 9.26-13v-5.9a1.09 1.09 0 0 0 -.67-.94z" />
    </SvgIcon>
  );
};
