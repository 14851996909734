import { ELossOfRevenue } from 'common/loss-of-revenue.enum';

import { ILossOfRevenuePlus } from './ILossOfRevenuePlus';

// eslint-disable-next-line import/prefer-default-export
export class LossOfRevenuePlus implements ILossOfRevenuePlus {
  // eslint-disable-next-line no-empty-function, no-useless-constructor
  constructor(public isSelected: boolean, public rate: ELossOfRevenue) {}

  public toggleIsSelected(newValue?: boolean): LossOfRevenuePlus {
    return new LossOfRevenuePlus(newValue || !this.isSelected, ELossOfRevenue.Daily500);
  }

  public setRate(newRate: ELossOfRevenue): LossOfRevenuePlus {
    return new LossOfRevenuePlus(this.isSelected, newRate);
  }

  public static getDefaultLossOfRevenuePlus(): LossOfRevenuePlus {
    return new LossOfRevenuePlus(false, ELossOfRevenue.Daily500);
  }
}
