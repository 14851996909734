import { convertCurrencyFormatToNumber } from 'client/helpers/functions/convertCurrencyFormatToNumber';
import { isNumber } from 'client/helpers/functions/isNumber';

export const roundCurrencyInStepsOf100 = (value: string) => {
  const numberValue = convertCurrencyFormatToNumber(value);
  if (numberValue && isNumber(numberValue)) {
    if (numberValue < 100) {
      return 100;
    }
    return Math.ceil(numberValue / 100) * 100;
  }

  // return null, because the numeric values accept null value as well.
  return null;
};
