import { cleanUpPhoneNumber } from 'client/helpers/functions/cleanUpPhoneNumber';
import { validateRequiredField } from 'client/helpers/functions/validations/validateRequiredField';
import { validationMessages } from 'client/i18n/validationMessages';

const phoneNumberRegexp = new RegExp(/^0\d{2,5}\/\d{3,11}$/);

export const validatePhoneNumber = (stringValue: string, isValidationOn = true) => {
  const isValid = phoneNumberRegexp.test(cleanUpPhoneNumber(stringValue));

  if (!isValid && isValidationOn && stringValue) {
    return validationMessages['validation.error.telephone-number'];
  }

  return validateRequiredField(stringValue, isValidationOn);
};
