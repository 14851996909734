import { validateRequiredField } from 'client/helpers/functions/validations/validateRequiredField';
import { validationMessages } from 'client/i18n/validationMessages';

const isValidStringLength = (length: number, riskStreet: string) => riskStreet.length <= length;

const getValidationMessage = (length: number) => {
  switch (length) {
    case 24:
      return validationMessages['validation.error.string-length-is-not-valid.max-24'];
    case 30:
      return validationMessages['validation.error.string-length-is-not-valid.max-30'];
    case 950:
      return validationMessages['validation.error.string-length-is-not-valid.max-950'];
    default:
      return validationMessages['validation.error.string-length-is-not-valid'];
  }
};

export const validateStringLength = (length: number, stringValue: string, isValidationOn = true) => {
  if (!isValidationOn) {
    return false;
  }

  if (!isValidStringLength(length, stringValue)) {
    return getValidationMessage(length);
  }

  return false;
};

export const validateRequiredStringLength = (length: number, stringValue: string, isValidationOn = true) => {
  if (!isValidationOn) {
    return false;
  }

  const requiredValidationError = validateRequiredField(stringValue, isValidationOn);
  if (requiredValidationError) {
    return requiredValidationError;
  }

  if (!isValidStringLength(length, stringValue)) {
    return getValidationMessage(length);
  }

  return false;
};
