import FormRow from '@eg/elements/FormRow';
import FormSection from '@eg/elements/FormSection';
import Select from '@eg/elements/Select';
import { withI18n } from 'client/i18n/withI18n';
import { BusinessDataContext } from 'client/store/BusinessDataContext';
import { EInfoMessages } from 'common/EInfoMessages';
import { EFormOfPayment } from 'common/form-of-payment.enum';
import React, { useContext } from 'react';
import { FormattedHTMLMessage, InjectedIntlProps } from 'react-intl';

import { messages } from './messages';

const PaymentMethodFormComponent = ({ intl }: InjectedIntlProps) => {
  const { formOfPayment, setFormOfPayment } = useContext(BusinessDataContext);

  return (
    <FormSection
      heading={intl.formatMessage({
        id: 'extra-benefits-page.payment-method.heading',
      })}
    >
      <FormRow
        label={intl.formatMessage({
          id: 'extra-benefits-page.payment-method.label',
        })}
        tooltip={<FormattedHTMLMessage id={EInfoMessages.I118} />}
      >
        <Select
          value={formOfPayment}
          onChange={(event: any) => {
            setFormOfPayment(event.target.value);
          }}
        >
          <option value={EFormOfPayment.Yearly}>
            {intl.formatMessage({
              id: 'extra-benefits-page.payment-method.option1',
            })}
          </option>
          <option value={EFormOfPayment.HalfYearly}>
            {intl.formatMessage({
              id: 'extra-benefits-page.payment-method.option2',
            })}
          </option>
          <option value={EFormOfPayment.Quarterly}>
            {intl.formatMessage({
              id: 'extra-benefits-page.payment-method.option3',
            })}
          </option>
        </Select>
      </FormRow>
    </FormSection>
  );
};

export const PaymentMethodForm = withI18n(PaymentMethodFormComponent, messages);
