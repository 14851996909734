import { IFrontAddress, mapAddressToDto } from 'common/address.interface';
import { BusinessTypeRequestDto } from 'common/dto/business-types-request.dto';
import { BusinessTypesResponseDto, IBusinessTypesResponse } from 'common/dto/business-types-response';
import { IOrderRequestDto } from 'common/dto/order-request.dto';
import { IOrderResponse } from 'common/dto/order-response.dto';
import { IRiskAddressResponse, RiskAddressResponseDto } from 'common/dto/risk-address-response.dto';
import { TariffRequestDto } from 'common/dto/tariff-request.dto';
import { ITariffResponse, TariffResponseDto } from 'common/dto/tariff-response.dto';

let apiUrl = window.location.origin;
if (apiUrl.indexOf('localhost:3000')) {
  apiUrl = apiUrl.replace(':3000', ':7001');
}

function fetchWithTimeout(url: string, requestInfo?: RequestInit, timeout: number = 15000): any {
  return new Promise((resolve, reject) => {
    // Set timeout timer
    const timer = setTimeout(() => reject(new Error('Request timed out')), timeout);

    fetch(url, requestInfo)
      .then((response) => response.json().then((result) => {
        if (result.status && result.status.toUpperCase() === 'OK') {
          resolve(result);
        } else if (result.error && result.requestId) {
          reject(new Error(result.requestId.split(':')[0].slice(7, 13)));
        }
        reject(new Error('There are no payload in response'));
      }))
      .catch((error) => reject(new Error(error)))
      .finally(() => clearTimeout(timer));
  });
}

// eslint-disable-next-line import/prefer-default-export
export async function getBusinessTypes({ consumer }: BusinessTypeRequestDto) {
  try {
    const url = new URL(`${apiUrl}/api/business-types`);
    url.searchParams.append('consumer', consumer);

    const jsonResult: IBusinessTypesResponse = await fetchWithTimeout(url.toString());
    const result = BusinessTypesResponseDto.fromPayload(jsonResult);
    return { payload: result.businessTypes, error: null };
  } catch (error) {
    console.warn('error', error);
    return { payload: [], error: error.toString().replace('Error: ', '') };
  }
}

export async function getZuersClassAndHazardZoneByAddress(address: IFrontAddress) {
  try {
    const payloadAddress = mapAddressToDto(address);
    const result: IRiskAddressResponse = await fetchWithTimeout(`${apiUrl}/api/risk-address`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payloadAddress),
    });
    return { payload: new RiskAddressResponseDto(result.status, result.zuersClass, result.hazardZone), error: null };
  } catch (error) {
    console.warn('error', error);
    return { payload: {}, error: error.toString() };
  }
}

let lastTariffRequest: TariffRequestDto;
export async function getTariff(tariffRequest: TariffRequestDto) {
  try {
    lastTariffRequest = tariffRequest;
    const result: ITariffResponse = await fetchWithTimeout(`${apiUrl}/api/tariff`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(tariffRequest),
    });

    // returns response ONLY of the last request
    if (lastTariffRequest === tariffRequest) {
      return { payload: new TariffResponseDto(result.price, result.formOfPayment, result.status), error: null };
    }
    return { payload: null, error: null };
  } catch (error) {
    console.warn('error', error);
    return { payload: null, error: error.toString() };
  }
}

export async function sendOrder(data: IOrderRequestDto) {
  try {
    const result: IOrderResponse = await fetchWithTimeout(`${apiUrl}/api/order`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    return { payload: result, error: null };
  } catch (error) {
    console.warn('error', error);
    return { payload: null, error: error.toString().replace('Error: ', '') };
  }
}
