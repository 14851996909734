import LoadingSpinner from '@eg/elements/LoadingSpinner';
import MessageBox from '@eg/elements/MessageBox';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './styles.module.scss';

interface RiskAddressModal {
  isRiskAddressLoading: boolean;
  riskAddressError: null | string;
}

export const RiskAddressRequestStatus = ({ isRiskAddressLoading, riskAddressError }: RiskAddressModal) => {
  const renderRiskAddressMessage = () => {
    if (isRiskAddressLoading) {
      return (
        <div style={{ textAlign: 'center' }}>
          <FormattedMessage id="validation.process.checking-risk-address" />
        </div>
      );
    }

    if (riskAddressError) {
      return <FormattedMessage id="validation.error.risk-address-is-not-valid" />;
    }

    return null;
  };

  return (
    <div>
      {riskAddressError && (
        <MessageBox type="error">
          <FormattedMessage id="validation.error.risk-address-is-not-valid" />
          <FormattedMessage id="validation.info.risk-address-is-not-valid" />
        </MessageBox>
      )}
      {isRiskAddressLoading && (
        <MessageBox type={riskAddressError ? 'error' : 'info'}>
          <div className={styles.spinner}>
            <LoadingSpinner viewport="relative" show={isRiskAddressLoading} />
          </div>
          {renderRiskAddressMessage()}
        </MessageBox>
      )}
    </div>
  );
};
