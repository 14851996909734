import Checkbox from '@eg/elements/Checkbox';
import ControlWithHint from '@eg/elements/ControlWithHint';
import FormRow from '@eg/elements/FormRow';
import Input from '@eg/elements/Input';
import TooltipIcon from '@eg/elements/TooltipIcon';
import { EPlausiNr, ErrorMessage } from 'client/components/molecules/ErrorMessage/ErrorMessage';
import { MAX_ELECTRONICS_INSURANCE_SUM } from 'client/helpers/constants';
import { convertCurrencyFormatToNumber } from 'client/helpers/functions/convertCurrencyFormatToNumber';
import { isElectronicsInsuranceSumInvalid } from 'client/helpers/functions/isElectronicsInsuranceSumInvalid';
import { roundCurrencyInStepsOf100 } from 'client/helpers/functions/roundCurrencyInStepsOf100';
import { validateInsuranceSum } from 'client/helpers/functions/validations';
import { withI18n } from 'client/i18n/withI18n';
import { BusinessDataContext } from 'client/store/BusinessDataContext';
import { GlobalsContext } from 'client/store/GlobalsContext';
import { EInfoMessages } from 'common/EInfoMessages';
import React, { useContext, useEffect } from 'react';
import { FormattedHTMLMessage, InjectedIntlProps } from 'react-intl';

import { messages } from './messages';
import styles from './styles.module.scss';

const ExtraElectronicsFormComponent = ({ intl }: InjectedIntlProps) => {
  const { electronics, setElectronics } = useContext(BusinessDataContext);

  const { setIsErrorMessage, isValidationOn, setIsValidationError } = useContext(GlobalsContext);

  // check for validation error
  useEffect(() => {
    setIsValidationError(!!validateInsuranceSum(electronics.insuranceSum, MAX_ELECTRONICS_INSURANCE_SUM));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [electronics.insuranceSum]);

  // it works like componentWillUnmount. before componentWillUnmount set the validation to false,
  // so the user can go on, even if the insurance sum was not correct.
  // it is required to cover the case if the diselected the extra electronics checkbox
  useEffect(() => () => {
    setIsValidationError(false);
  }, []);

  return (
    <>
      <FormRow
        label={intl.formatMessage({
          id: 'extra-benefits-page.business-topic.extra-items.item-3.input.label',
        })}
        boundary="narrow"
        tooltip={<FormattedHTMLMessage id={EInfoMessages.I116} />}
      >
        <ControlWithHint
          error={validateInsuranceSum(electronics.insuranceSum, MAX_ELECTRONICS_INSURANCE_SUM, isValidationOn)}
          description={intl.formatMessage({
            id: 'validation.description.insurance-sum',
          })}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'extra-benefits-page.business-topic.extra-items.item-3.input.placeholder',
            })}
            name="electronicsInsuranceSum"
            value={electronics.insuranceSum ? intl.formatNumber(electronics.insuranceSum) : ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setElectronics(electronics.setInsuranceSum(convertCurrencyFormatToNumber(e.target.value)));
              setIsErrorMessage(isElectronicsInsuranceSumInvalid(convertCurrencyFormatToNumber(e.target.value)));
            }}
            /* eslint-disable-next-line max-len */
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => setElectronics(electronics.setInsuranceSum(roundCurrencyInStepsOf100(e.target.value)))}
            adornmentRight="€"
            disabled={electronics.isInsuranceSumLocked}
          />
        </ControlWithHint>
      </FormRow>
      {isElectronicsInsuranceSumInvalid(electronics.insuranceSum) && <ErrorMessage plausiNr={EPlausiNr.P003} />}
      <div
        style={{ display: 'flex', alignItems: 'center' }}
        className={isElectronicsInsuranceSumInvalid(electronics.insuranceSum) ? 'u-hidden' : ''}
      >
        {electronics.isAdditionalCoverageAllowed && (
          <>
            <Checkbox
              label={intl.formatMessage({ id: 'extra-benefits-page.business-topic.extra-items.item-3.checkbox.label' })}
              checked={electronics.isAdditionalCoverageSelected}
              onChange={() => setElectronics(electronics.setAdditionalCoverageSelected(!electronics.isAdditionalCoverageSelected))}
            />
            <TooltipIcon className={styles.checkboxTooltip}>
              <FormattedHTMLMessage id={EInfoMessages.I117} />
            </TooltipIcon>
          </>
        )}
      </div>
    </>
  );
};

export const ExtraElectronicsForm = withI18n(ExtraElectronicsFormComponent, messages);
