import Button from '@eg/elements/Button';
import Modal from '@eg/elements/Modal';
import { withI18n } from 'client/i18n/withI18n';
import { GlobalsContext } from 'client/store/GlobalsContext';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from './messages';

const ServerErrorModalComponent = ({ message, action }: { message: string; action: () => void }) => {
  const { isServerErrorModelOpen, setIsServerErrorModelOpen } = useContext(GlobalsContext);

  return (
    <Modal open={isServerErrorModelOpen} onDismiss={() => setIsServerErrorModelOpen(false)} dismissible>
      <h3 className="esc_h3">
        <FormattedMessage id="server-error-modal.header" />
      </h3>
      <p>{message}</p>
      <p>
        <FormattedMessage id="server-error-modal.description" />
      </p>
      <div className="navigation-buttons__wrapper u-padding-top-m">
        <Button
          className="navigation-buttons__next-button"
          type="button"
          variant="primary"
          onClick={() => {
            setIsServerErrorModelOpen(false);
            action();
          }}
        >
          <FormattedMessage id="server-error-modal.try-again.btn" />
        </Button>
        <Button
          variant="secondary"
          type="button"
          onClick={() => {
            setIsServerErrorModelOpen(false);
          }}
        >
          <FormattedMessage id="server-error-modal.close.btn" />
        </Button>
      </div>
    </Modal>
  );
};

export const ServerErrorModal = withI18n(ServerErrorModalComponent, messages);
