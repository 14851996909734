/* eslint-disable max-len */
import { ErgoElementsContext } from '@eg/elements/Provider';
import SvgIcon from '@eg/elements/SvgIcon';
import React from 'react';

import styles from './styles.module.scss';

/* eslint-disable max-len */
export const Logo = () => (
  <ErgoElementsContext.Consumer>
    {({ idGenerator }) => (
      <SvgIcon
        idGenerator={idGenerator}
        viewBox="0 0 66 20"
        title="ERGO"
        width={66}
        height={20}
        className={styles.color}
        id="ergo-logo"
      >
        <path d="M65.4424779,9.68997027 C65.4424779,15.316085 61.6924003,19.3548387 56.177658,19.3548387 C50.9636358,19.3548387 47.0866609,15.6483541 47.0866609,9.68997027 C47.0866609,3.29098316 51.6880075,0 56.3521417,0 C60.8219646,0 65.4424779,3.12055488 65.4424779,9.68997027 Z M60.9290341,9.68997027 C60.9290341,7.00407354 59.6289984,3.88682153 56.2510205,3.88682153 C53.1889638,3.88682153 51.5756505,6.51921171 51.5756505,9.68997027 C51.5756505,13.2537708 53.5637072,15.4944402 56.2510205,15.4944402 C59.6296593,15.4944402 60.9290341,12.4102169 60.9290341,9.68997027 Z" />
        <path d="M45.4905029,17.6113561 C44.3815345,18.3505345 42.0481215,19.3548387 38.8672204,19.3548387 C32.6169123,19.3548387 29.5289229,15.058612 29.5289229,9.80965163 C29.5282593,3.90019108 33.7252171,0 39.2096667,0 C41.9266725,0 43.6707594,0.451573234 45.2164132,1.20463606 L43.6322673,4.84565187 C42.4542787,4.35440895 41.0944485,4.0641591 39.4830927,4.0641591 C35.5190781,4.0641591 34.1738482,6.42714993 34.1738482,9.68932026 C34.1738482,13.2106659 35.979655,15.4936562 39.1253824,15.4936562 C40.2635517,15.4936562 41.1190037,15.2510099 41.382475,15.0804303 L41.382475,11.8056979 L38.1889644,11.8056979 L38.1942736,8.0992271 L45.4878483,8.0992271 L45.4905029,17.6113561 Z" />
        <path d="M24.5297433,18.5483871 C23.9290716,16.3472826 22.9247435,14.6727396 22.096253,13.6021936 C21.3973946,12.6987792 20.8531964,12.5051904 20.8531964,12.5051904 C20.2884623,12.5206775 19.9002077,12.5187416 19.3823209,12.4948657 L19.3823209,18.5477418 L15.163501,18.5477418 L15.163501,0.442027733 C16.0516736,0.297481438 16.6889246,0.219400627 17.620094,0.132930968 C18.4793882,0.0522689728 19.4490622,0 20.5239821,0 C21.9467268,0 23.1551293,0.15809751 24.1472643,0.473647235 C25.1400411,0.78919696 25.9505628,1.22219055 26.5794711,1.77133741 C27.224423,2.32112957 27.6877616,3.00256211 27.9688452,3.75110542 C28.266614,4.48287104 28.4154985,5.26496974 28.4154985,6.09675624 C28.4154985,7.19440467 28.1485333,8.25849771 27.5677557,9.16836501 C27.0517941,9.97627555 26.1854408,10.803545 25.2882837,11.372696 L25.2882837,11.4527127 C27.2930896,12.3748406 28.4283333,15.3438474 29.5289229,18.5483871 L24.5297433,18.5483871 Z M24.1825602,6.20000359 C24.1825602,5.43468258 23.9091776,4.82165142 23.3636958,4.40608082 C22.8175724,3.97308723 22.0667328,3.76143016 21.1573826,3.74723364 C21.056629,3.74594305 20.6638821,3.74981483 20.4283623,3.75820368 C20.1960513,3.76401134 19.5767691,3.82015209 19.3829626,3.85564337 L19.3829626,9.07221591 C19.6640462,9.1057713 20.2480325,9.13222844 20.7229225,9.13222844 C21.7317428,9.13222844 22.6706132,8.85281529 23.2667926,8.38820219 C23.8623303,7.90487552 24.1825602,7.13181096 24.1825602,6.20000359 Z" />
        <path d="M0,14.153075 L0,0 L12.4262187,0 L12.4294368,3.94707793 L4.23174953,3.94707793 L4.23174953,7.12266964 L11.1692443,7.12266964 L11.1737496,10.8688601 L4.23174953,10.8688601 L4.23174953,12.8528688 C4.23174953,14.0994178 4.95195146,14.6268291 5.89098151,14.6268291 C7.79671693,14.6268291 12.7641151,14.6261747 12.7641151,14.6261747 L12.769264,18.5483871 L4.29160545,18.5483871 C1.11666699,18.5483871 0,17.0584175 0,14.153075 Z" />
      </SvgIcon>
    )}
  </ErgoElementsContext.Consumer>
);
