import { Footer } from 'client/components/organisms/Footer/Footer';
import { Header } from 'client/components/organisms/Header/Header';
import React from 'react';

import styles from './styles.module.scss';

interface IBasicTemplate {
  children: React.ReactNode;
}

export const BasicTemplate = ({ children }: IBasicTemplate) => (
  <>
    <Header />
    <div className={`esc_box esc_container esc_container--l ${styles.wrapper}`}>{children}</div>
    <Footer />
  </>
);
