import FormRow from '@eg/elements/FormRow';
import Select from '@eg/elements/Select';
import { translateLossOfRevenue } from 'client/i18n/translateEnums';
import { withI18n } from 'client/i18n/withI18n';
import { BusinessDataContext } from 'client/store/BusinessDataContext';
import { ELossOfRevenue } from 'common/loss-of-revenue.enum';
import React, { useContext } from 'react';
import { InjectedIntlProps } from 'react-intl';

import { messages } from './messages';

const LossOfRevenuePlusFormComponent = ({ intl }: InjectedIntlProps) => {
  const { lossOfRevenuePlus, setLossOfRevenuePlus } = useContext(BusinessDataContext);

  const setLossOfRevenuePlusRateHandler = (event: any) => {
    const newRate = event.target.value as ELossOfRevenue;
    setLossOfRevenuePlus(lossOfRevenuePlus.setRate(newRate));
  };
  return (
    <FormRow
      label={intl.formatMessage({
        id: 'extra-benefits-page.business-topic.extra-items.item-1.select.label',
      })}
      boundary="narrow"
    >
      <Select
        label={intl.formatMessage({
          id: 'extra-benefits-page.business-topic.extra-items.item-1.select.label',
        })}
        value={lossOfRevenuePlus.rate}
        onChange={setLossOfRevenuePlusRateHandler}
      >
        <option value={ELossOfRevenue.Daily500}>{translateLossOfRevenue(ELossOfRevenue.Daily500)}</option>
        <option value={ELossOfRevenue.Daily1000}>{translateLossOfRevenue(ELossOfRevenue.Daily1000)}</option>
        <option value={ELossOfRevenue.Daily2000}>{translateLossOfRevenue(ELossOfRevenue.Daily2000)}</option>
        <option value={ELossOfRevenue.Daily3000}>{translateLossOfRevenue(ELossOfRevenue.Daily3000)}</option>
        <option value={ELossOfRevenue.Daily4000}>{translateLossOfRevenue(ELossOfRevenue.Daily4000)}</option>
        <option value={ELossOfRevenue.Daily5000}>{translateLossOfRevenue(ELossOfRevenue.Daily5000)}</option>
      </Select>
    </FormRow>
  );
};

export const LossOfRevenuePlusForm = withI18n(LossOfRevenuePlusFormComponent, messages);
