import { validateRequiredField } from 'client/helpers/functions/validations/validateRequiredField';
import { notValidEmailEndings } from 'client/helpers/notValidEmailEndings';
import { validationMessages } from 'client/i18n/validationMessages';

const isValidEMail = (eMail: string) => /^[^\s@]+@[^\s@][^\s.@]*\.[^\s@]+$/.test(eMail);

const isAllowedEMail = (eMail: string) => {
  let isValid = true;

  notValidEmailEndings.forEach((notValidEMailEnding: string) => {
    if (eMail.indexOf(notValidEMailEnding) !== -1) {
      isValid = false;
    }
  });

  return isValid;
};

export const validateEmail = (eMail: string, isValidationOn = true) => {
  let isValid: string | boolean = true;
  isValid = validateRequiredField(eMail, isValidationOn);
  if (!isValid && isValidationOn) {
    if (!isValidEMail(eMail)) {
      isValid = validationMessages['validation.error.eMail-is-not-valid'];
    } else if (!isAllowedEMail(eMail)) {
      isValid = validationMessages['validation.error.eMail-is-not-allowed'];
    }
  }
  return isValid;
};
