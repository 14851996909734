import Details from '@eg/elements/Details';
import EditIcon from '@eg/elements/Icons/EditIcon';
import { withI18n } from 'client/i18n/withI18n';
import { IStepNavigationContext, StepNavigationContext } from 'client/store/StepNavigationContext';
import React, { Fragment, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { messages } from './messages';
import styles from './styles.module.scss';

interface ISummaryBox {
  title: string;
  editLink: string;
  data: { name: string; value: string }[];
  renderAdditional?: () => void;
}

const SummaryBoxComponent = ({
  title, editLink, data, renderAdditional,
}: ISummaryBox) => {
  const { goToStepByUrl }: IStepNavigationContext = useContext(StepNavigationContext);

  const renderData = () => {
    const dataToReturn: any[] = [];
    data.forEach((entry) => {
      if (
        (entry.value && typeof entry.value === 'string' && entry.value.replace(',', '').trim() !== '') ||
        (entry.value && (typeof entry.value === 'number' || typeof entry.value === 'object'))
      ) {
        dataToReturn.push(
          <Fragment key={entry.name}>
            <div className="esc_col esc_col-12 esc_col-m-4">{entry.name}</div>
            <div className="esc_col esc_col-12 esc_col-m-8">
              {Array.isArray(entry.value) ? entry.value.map((v) => <div key={entry.name + v}>{v}</div>) : entry.value}
            </div>
          </Fragment>,
        );
      }
    });
    return dataToReturn;
  };

  return (
    <Details className={styles.summaryBox} summary={title}>
      <div className="esc_grid">
        <div className="esc_grid__wrapper">
          {renderData()}
          {renderAdditional && renderAdditional()}
        </div>
      </div>
      <Link
        onClick={() => {
          goToStepByUrl(editLink);
        }}
        to={editLink}
        className={`esc_text-link ${styles.summaryLink} print-hide`}
      >
        <span className={styles.summaryLinkLabel}>
          <FormattedMessage id="summary-box.button.text" />
        </span>
        <EditIcon className={styles.summaryLinkIcon} />
      </Link>
    </Details>
  );
};

export const SummaryBox = withI18n(SummaryBoxComponent, messages);
