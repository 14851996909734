import { addYears } from 'client/helpers/functions/addYears';
import { generateTomorrowDate } from 'client/helpers/functions/generateTomorrowDate';
import { validateRequiredField } from 'client/helpers/functions/validations/validateRequiredField';
import { validationMessages } from 'client/i18n/validationMessages';
import { toISODate } from 'common/helper/toISODate';
import moment from 'moment';

const isValidInceptionDate = (inceptionDate: Date | '') => (
  inceptionDate !== '' &&
  moment(toISODate(inceptionDate)).isSameOrAfter(toISODate(generateTomorrowDate())) &&
  moment(toISODate(inceptionDate)).isSameOrBefore(toISODate(addYears(new Date(), 1)))
);

export const validateInceptionDate = (inceptionDate: Date | '', isValidationOn = true) => {
  let isValid: string | boolean = true;
  isValid = validateRequiredField(inceptionDate, isValidationOn);
  if (!isValid && isValidationOn && !isValidInceptionDate(inceptionDate)) {
    isValid = validationMessages['validation.error.inseption-date-is-not-valid'];
  }
  return isValid;
};
