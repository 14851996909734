import FormSection from '@eg/elements/FormSection';
import YesNoInput from '@eg/elements/YesNoInput';
import { EPlausiNr, ErrorMessage } from 'client/components/molecules/ErrorMessage/ErrorMessage';
import { DamagesForm } from 'client/components/organisms/DamagesForm/DamagesForm';
import { PreinsuranceProductForm } from 'client/components/organisms/PreinsuranceProductForm/PreinsuranceProductForm';
import { AdvisoryTemplate } from 'client/components/templates/AdvisoryTemplate/AdvisoryTemplate';
import { validateRequiredYesNoInput } from 'client/helpers/functions/validations';
import { withI18n } from 'client/i18n/withI18n';
import { BusinessDataContext } from 'client/store/BusinessDataContext';
import { GlobalsContext } from 'client/store/GlobalsContext';
import { ECancellationStatus } from 'common/cancellation-status.enum';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, InjectedIntlProps } from 'react-intl';

import { messages } from './messages';

const PreinsuranceDamagesPageComponent = ({ intl }: InjectedIntlProps) => {
  const {
    hasPreinsurance,
    hadDamages,
    setHadDamages,
    setHasPreinsurance,
    combinedShopContentsProduct,
    setCombinedShopContentsProduct,
    glassProduct,
    setGlassProduct,
    electronicFlatProduct,
    setElectronicFlatProduct,
    electronics,
    glass,
  } = useContext(BusinessDataContext);

  const { setIsErrorMessage, isValidationOn, setIsValidationError } = useContext(GlobalsContext);

  const [isCombinedShopContentsProductErrorMessage, setIsCombinedShopContentsProductErrorMessage] = useState(false);
  const [isGlasProductErrorMessage, setIsGlasProductErrorMessage] = useState(false);
  const [isElectronicFlatErrorMessage, setIsElectronicFlatErrorMessage] = useState(false);

  const [isGlassProductFormValid, setIsGlassProductFormValid] = useState(true);
  const [isCombinedShopContentsProductFormValid, setIsCombinedShopContentsProductFormValid] = useState(true);
  const [isElectronicFlatProductFormValid, setIsElectronicFlatProductFormValid] = useState(true);
  const [isDamagesFormValid, setIsDamagesFormValid] = useState(true);

  const isGlassFormVisible = !!glass && !isCombinedShopContentsProductErrorMessage;
  const isElectronicsFormVisible =
    !!electronics.isSelected && !(isCombinedShopContentsProductErrorMessage || isGlasProductErrorMessage);

  // check for validation error
  useEffect(() => {
    setIsValidationError(
      !!validateRequiredYesNoInput(hasPreinsurance) ||
      !!validateRequiredYesNoInput(hadDamages) ||
      (hasPreinsurance &&
        (!isGlassProductFormValid || !isCombinedShopContentsProductFormValid || !isElectronicFlatProductFormValid)) ||
      (hadDamages && !isDamagesFormValid),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasPreinsurance,
    hadDamages,
    isGlassProductFormValid,
    isCombinedShopContentsProductFormValid,
    isElectronicFlatProductFormValid,
    isDamagesFormValid,
  ]);

  const checkIsPreinsuranceErrorMessage = () => (
    combinedShopContentsProduct.cancellationStatus === ECancellationStatus.ByInsurance ||
    glassProduct.cancellationStatus === ECancellationStatus.ByInsurance ||
    electronicFlatProduct.cancellationStatus === ECancellationStatus.ByInsurance
  );

  // check isErrorMessage on load of the component
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setIsCombinedShopContentsProductErrorMessage(
      combinedShopContentsProduct.cancellationStatus === ECancellationStatus.ByInsurance,
    );
    setIsGlasProductErrorMessage(glassProduct.cancellationStatus === ECancellationStatus.ByInsurance);
    setIsElectronicFlatErrorMessage(electronicFlatProduct.cancellationStatus === ECancellationStatus.ByInsurance);
    setIsErrorMessage(checkIsPreinsuranceErrorMessage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AdvisoryTemplate>
      <FormSection
        heading={intl.formatMessage({
          id: 'preinsurance-damages-page.preinsurance.heading',
        })}
      >
        <p>
          <FormattedMessage id="preinsurance-damages-page.preinsurance.question" />
        </p>
        <YesNoInput
          name="has-preinsurance"
          data-testid="hasPreinsurance"
          value={hasPreinsurance}
          onChange={(hasPreinsuranceValue) => setHasPreinsurance(hasPreinsuranceValue)}
          error={validateRequiredYesNoInput(hasPreinsurance, isValidationOn)}
        />
        <div data-testid="preinsuranceForm" className={hasPreinsurance ? '' : 'u-hidden'}>
          <PreinsuranceProductForm
            preinsuranceProduct={combinedShopContentsProduct}
            setPreinsuranceProduct={setCombinedShopContentsProduct}
            productName="preinsurance-damages-page.preinsurance.product.combined-shop-contents.label"
            setIsFormValid={setIsCombinedShopContentsProductFormValid}
            updateErrorMessagesState={(cancellationStatus: ECancellationStatus) => {
              if (cancellationStatus === ECancellationStatus.ByInsurance) {
                setIsCombinedShopContentsProductErrorMessage(true);
                setIsErrorMessage(true);
              } else {
                setIsCombinedShopContentsProductErrorMessage(false);
                setIsErrorMessage(isGlasProductErrorMessage || isElectronicFlatErrorMessage);
              }
            }}
            disableSelectNone={!isGlassFormVisible && !isElectronicsFormVisible}
            isVisible
          />
          <PreinsuranceProductForm
            preinsuranceProduct={glassProduct}
            setPreinsuranceProduct={setGlassProduct}
            productName="preinsurance-damages-page.preinsurance.product.glass.label"
            setIsFormValid={setIsGlassProductFormValid}
            updateErrorMessagesState={(cancellationStatus: ECancellationStatus) => {
              if (cancellationStatus === ECancellationStatus.ByInsurance) {
                setIsGlasProductErrorMessage(true);
                setIsErrorMessage(true);
              } else {
                setIsGlasProductErrorMessage(false);
                setIsErrorMessage(isCombinedShopContentsProductErrorMessage || isElectronicFlatErrorMessage);
              }
            }}
            isVisible={isGlassFormVisible}
          />
          <PreinsuranceProductForm
            preinsuranceProduct={electronicFlatProduct}
            setPreinsuranceProduct={setElectronicFlatProduct}
            productName="preinsurance-damages-page.preinsurance.product.electronic-flat.label"
            setIsFormValid={setIsElectronicFlatProductFormValid}
            setIsProductErrorMessage={setIsElectronicFlatErrorMessage}
            updateErrorMessagesState={(cancelationState: ECancellationStatus) => {
              if (cancelationState === ECancellationStatus.ByInsurance) {
                setIsElectronicFlatErrorMessage(true);
                setIsErrorMessage(true);
              } else {
                setIsElectronicFlatErrorMessage(false);
                setIsErrorMessage(isCombinedShopContentsProductErrorMessage || isGlasProductErrorMessage);
              }
            }}
            isVisible={isElectronicsFormVisible}
          />
        </div>
        {checkIsPreinsuranceErrorMessage() && <ErrorMessage plausiNr={EPlausiNr.P003} />}
      </FormSection>

      <div
        className={
          isCombinedShopContentsProductErrorMessage || isGlasProductErrorMessage || isElectronicFlatErrorMessage
            ? 'u-hidden'
            : ''
        }
      >
        <FormSection
          heading={intl.formatMessage({
            id: 'preinsurance-damages-page.damages.heading',
          })}
        >
          <p>
            <FormattedMessage id="preinsurance-damages-page.damages.question" />
          </p>
          <YesNoInput
            name="had-damages"
            data-testid="hasDamages"
            value={hadDamages}
            onChange={(hadDamagesValue) => setHadDamages(hadDamagesValue)}
            error={validateRequiredYesNoInput(hadDamages, isValidationOn)}
          />
          <DamagesForm setIsFormValid={setIsDamagesFormValid} />
        </FormSection>
      </div>
    </AdvisoryTemplate>
  );
};

export const PreinsuranceDamagesPage = withI18n(PreinsuranceDamagesPageComponent, messages);
