import { Logo } from 'client/components/atoms/Logo/Logo';
import { withI18n } from 'client/i18n/withI18n';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from './messages';
import styles from './styles.module.scss';

const HeaderComponent = () => (
  <header className={`esc_container esc_box esc_box--around esc_box-shadow ${styles.header}`}>
    <Logo />
    <a href="https://www.ergo.de" className="esc_text-link print-hide" rel="noopener noreferrer">
      <FormattedMessage id="header.back-to-portsl.link" />
    </a>
  </header>
);

export const Header = withI18n(HeaderComponent, messages);
