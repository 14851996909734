import Button from '@eg/elements/Button';
import ArrowLeftIcon from '@eg/elements/Icons/ArrowLeftIcon';
import ArrowRightIcon from '@eg/elements/Icons/ArrowRightIcon';
import MessageBox from '@eg/elements/MessageBox';
import SalesmanButton from '@eg/elements/SalesmanButton';
import StepNavigation from '@eg/elements/StepNavigation';
import { ConsultModal } from 'client/components/molecules/ConsultModal/ConsultModal';
import { isPageValidationDisabled } from 'client/helpers/functions/isPageValidationDisabled';
import { primaryStepNames, steps } from 'client/helpers/StepNavigationConfig';
import { withI18n } from 'client/i18n/withI18n';
import { history } from 'client/routers/history';
import { GlobalsContext } from 'client/store/GlobalsContext';
import { RiskAddressContext } from 'client/store/RiskAddressContext';
import { SendOrderContext } from 'client/store/SendOrderContext';
import { IStepNavigationContext, StepNavigationContext } from 'client/store/StepNavigationContext';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from './messages';

interface IGpOnlineStepNavigation {
  children: React.ReactNode;
}

const GpOnlineStepNavigationComponent = ({ children }: IGpOnlineStepNavigation) => {
  const {
    activePrimaryStepIndex,
    activeStepIndex,
    goToNextStep,
    goToPrevStep,
    goToPrimaryStepByIndex,
    updateNavigationByUrl,
  }: IStepNavigationContext = useContext(StepNavigationContext);

  const {
    isErrorMessage,
    isValidationOn,
    setIsValidationOn,
    isValidationError,
    isFirstPageLoad,
    setIsFirstPageLoad,
    setIsConsultModelOpen,
  } = useContext(GlobalsContext);

  const { error: riskAddressError, isLoading: isRiskAddressLoading } = useContext(RiskAddressContext);

  const { isLoading: isSendOrderRequesting, requestSendOrder } = useContext(SendOrderContext);

  useEffect(() => {
    // reset validation on page load
    setIsFirstPageLoad(true);
    setIsValidationOn(false);

    // update navigation styles by url, it is needed for the case the user is using the navigation in brouser
    updateNavigationByUrl(history.location.pathname);
  }, []);

  const isNextButtonDisabled = () => {
    if (isErrorMessage || (isValidationError && !isFirstPageLoad) || riskAddressError || isRiskAddressLoading) {
      return true;
    }
    if (steps[activeStepIndex].buttons && steps[activeStepIndex].buttons!.next) {
      return steps[activeStepIndex].buttons!.next!.isDisable ? steps[activeStepIndex].buttons!.next!.isDisable : false;
    }
    return false;
  };

  const isPrevButtonDisabled = () => {
    if (steps[activeStepIndex].buttons && steps[activeStepIndex].buttons!.prev) {
      return steps[activeStepIndex].buttons!.prev!.isDisable ? steps[activeStepIndex].buttons!.prev!.isDisable : false;
    }
    return false;
  };

  const isNextButtonHidden = () => {
    if (steps[activeStepIndex].buttons && steps[activeStepIndex].buttons!.next) {
      return steps[activeStepIndex].buttons!.next!.isHidden ? steps[activeStepIndex].buttons!.next!.isHidden : false;
    }
    return false;
  };

  const isPrevButtonHidden = () => {
    if (steps[activeStepIndex].buttons && steps[activeStepIndex].buttons!.prev) {
      return steps[activeStepIndex].buttons!.prev!.isHidden ? steps[activeStepIndex].buttons!.prev!.isHidden : false;
    }
    return false;
  };

  const isSalesmanButtonVisible = () => {
    if (steps[activeStepIndex].buttons && steps[activeStepIndex].buttons!.next) {
      return steps[activeStepIndex].buttons!.next!.isSalesman && isNextButtonHidden();
    }
    return false;
  };

  const renderPrevButtonText = () => {
    if (steps[activeStepIndex].buttons && steps[activeStepIndex].buttons!.prev) {
      return steps[activeStepIndex].buttons!.prev!.text ? (
        steps[activeStepIndex].buttons!.prev!.text
      ) : (
        <FormattedMessage id="step-navigation.prev-btn.text" />
      );
    }
    return <FormattedMessage id="step-navigation.prev-btn.text" />;
  };

  const renderNextButtonText = () => {
    if (steps[activeStepIndex].buttons && steps[activeStepIndex].buttons!.next) {
      return steps[activeStepIndex].buttons!.next!.text ? (
        steps[activeStepIndex].buttons!.next!.text
      ) : (
        <FormattedMessage id="step-navigation.next-btn.text" />
      );
    }
    return <FormattedMessage id="step-navigation.next-btn.text" />;
  };

  const goToNextStepHandler = () => {
    if (steps[activeStepIndex].buttons && steps[activeStepIndex].buttons!.next) {
      if (steps[activeStepIndex].buttons!.next!.hasConsultModal) {
        // show modal and skip go to the next page
        setIsConsultModelOpen(true);
        return null;
      }
      return steps[activeStepIndex].buttons!.next!.url
        ? history.push(steps[activeStepIndex].buttons!.next!.url!)
        : goToNextStep();
    }
    return goToNextStep();
  };

  const goToPrevStepHandler = () => {
    if (steps[activeStepIndex].buttons && steps[activeStepIndex].buttons!.prev) {
      return steps[activeStepIndex].buttons!.prev!.url
        ? history.push(steps[activeStepIndex].buttons!.prev!.url!)
        : goToPrevStep();
    }
    return goToPrevStep();
  };

  const processValidationLogicBefore = (nextAction: () => void) => {
    // skip the validation if the page validation is disabled
    if (!isPageValidationDisabled()) {
      // switch on validation, if it was not
      if (!isValidationOn) {
        setIsValidationOn(true);
      }
      if (isFirstPageLoad && isValidationError) {
        setIsFirstPageLoad(false);
      } else {
        setIsValidationOn(false);
        nextAction();
      }
    } else {
      nextAction();
    }
  };

  return (
    <>
      <StepNavigation
        className="print-hide"
        activeStepIndex={activePrimaryStepIndex}
        steps={primaryStepNames}
        goToStepByIndex={goToPrimaryStepByIndex}
      />
      {children}
      {isSalesmanButtonVisible() && isNextButtonDisabled() && (
        <MessageBox type="error">
          <FormattedMessage id="step-navigation.send-order-button.text" />
        </MessageBox>
      )}
      <div className="navigation-buttons__wrapper print-hide">
        {isSalesmanButtonVisible() && (
          <SalesmanButton
            className="navigation-buttons__next-button"
            note=""
            data-testid="salesmanBtn"
            onClick={() => {
              processValidationLogicBefore(requestSendOrder);
            }}
            disabled={isNextButtonDisabled()}
            loading={isSendOrderRequesting}
          />
        )}

        <Button
          className={`${isNextButtonHidden() ? 'u-hidden' : ''} navigation-buttons__next-button`}
          type="button"
          variant="primary"
          iconRight={ArrowRightIcon}
          onClick={() => {
            processValidationLogicBefore(goToNextStepHandler);
          }}
          disabled={isNextButtonDisabled()}
          data-testid="nextBtn"
        >
          {renderNextButtonText()}
        </Button>
        <Button
          className={isPrevButtonHidden() ? 'u-hidden' : ''}
          variant="primary"
          type="button"
          iconLeft={ArrowLeftIcon}
          onClick={goToPrevStepHandler}
          disabled={isPrevButtonDisabled()}
          data-testid="prevBtn"
        >
          {renderPrevButtonText()}
        </Button>
      </div>
      <ConsultModal />
    </>
  );
};

export const GpOnlineStepNavigation = withI18n(GpOnlineStepNavigationComponent, messages);
