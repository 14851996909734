import { isNumber } from 'client/helpers/functions/isNumber';

export const convertCurrencyFormatToNumber = (value: any) => {
  // since we do not allow the user to input cents we do not need to clean them.
  const valueWithoutDots = value.replace(/\./g, '');
  if (isNumber(valueWithoutDots)) {
    return Number(valueWithoutDots);
  }

  // return null, because the numeric values accept null value as well.
  return null;
};
