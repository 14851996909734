import { ESelectValues } from 'client/helpers/ESelectValues';
import { DirectDebitMandate } from 'client/model/DirectDebitMandate';
import { Electronics } from 'client/model/Electronics';
import { LossOfRevenuePlus } from 'client/model/LossOfRevenuePlus';
import { Risks } from 'client/model/Risks';
import { IFrontAddress } from 'common/address.interface';
import { ECancellationStatus } from 'common/cancellation-status.enum';
import { EDeductible } from 'common/deductible.enum';
import { EFormOfPayment } from 'common/form-of-payment.enum';
import { ESalutation } from 'common/salutation.enum';
import React, {
  createContext, Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';

import { BusinessTypesContext } from './BusinessTypesContext';

export interface IPreinsuranceProduct {
  insuranceKey: string;
  contractNumber: string;
  cancellationStatus: ECancellationStatus | ESelectValues;
  isEditing: boolean;
}

export const PreinsuranceProductNullObject: IPreinsuranceProduct = {
  insuranceKey: ESelectValues.PleaseSelect,
  contractNumber: '',
  cancellationStatus: ESelectValues.PleaseSelect,
  isEditing: false,
};

export interface IBusinessDataContext {
  // BusinessTypePage (Done)
  businessType: string;
  setBusinessType: Dispatch<SetStateAction<string>>;

  address: IFrontAddress;
  setAddress: Dispatch<SetStateAction<IFrontAddress>>;

  isInclosedBuilding: boolean | undefined;
  setIsInclosedBuilding: Dispatch<SetStateAction<boolean>>;
  hasSecurityMeasures: boolean | undefined;
  setHasSecurityMeasures: Dispatch<SetStateAction<boolean>>;

  // ContributionPage (Done)
  insuranceSum: number | null;
  setInsuranceSum: Dispatch<SetStateAction<number | null>>;
  deductible: EDeductible;
  setDeductible: Dispatch<SetStateAction<EDeductible>>;

  risks: Risks;
  setRisks: Dispatch<SetStateAction<Risks>>;

  // ExtraBenefitsPage (TODO: rename update into set)
  lossOfRevenuePlus: LossOfRevenuePlus;
  setLossOfRevenuePlus: Dispatch<SetStateAction<LossOfRevenuePlus>>;

  glass: boolean;
  updateGlass: (newGlassState: boolean) => void;

  electronics: Electronics;
  setElectronics: Dispatch<SetStateAction<Electronics>>;

  formOfPayment: EFormOfPayment;
  setFormOfPayment: Dispatch<SetStateAction<EFormOfPayment>>;

  // PreinsuranceDamagesPage
  hasPreinsurance: boolean;
  setHasPreinsurance: Dispatch<SetStateAction<boolean>>;
  hadDamages: boolean;
  setHadDamages: Dispatch<SetStateAction<boolean>>;
  combinedShopContentsProduct: IPreinsuranceProduct;
  setCombinedShopContentsProduct: Dispatch<SetStateAction<IPreinsuranceProduct>>;
  glassProduct: IPreinsuranceProduct;
  setGlassProduct: Dispatch<SetStateAction<IPreinsuranceProduct>>;
  electronicFlatProduct: IPreinsuranceProduct;
  setElectronicFlatProduct: Dispatch<SetStateAction<IPreinsuranceProduct>>;
  damagesQuantity: number | null;
  setDamagesQuantity: Dispatch<SetStateAction<number | null>>;
  damagesOperationalExpenditure: number | null;
  setDamagesOperationalExpenditure: Dispatch<SetStateAction<number | null>>;
  damagesDescription: string;
  setDamagesDescription: Dispatch<SetStateAction<string>>;

  // ContactDataPage
  salutation: ESalutation | ESelectValues;
  setSalutation: Dispatch<SetStateAction<ESalutation | ESelectValues>>;
  companyOrInsurantName: string;
  setCompanyOrInsurantName: Dispatch<SetStateAction<string>>;
  nameAffixOrInsurantFirstName: string;
  setNameAffixOrInsurantFirstName: Dispatch<SetStateAction<string>>;
  insurantAddress: IFrontAddress;
  setInsurantAddress: Dispatch<SetStateAction<IFrontAddress>>;
  dateOfBirth: Date;
  setDateOfBirth: Dispatch<SetStateAction<Date>>;
  telephoneNumber: string;
  setTelephoneNumber: Dispatch<SetStateAction<string>>;
  eMail: string;
  setEMail: Dispatch<SetStateAction<string>>;
  confirmEMail: string;
  setConfirmEMail: Dispatch<SetStateAction<string>>;
  customerNo: string;
  setCustomerNo: Dispatch<SetStateAction<string>>;

  // PaymentDataPage
  directDebitMandate: DirectDebitMandate; // PaymentDataPage (Done)
  setDirectDebitMandate: Dispatch<SetStateAction<DirectDebitMandate>>;

  // SummaryPage (Done)
  advertisementPermission: boolean;
  setAdvertisementPermission: Dispatch<SetStateAction<boolean>>;
}

const BusinessDataContext = createContext<IBusinessDataContext>({
  // BusinessTypePage (Done)
  businessType: '',
  setBusinessType: () => { },

  address: {
    street: '',
    houseNumber: '',
    zipCode: '',
    city: '',
  },
  setAddress: () => { },

  isInclosedBuilding: false,
  setIsInclosedBuilding: () => { },
  hasSecurityMeasures: false,
  setHasSecurityMeasures: () => { },

  // ContributionPage (Done)
  insuranceSum: null,
  setInsuranceSum: () => { },
  deductible: EDeductible.None,
  setDeductible: () => { },

  risks: Risks.getDefaultRisks(),
  setRisks: () => { },

  // ExtraBenefitsPage
  lossOfRevenuePlus: LossOfRevenuePlus.getDefaultLossOfRevenuePlus(),
  setLossOfRevenuePlus: () => { },

  glass: false,
  /* eslint-disable */
  updateGlass: (newGlassState: boolean) => { },
  /* eslint-enable */

  electronics: Electronics.getDefaultElectronics(),
  setElectronics: () => { },

  formOfPayment: EFormOfPayment.Yearly,
  setFormOfPayment: () => { },

  // PreinsuranceDamagesPage (Done)
  hasPreinsurance: false,
  setHasPreinsurance: () => { },
  hadDamages: false,
  setHadDamages: () => { },
  combinedShopContentsProduct: PreinsuranceProductNullObject,
  setCombinedShopContentsProduct: () => { },
  glassProduct: PreinsuranceProductNullObject,
  setGlassProduct: () => { },
  electronicFlatProduct: PreinsuranceProductNullObject,
  setElectronicFlatProduct: () => { },
  damagesQuantity: null,
  setDamagesQuantity: () => { },
  damagesOperationalExpenditure: null,
  setDamagesOperationalExpenditure: () => { },
  damagesDescription: '',
  setDamagesDescription: () => { },

  // ContactDataPage (Done)
  salutation: ESelectValues.PleaseSelect,
  setSalutation: () => { },
  companyOrInsurantName: '',
  setCompanyOrInsurantName: () => { },
  nameAffixOrInsurantFirstName: '',
  setNameAffixOrInsurantFirstName: () => { },
  insurantAddress: {
    houseNumber: '',
    street: '',
    zipCode: '',
    city: '',
  },
  setInsurantAddress: () => { },
  dateOfBirth: new Date(),
  setDateOfBirth: () => { },
  telephoneNumber: '',
  setTelephoneNumber: () => { },
  eMail: '',
  setEMail: () => { },
  confirmEMail: '',
  setConfirmEMail: () => { },
  customerNo: '',
  setCustomerNo: () => { },

  // PaymentDataPage (Done)
  directDebitMandate: DirectDebitMandate.getDefaultMandate(),
  setDirectDebitMandate: () => { },

  // SummaryPage (Done)
  advertisementPermission: false,
  setAdvertisementPermission: () => { },
});

const { Provider } = BusinessDataContext;

interface IBusinessDataProvider {
  children: React.ReactNode;
}

const BusinessDataProvider = ({ children }: IBusinessDataProvider) => {
  const { businessTypeDescriptions } = useContext(BusinessTypesContext);

  // BusinessTypePage (Done)
  const [businessType, setBusinessType] = useState(''); // BT_800000
  const [formOfPayment, setFormOfPayment] = useState(EFormOfPayment.Yearly);
  const [address, setAddress] = useState<IFrontAddress>({
    street: '',
    houseNumber: '',
    zipCode: '',
    city: '',
  });

  const [isInclosedBuilding, setIsInclosedBuilding] = useState(false);
  const [hasSecurityMeasures, setHasSecurityMeasures] = useState(false);

  // ContributionPage (Done)
  const [insuranceSum, setInsuranceSum] = useState<number | null>(null);
  const [deductible, setDeductible] = useState(EDeductible.Fixed500);
  const [risks, setRisks] = useState(Risks.getDefaultRisks());

  // ExtraBenefitsPage
  const [lossOfRevenuePlus, setLossOfRevenuePlus] = useState(LossOfRevenuePlus.getDefaultLossOfRevenuePlus());
  const [glass, setGlass] = useState(false);
  const [electronics, setElectronics] = useState(Electronics.getDefaultElectronics());

  const updateGlass = (newGlassState: boolean) => {
    setGlass(newGlassState);
  };

  // electronics.insuranceSum depends on insuranceSum. if insuranceSum <= 50000, then the electronics.insuranceSum
  // has the same value as insuranceSum and it is not addible by user. see GPON-113
  useEffect(() => {
    setElectronics(electronics.updateFromGpInsuranceSum(insuranceSum));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insuranceSum]);

  // electronics.isForbidden depends on businessTypeDescription.
  // It is regulated by the flag 'electronicsAllowed'
  // a case with req level calc and false is:
  // BT_801853 Schankwirtschaft (nicht Imbiss, Bar, Diskothek, Vergnügungsbetrieb)
  // a case with req level calc and true is
  // BT_800000 Abbruchbetrieb
  useEffect(() => {
    if (!businessType || !businessTypeDescriptions || !businessTypeDescriptions.length) {
      return;
    }
    const businessTypeDescription = businessTypeDescriptions.find(
      (businessTypeDescriptionItem) => businessTypeDescriptionItem.businessTypeDescription === businessType,
    );
    if (!businessTypeDescription) {
      return;
    }
    setElectronics(electronics.setIsAdditionalCoverageAllowed(businessTypeDescription.electronicsAllowed));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessType]);

  // PreinsuranceDamagesPage (Done)
  const [hasPreinsurance, setHasPreinsurance] = useState(false);
  const [hadDamages, setHadDamages] = useState(false);
  const [combinedShopContentsProduct, setCombinedShopContentsProduct] = useState(PreinsuranceProductNullObject);
  const [glassProduct, setGlassProduct] = useState(PreinsuranceProductNullObject);
  const [electronicFlatProduct, setElectronicFlatProduct] = useState(PreinsuranceProductNullObject);
  const [damagesQuantity, setDamagesQuantity] = useState<number | null>(null);
  const [damagesOperationalExpenditure, setDamagesOperationalExpenditure] = useState<number | null>(null);
  const [damagesDescription, setDamagesDescription] = useState('');

  // Set hasPreinsurance to false if the user selected no preinsurance for all products
  useEffect(() => {
    const isEditing =
      combinedShopContentsProduct.isEditing || glassProduct.isEditing || electronicFlatProduct.isEditing;
    const noPreinsuaranceForAll =
      combinedShopContentsProduct.insuranceKey === ESelectValues.NoPreinsuarance &&
      (!glass || glassProduct.insuranceKey === ESelectValues.NoPreinsuarance) &&
      (!electronics.isSelected || electronicFlatProduct.insuranceKey === ESelectValues.NoPreinsuarance);

    if (hasPreinsurance && !isEditing && noPreinsuaranceForAll) {
      setHasPreinsurance(false);
      setCombinedShopContentsProduct(PreinsuranceProductNullObject);
      setGlassProduct(PreinsuranceProductNullObject);
      setElectronicFlatProduct(PreinsuranceProductNullObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [combinedShopContentsProduct.isEditing, glassProduct.isEditing, electronicFlatProduct.isEditing]);

  // ContactDataPage (Done)
  const [salutation, setSalutation] = useState<ESalutation | ESelectValues>(ESelectValues.PleaseSelect);
  const [companyOrInsurantName, setCompanyOrInsurantName] = useState('');
  const [nameAffixOrInsurantFirstName, setNameAffixOrInsurantFirstName] = useState('');
  const [insurantAddress, setInsurantAddress] = useState({
    street: '',
    zipCode: '',
    houseNumber: '',
    city: '',
  });
  const [dateOfBirth, setDateOfBirth] = useState<Date>(undefined as any);
  const [telephoneNumber, setTelephoneNumber] = useState('');
  const [eMail, setEMail] = useState('');
  const [confirmEMail, setConfirmEMail] = useState('');
  const [customerNo, setCustomerNo] = useState('');

  // PaymentDataPage (Done)
  const [directDebitMandate, setDirectDebitMandate] = useState(DirectDebitMandate.getDefaultMandate());

  // SummaryPage (Done)
  const [advertisementPermission, setAdvertisementPermission] = useState(false);

  return (
    <Provider
      value={{
        businessType, // BusinessTypePage (Done)
        setBusinessType,
        formOfPayment,
        setFormOfPayment,
        address,
        setAddress,
        insuranceSum, // ContributionPage (Done)
        setInsuranceSum,
        deductible,
        setDeductible,
        risks,
        setRisks,
        isInclosedBuilding, // ExtraBenefitsPage
        setIsInclosedBuilding,
        hasSecurityMeasures,
        setHasSecurityMeasures,
        lossOfRevenuePlus,
        setLossOfRevenuePlus,
        glass,
        updateGlass,
        electronics,
        setElectronics,
        hasPreinsurance, // PreinsuranceDamagesPage (Done)
        setHasPreinsurance,
        hadDamages,
        setHadDamages,
        combinedShopContentsProduct,
        setCombinedShopContentsProduct,
        glassProduct,
        setGlassProduct,
        electronicFlatProduct,
        setElectronicFlatProduct,
        damagesQuantity,
        setDamagesQuantity,
        damagesOperationalExpenditure,
        setDamagesOperationalExpenditure,
        damagesDescription,
        setDamagesDescription,
        salutation, // ContactDataPage (Done)
        setSalutation,
        companyOrInsurantName,
        setCompanyOrInsurantName,
        nameAffixOrInsurantFirstName,
        setNameAffixOrInsurantFirstName,
        insurantAddress,
        setInsurantAddress,
        dateOfBirth,
        setDateOfBirth,
        telephoneNumber,
        setTelephoneNumber,
        eMail,
        setEMail,
        confirmEMail,
        setConfirmEMail,
        customerNo,
        setCustomerNo,
        directDebitMandate, // PaymentDataPage (Done)
        setDirectDebitMandate,
        advertisementPermission, // SummaryPage (Done)
        setAdvertisementPermission,
      }}
    >
      {children}
    </Provider>
  );
};

export { BusinessDataContext, BusinessDataProvider, Provider };
