import { ECancellationStatus } from 'common/cancellation-status.enum';
import { EDeductible } from 'common/deductible.enum';
import { EFormOfPayment } from 'common/form-of-payment.enum';
import { ELossOfRevenue } from 'common/loss-of-revenue.enum';
import { ETransactionDate } from 'common/transaction-date.enum';

import { cancellationStatusMessages } from './cancellationStatusMessages';
import { deductibleMessages } from './deductibleMessages';
import { formOfPaymentMessages } from './formOfPaymentMessages';
import { lossOfRevenueMessages } from './lossOfRevenueMessages';
import { transactionDateMessages } from './transactionDateMessages';

function camelToKebabCase(str: string): string {
  return str.replace(/([a-z0-9])([A-Z]|\d+)/g, '$1-$2').toLowerCase();
}

function pascalToKebabCase(str: string): string {
  return camelToKebabCase(str);
}

function translateEnum(type: object, value: any, messages: { [x: string]: string }, prefix: string = ''): string {
  const entry = Object.entries(type).find(([, v]) => v === value);
  return entry ? messages[prefix + pascalToKebabCase(entry[0])] : '';
}

export function translateCancellationStatus(value: ECancellationStatus): string {
  return translateEnum(ECancellationStatus, value, cancellationStatusMessages);
}

export function translateDeductible(value: EDeductible): string {
  return translateEnum(EDeductible, value, deductibleMessages);
}

export function translateFormOfPayment(value: EFormOfPayment): string {
  return translateEnum(EFormOfPayment, value, formOfPaymentMessages, 'form-of-payment-name.full.');
}

export function translateLossOfRevenue(value: ELossOfRevenue): string {
  return translateEnum(ELossOfRevenue, value, lossOfRevenueMessages, 'loss-of-revenue-name.');
}

export function translateTransactionDate(value: ETransactionDate): string {
  return translateEnum(ETransactionDate, value, transactionDateMessages, 'transaction-date.');
}
