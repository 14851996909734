import { ScrollToTop } from 'client/components/atoms/ScrollToTop/ScrollToTop';
import { BusinessTypePage } from 'client/components/pages/BusinessTypePage/BusinessTypePage';
import { ContactDataPage } from 'client/components/pages/ContactDataPage/ContactDataPage';
import { ContributionPage } from 'client/components/pages/ContributionPage/ContributionPage';
import { ExtraBenefitsPage } from 'client/components/pages/ExtraBenefitsPage/ExtraBenefitsPage';
import { NotFoundPage } from 'client/components/pages/NotFoundPage/NotFoundPage';
import { OrderConfirmationPage } from 'client/components/pages/OrderConfirmationPage/OrderConfirmationPage';
import { PaymentDataPage } from 'client/components/pages/PaymentDataPage/PaymentDataPage';
import { PreinsuranceDamagesPage } from 'client/components/pages/PreinsuranceDamagesPage/PreinsuranceDamagesPage';
import { SummaryPage } from 'client/components/pages/SummaryPage/SummaryPage';
import { NavigationUrls } from 'client/helpers/NavigationUrls';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

export const AppRouter = () => (
  <ScrollToTop>
    <Switch>
      <Redirect from="/" to={`${NavigationUrls.businessTypePageUrl}${window.location.search}`} exact />
      <Route path={NavigationUrls.businessTypePageUrl} component={BusinessTypePage} />
      <Route path={NavigationUrls.preinsuranceDamagesPageUrl} component={PreinsuranceDamagesPage} />
      <Route path={NavigationUrls.contributionPageUrl} component={ContributionPage} exact />
      <Route path={NavigationUrls.extraBenefitsPageUrl} component={ExtraBenefitsPage} exact />
      <Route path={NavigationUrls.contactDataPageUrl} component={ContactDataPage} />
      <Route path={NavigationUrls.paymentDataPageUrl} component={PaymentDataPage} />
      <Route path={NavigationUrls.summaryPagePageUrl} component={SummaryPage} />
      <Route path={NavigationUrls.orderConfirmationPageUrl} component={OrderConfirmationPage} />
      <Route component={NotFoundPage} />
    </Switch>
  </ScrollToTop>
);
