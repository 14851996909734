import FormSection from '@eg/elements/FormSection';
import { DefaultIcon } from 'client/components/atoms/Icons/DefaultIcon';
import { ElectronicsIcon } from 'client/components/atoms/Icons/ElectronicsIcon';
import { GlassIcon } from 'client/components/atoms/Icons/GlassIcon';
import { CheckboxTile } from 'client/components/molecules/CheckboxTile/CheckboxTile';
import { ContributionBox } from 'client/components/molecules/ContributionsBox/ContributionBox';
import { ExtraElectronicsForm } from 'client/components/organisms/ExtraElectronicsForm/ExtraElectronicsForm';
import { LossOfRevenuePlusForm } from 'client/components/organisms/LossOfRevenuePlusForm/LossOfRevenuePlusForm';
import { PaymentMethodForm } from 'client/components/organisms/PaymentMethodForm/PaymentMethodForm';
import { AdvisoryTemplate } from 'client/components/templates/AdvisoryTemplate/AdvisoryTemplate';
import { isElectronicsInsuranceSumInvalid } from 'client/helpers/functions/isElectronicsInsuranceSumInvalid';
import { withI18n } from 'client/i18n/withI18n';
import { BusinessDataContext, PreinsuranceProductNullObject } from 'client/store/BusinessDataContext';
import { GlobalsContext } from 'client/store/GlobalsContext';
import { EInfoMessages } from 'common/EInfoMessages';
import React, { useContext, useEffect } from 'react';
import { FormattedHTMLMessage, InjectedIntlProps } from 'react-intl';

import { messages } from './messages';
import styles from './styles.module.scss';

const ExtraBenefitsPageComponent = ({ intl }: InjectedIntlProps) => {
  const {
    insuranceSum,
    risks,
    setRisks,
    lossOfRevenuePlus,
    setLossOfRevenuePlus,
    glass,
    updateGlass,
    electronics,
    setElectronics,
    setElectronicFlatProduct,
    setGlassProduct,
  } = useContext(BusinessDataContext);

  const { setIsErrorMessage, setIsValidationError } = useContext(GlobalsContext);

  useEffect(() => {
    if (!electronics.isSelected) {
      setIsValidationError(false);
    }
  }, []);

  // check isErrorMessage on load of the component
  useEffect(() => {
    setIsErrorMessage(isElectronicsInsuranceSumInvalid(electronics.insuranceSum));
  }, []);

  const setLossOfRevenuePlusHandler = () => {
    setLossOfRevenuePlus(lossOfRevenuePlus.toggleIsSelected());
  };

  const setGlassHandler = () => {
    // refresh product on deselect and select
    setGlassProduct(PreinsuranceProductNullObject);
    updateGlass(!glass);
  };

  const setElectronicsHandler = () => {
    // refresh product on deselect and select
    setElectronicFlatProduct(PreinsuranceProductNullObject);
    setElectronics(electronics.setSelected(!electronics.isSelected, insuranceSum));
  };

  return (
    <AdvisoryTemplate>
      <FormSection
        heading={intl.formatMessage({
          id: 'extra-benefits.page.extra-benefits.question',
        })}
        className={styles.formSectionSubheadline}
      />
      <FormSection
        heading={intl.formatMessage({
          id: 'extra-benefits-page.business-topic.title',
        })}
      >
        <div className="esc_grid">
          <div className="esc_grid__wrapper">
            <div className="esc_col esc_col-12 esc_col-m-6">
              <CheckboxTile
                icon={DefaultIcon}
                label={intl.formatMessage({
                  id: 'extra-benefits-page.business-topic.extra-items.item-1.label',
                })}
                value={lossOfRevenuePlus.isSelected}
                onChange={setLossOfRevenuePlusHandler}
                tooltip={<FormattedHTMLMessage id={EInfoMessages.I112} />}
              />
              {lossOfRevenuePlus.isSelected && <LossOfRevenuePlusForm />}

              <CheckboxTile
                icon={DefaultIcon}
                label={intl.formatMessage({
                  id: 'extra-benefits-page.business-topic.extra-items.item-service-plus.label',
                })}
                value={risks.assistance}
                className={styles.nextCheckboxTile}
                onChange={() => setRisks(risks.setAssistance(!risks.assistance))}
                tooltip={<FormattedHTMLMessage id={EInfoMessages.I113} />}
              />

              <CheckboxTile
                icon={GlassIcon}
                label={intl.formatMessage({
                  id: 'extra-benefits-page.business-topic.extra-items.item-2.label',
                })}
                className={styles.nextCheckboxTile}
                value={glass}
                onChange={setGlassHandler}
                tooltip={<FormattedHTMLMessage id={EInfoMessages.I114} />}
              />

              <CheckboxTile
                icon={ElectronicsIcon}
                label={intl.formatMessage({
                  id: 'extra-benefits-page.business-topic.extra-items.item-3.label',
                })}
                className={styles.nextCheckboxTile}
                value={electronics.isSelected}
                onChange={setElectronicsHandler}
                tooltip={<FormattedHTMLMessage id={EInfoMessages.I115} />}
              />

              {electronics.isSelected && <ExtraElectronicsForm />}
            </div>

            <div className={`esc_col esc_col-12 esc_col-m-6 ${styles.contributionBoxDesktop}`}>
              <ContributionBox className={styles.contributionBox} />
            </div>
          </div>
        </div>
      </FormSection>
      {!isElectronicsInsuranceSumInvalid(electronics.insuranceSum) && <PaymentMethodForm />}
      {!isElectronicsInsuranceSumInvalid(electronics.insuranceSum) && (
        <ContributionBox className={styles.contributionBoxMobile} />
      )}
    </AdvisoryTemplate>
  );
};

export const ExtraBenefitsPage = withI18n(ExtraBenefitsPageComponent, messages);
