import ControlWithHint from '@eg/elements/ControlWithHint';
import DatePickerRow from '@eg/elements/DatePickerRow';
import FormRow from '@eg/elements/FormRow';
import FormSection from '@eg/elements/FormSection';
import InputRow from '@eg/elements/InputRow';
import Select from '@eg/elements/Select';
import { beautifyIban } from '@eg/elements/utils/validation/iban';
import { AdvisoryTemplate } from 'client/components/templates/AdvisoryTemplate/AdvisoryTemplate';
import { ESelectValues } from 'client/helpers/ESelectValues';
import { addYears } from 'client/helpers/functions/addYears';
import { generateTomorrowDate } from 'client/helpers/functions/generateTomorrowDate';
import { isCompany } from 'client/helpers/functions/isCompany';
import { validateIban, validateInceptionDate, validateSelectBox } from 'client/helpers/functions/validations';
import { translateTransactionDate } from 'client/i18n/translateEnums';
import { withI18n } from 'client/i18n/withI18n';
import { BusinessDataContext } from 'client/store/BusinessDataContext';
import { GlobalsContext } from 'client/store/GlobalsContext';
import { EInfoMessages } from 'common/EInfoMessages';
import { ETransactionDate } from 'common/transaction-date.enum';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage, InjectedIntlProps } from 'react-intl';

import { messages } from './messages';
import styles from './styles.module.scss';

const PaymentDataPageComponent = ({ intl }: InjectedIntlProps) => {
  const {
    directDebitMandate,
    setDirectDebitMandate,
    salutation,
    companyOrInsurantName,
    nameAffixOrInsurantFirstName,
  } = useContext(BusinessDataContext);

  const { isValidationOn, setIsValidationError } = useContext(GlobalsContext);
  const [validationOnBlur, setValidationOnBlur] = useState<boolean>(false);

  // check for validation error
  useEffect(() => {
    setIsValidationError(
      !!validateInceptionDate(directDebitMandate.inceptionDate) ||
      !!validateIban(directDebitMandate.iban) ||
      !!validateSelectBox(directDebitMandate.transactionDate),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directDebitMandate]);

  return (
    <AdvisoryTemplate>
      <FormSection
        heading={intl.formatMessage({
          id: 'payment-data-page.payment-method.heading',
        })}
      >
        <DatePickerRow
          label={intl.formatMessage({
            id: 'payment-data-page.payment-begin.label',
          })}
          onChange={(e, date) => setDirectDebitMandate(directDebitMandate.setInceptionDate(date))}
          valueDate={directDebitMandate.inceptionDate}
          error={validateInceptionDate(directDebitMandate.inceptionDate, isValidationOn)}
          minDate={generateTomorrowDate()}
          maxDate={addYears(new Date(), 1)}
          tooltip={<FormattedHTMLMessage id={EInfoMessages.I121} />}
        />
      </FormSection>
      <FormSection
        heading={intl.formatMessage({
          id: 'payment-data-page.sepa.label',
        })}
      >
        <p>
          <FormattedMessage id="payment-data-page.authorization.text" />
        </p>
        <FormRow
          label={intl.formatMessage({
            id: 'payment-data-page.account-holder.label',
          })}
        >
          <span className={styles.accountHolderText}>
            {isCompany(salutation)
              ? `${companyOrInsurantName} ${nameAffixOrInsurantFirstName}`
              : `${nameAffixOrInsurantFirstName} ${companyOrInsurantName}`}
          </span>
        </FormRow>

        <InputRow
          label={intl.formatMessage({
            id: 'payment-data-page.iban.label',
          })}
          name="iban"
          value={beautifyIban(directDebitMandate.iban)}
          error={validateIban(directDebitMandate.iban, isValidationOn || validationOnBlur)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDirectDebitMandate(directDebitMandate.setIban(e.target.value))}
          onBlur={() => {
            setValidationOnBlur(true);
          }}
        />
        <FormRow
          label={intl.formatMessage({
            id: 'payment-data-page.transaction-date.label',
          })}
          colspans={[1, 2]}
        >
          <ControlWithHint error={validateSelectBox(directDebitMandate.transactionDate, isValidationOn)}>
            <Select
              data-testid="transactionDate"
              value={directDebitMandate.transactionDate}
              onChange={(e: any) => setDirectDebitMandate(directDebitMandate.setTransactionDate(e.target.value))}
            >
              <option value={ESelectValues.PleaseSelect} disabled>
                {intl.formatMessage({
                  id: 'payment-data-page.transaction-date.please-select.select',
                })}
              </option>
              <option value={ETransactionDate.First}>{translateTransactionDate(ETransactionDate.First)}</option>
              <option value={ETransactionDate.Eight}>{translateTransactionDate(ETransactionDate.Eight)}</option>
              <option value={ETransactionDate.Sixteenth}>{translateTransactionDate(ETransactionDate.Sixteenth)}</option>
              <option value={ETransactionDate.TwentyFourth}>
                {translateTransactionDate(ETransactionDate.TwentyFourth)}
              </option>
            </Select>
          </ControlWithHint>
        </FormRow>
        <FormRow
          label={intl.formatMessage({
            id: 'payment-data-page.hint.label',
          })}
        >
          <FormattedMessage id="payment-data-page.hint.text" />
        </FormRow>
      </FormSection>
    </AdvisoryTemplate>
  );
};

export const PaymentDataPage = withI18n(PaymentDataPageComponent, messages);
