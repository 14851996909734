/* eslint-disable max-len */
import { ErgoElementsContext } from '@eg/elements/Provider';
import SvgIcon from '@eg/elements/SvgIcon';
import React, { useContext } from 'react';

interface IOperatingCostsProps {
  width?: number;
  height?: number;
}

export const OperatingCostsIcon = ({ width, height }: IOperatingCostsProps) => {
  const { idGenerator } = useContext(ErgoElementsContext);

  return (
    <SvgIcon idGenerator={idGenerator} title="fire" viewBox="0 0 52 52" width={width} height={height}>
      <path d="m6.4 2.2h39c2.2 0 4 1.8 4 4v39c0 2.2-1.8 4-4 4h-39c-2.2 0-4-1.8-4-4v-39c0-2.2 1.8-4 4-4z" fill="none" />
      <path d="m38.1 37.3c0-4.7 0-9.5 0-14.2 0-.3 0-.6 0-.9-.1-.4-.2-.8-.3-1.2-.1-.3-.2-.5-.4-.7s-.3-.4-.4-.6c-.1-.1-.3-.3-.4-.4-2-2-3.9-3.9-5.9-5.9-.3-.3-.7-.5-1-.8-.5-.3-1-.4-1.5-.6-.3-.1-.6-.1-.9-.1-3.6 0-7.2 0-10.8 0-.3 0-.6 0-.9.1-.5.1-.8.4-1.1.8-.2.2-.3.5-.3.7v7.2 16.3c0 .5 0 1 .1 1.5.3.7 1 1.2 1.7 1.3h20.1c.5 0 1-.2 1.4-.6.2-.3.5-.6.5-.9.1-.3.1-.6.1-1zm-20-16.4h.5 4.7.7v2c-2 0-4 0-6 0 .1-.7.1-1.4.1-2zm3.2 15c-.7.1-1.4-.1-1.9-.5-.4-.3-.7-.8-.8-1.2-.1 0-.3 0-.4 0-.1-.1 0-.2-.1-.3 0-.1 0-.3.1-.3h.3c0-.1 0-.2 0-.4-.1 0-.2 0-.3-.1 0-.2 0-.4 0-.6h.4c.1-.2.2-.5.3-.7.4-.6 1.1-1 1.9-1 .3 0 .6 0 .8.1.1 0 .1.1.1.1 0 .2-.1.4-.1.6 0 .1 0 .1-.1.1-.2 0-.4-.1-.7-.1-.5 0-1.1.4-1.3.9h1.3.5c.1 0 .1.1.1.1 0 .1 0 .3-.1.4 0 .1 0 .1-.1.1-.6 0-1.2 0-1.8 0v.4h1.6c.1 0 .1 0 .2.1 0 .2-.1.4-.1.6-.5 0-1 0-1.5 0 .1.4.6.8 1 .9s.7 0 1-.1c.1.2.1.5.2.8-.1 0-.3.1-.5.1zm6.1 0c-.7.1-1.4-.1-1.9-.5-.4-.3-.7-.8-.8-1.2-.1 0-.3 0-.4 0-.1-.1 0-.2-.1-.3 0-.1 0-.3.1-.3h.3c0-.1 0-.2 0-.4-.1 0-.2 0-.3-.1 0-.2 0-.4 0-.6h.4c.1-.2.2-.4.3-.6.4-.6 1.1-1 1.9-1.1.3 0 .6 0 .8.1.1 0 .1.1.1.1 0 .2-.1.4-.1.6 0 .1 0 .1-.1.1-.2 0-.4-.1-.7-.1-.5 0-1.1.4-1.3.9h1.3.5c.1 0 .1.1.1.1 0 .1 0 .3-.1.4 0 .1 0 .1-.1.1-.6 0-1.2 0-1.8 0v.4h1.6c.1 0 .1 0 .2.1 0 .2 0 .4-.1.6-.5.1-1 0-1.5 0 .1.4.6.8 1 .9s.7 0 1-.1c.1.2.1.5.2.8-.1 0-.3.1-.5.1zm.7-10.2v1.1c-.1 0-.1 0-.2 0-3.1 0-6.1 0-9.2 0-.2 0-.4 0-.6 0 0-.6 0-1.3 0-1.9 0-.1.1-.1.2-.1h9.2.6zm5.5 10.2c-.7.1-1.4-.1-1.9-.5-.4-.3-.7-.8-.9-1.3-.1 0-.3 0-.4 0-.1-.1 0-.2-.1-.3 0-.1 0-.3.1-.3h.3c0-.1 0-.2 0-.4-.1 0-.2 0-.3-.1 0-.2 0-.4 0-.6h.4l.3-.6c.4-.6 1.1-1.1 1.9-1.1.3 0 .6 0 .9.1.1 0 .1.1.1.1 0 .2-.1.4-.1.6 0 .1 0 .1-.1.1-.2 0-.4-.1-.7-.1-.5 0-1.1.4-1.3.9h1.6c.1 0 .2 0 .2.1 0 .2 0 .3-.1.5 0 .1 0 .1-.1.1-.6 0-1.2 0-1.8 0v.4h1.6c.1 0 .1 0 .2.1 0 .2-.1.4-.1.6-.5 0-1 0-1.5 0 .1.4.6.8 1 .9s.7 0 1-.1c.1.2.1.5.2.8 0 0-.2.1-.4.1zm-3.5-15c-.4 0-.7-.3-.9-.6-.1-.2-.1-.5-.1-.7 0-1.3 0-2.7 0-4 0-.2 0-.4 0-.7.1.1.3.2.4.3 1.9 1.9 3.7 3.7 5.6 5.6 0 0 .1.1.1.2-1.7-.1-3.4-.2-5.1-.1z" />
    </SvgIcon>
  );
};
