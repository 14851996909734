/* eslint-disable max-len */
import { ErgoElementsContext } from '@eg/elements/Provider';
import SvgIcon from '@eg/elements/SvgIcon';
import React, { useContext } from 'react';

interface IFloodingProps {
  width?: number;
  height?: number;
}

export const FloodingIcon = ({ width, height }: IFloodingProps) => {
  const { idGenerator } = useContext(ErgoElementsContext);

  return (
    <SvgIcon idGenerator={idGenerator} title="fire" viewBox="0 0 52 52" width={width} height={height}>
      <rect fill="none" height="47" rx="4" width="47" x="2.5" y="2.5" />
      <g>
        <path d="m40.5 42a5 5 0 0 1 -3.19-1.09 4.21 4.21 0 0 0 -5.23 0 5.2 5.2 0 0 1 -6.37 0 4.21 4.21 0 0 0 -5.23 0 5.2 5.2 0 0 1 -6.37 0 4 4 0 0 0 -2.61-.91.5.5 0 0 1 0-1 5 5 0 0 1 3.18 1.09 4.21 4.21 0 0 0 5.23 0 5.18 5.18 0 0 1 6.36 0 4.23 4.23 0 0 0 5.24 0 5.2 5.2 0 0 1 6.37 0 4 4 0 0 0 2.62.91.5.5 0 0 1 0 1zm0-3a5 5 0 0 1 -3.19-1.09 4.21 4.21 0 0 0 -5.23 0 5.2 5.2 0 0 1 -6.37 0 4.21 4.21 0 0 0 -5.23 0 5.2 5.2 0 0 1 -6.37 0 4 4 0 0 0 -2.61-.91.5.5 0 0 1 0-1 5 5 0 0 1 3.18 1.09 4.21 4.21 0 0 0 5.23 0 5.18 5.18 0 0 1 6.36 0 4.23 4.23 0 0 0 5.24 0 5.2 5.2 0 0 1 6.37 0 4 4 0 0 0 2.62.91.5.5 0 0 1 0 1" />
        <path d="m21.29 33.54h9.42v-9.42h-9.42zm-8.94-9.42h3.29v12.41a2.77 2.77 0 0 0 1.66.47 3 3 0 0 0 2-.73 6.17 6.17 0 0 1 7.5 0 3.24 3.24 0 0 0 4.1 0 6 6 0 0 1 3.79-1.27 6.2 6.2 0 0 1 1.67.22v-11.1h3.29a.48.48 0 0 0 .44-.28.5.5 0 0 0 -.1-.52l-3.63-3.66v-9.19a.47.47 0 0 0 -.47-.47h-4.71a.47.47 0 0 0 -.47.47v3.53l-3.37-3.4a2 2 0 0 0 -2.69 0l-12.65 12.72a.5.5 0 0 0 -.1.52.48.48 0 0 0 .44.28z" />
      </g>
    </SvgIcon>
  );
};
