import { IBusinessTypeDescription } from './business-type-description.interface';
import { EBusinessTypeRequestLevel } from './business-type-request-level.enum';

export class BusinessTypeDescription implements IBusinessTypeDescription {
  /* eslint-disable no-useless-constructor, no-empty-function */
  constructor(
    public businessTypeId: string,
    public requestLevel: EBusinessTypeRequestLevel,
    public businessTypeDescription: string,
    public businessContentAllowed: boolean,
    public protectionClass: string,
    public firmClosureAllowed: boolean,
    public operatingCostsAllowed: boolean,
    public electronicsAllowed: boolean,
    public machineAllowed: boolean,
    public recommendedPackage: string,
  ) { }

  public static fromPayload(payload: IBusinessTypeDescription) {
    return new BusinessTypeDescription(
      payload.businessTypeId,
      payload.requestLevel,
      payload.businessTypeDescription,
      payload.businessContentAllowed,
      payload.protectionClass,
      payload.firmClosureAllowed,
      payload.operatingCostsAllowed,
      payload.electronicsAllowed,
      payload.machineAllowed,
      payload.recommendedPackage,
    );
  }
}
