import { EPlausiNr } from 'client/components/molecules/ErrorMessage/ErrorMessage';
import React, {
  createContext, Dispatch, SetStateAction, useState,
} from 'react';

export interface IGlobalsContext {
  isErrorMessage: boolean;
  setIsErrorMessage: Dispatch<SetStateAction<boolean>>;
  plausiNr: EPlausiNr;
  setPlausiNr: Dispatch<SetStateAction<EPlausiNr>>;
  isValidationOn: boolean;
  setIsValidationOn: Dispatch<SetStateAction<boolean>>;
  isValidationError: boolean;
  setIsValidationError: Dispatch<SetStateAction<boolean>>;
  isFirstPageLoad: boolean;
  setIsFirstPageLoad: Dispatch<SetStateAction<boolean>>;
  visitedSteps: Map<string, boolean>;
  setVisitedSteps: Dispatch<SetStateAction<Map<string, boolean>>>;
  isConsultModelOpen: boolean;
  setIsConsultModelOpen: Dispatch<SetStateAction<boolean>>;
  isServerErrorModelOpen: boolean;
  setIsServerErrorModelOpen: Dispatch<SetStateAction<boolean>>;
}

const GlobalsContext = createContext<IGlobalsContext>({
  isErrorMessage: false,
  setIsErrorMessage: () => {},
  plausiNr: EPlausiNr.P001,
  setPlausiNr: () => {},
  isValidationOn: false,
  setIsValidationOn: () => {},
  isValidationError: false,
  setIsValidationError: () => {},
  isFirstPageLoad: true,
  setIsFirstPageLoad: () => {},
  visitedSteps: new Map<string, boolean>(),
  setVisitedSteps: () => {},
  isConsultModelOpen: false,
  setIsConsultModelOpen: () => {},
  isServerErrorModelOpen: false,
  setIsServerErrorModelOpen: () => {},
});
const { Provider } = GlobalsContext;

interface IGlobalsProvider {
  children: React.ReactNode;
}

const GlobalsProvider = ({ children }: IGlobalsProvider) => {
  const [isErrorMessage, setIsErrorMessage] = useState(false);
  const [plausiNr, setPlausiNr] = useState(EPlausiNr.P001);
  const [isValidationOn, setIsValidationOn] = useState(false);
  const [isValidationError, setIsValidationError] = useState(false);
  const [isFirstPageLoad, setIsFirstPageLoad] = useState(false);
  const [visitedSteps, setVisitedSteps] = useState(new Map<string, boolean>());
  const [isConsultModelOpen, setIsConsultModelOpen] = useState(false);
  const [isServerErrorModelOpen, setIsServerErrorModelOpen] = useState(false);

  return (
    <Provider
      value={{
        isErrorMessage,
        setIsErrorMessage,
        plausiNr,
        setPlausiNr,
        isValidationOn,
        setIsValidationOn,
        isValidationError,
        setIsValidationError,
        isFirstPageLoad,
        setIsFirstPageLoad,
        visitedSteps,
        setVisitedSteps,
        isConsultModelOpen,
        setIsConsultModelOpen,
        isServerErrorModelOpen,
        setIsServerErrorModelOpen,
      }}
    >
      {children}
    </Provider>
  );
};

export { GlobalsContext, GlobalsProvider, Provider };
