import Autocomplete from '@eg/elements/Autocomplete';
import ControlWithHint from '@eg/elements/ControlWithHint';
import FormRow from '@eg/elements/FormRow';
import FormSection from '@eg/elements/FormSection';
import Input from '@eg/elements/Input';
import YesNoInput from '@eg/elements/YesNoInput';
import { EPlausiNr, ErrorMessage } from 'client/components/molecules/ErrorMessage/ErrorMessage';
import { RiskAddressRequestStatus } from 'client/components/molecules/RiskAddressRequestStatus/RiskAddressRequestStatus';
import { ServerErrorModal } from 'client/components/molecules/ServerErrorModal/ServerErrorModal';
import { AdvisoryTemplate } from 'client/components/templates/AdvisoryTemplate/AdvisoryTemplate';
import { isNumber } from 'client/helpers/functions/isNumber';
import {
  validateNoSpaces,
  validateRequiredField,
  validateRequiredStringLength,
  validateRequiredYesNoInput,
  validateZipCode,
} from 'client/helpers/functions/validations';
import { validateNoNumberOnEnd } from 'client/helpers/functions/validations/validateNoNumberOnEnd';
import { withI18n } from 'client/i18n/withI18n';
import { BusinessDataContext } from 'client/store/BusinessDataContext';
import { BusinessTypesContext } from 'client/store/BusinessTypesContext';
import { GlobalsContext } from 'client/store/GlobalsContext';
import { RiskAddressContext } from 'client/store/RiskAddressContext';
import { EBusinessTypeRequestLevel } from 'common/business-type-request-level.enum';
import { EInfoMessages } from 'common/EInfoMessages';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage, InjectedIntlProps } from 'react-intl';

import { messages } from './messages';

const BusinessTypePageComponent = (props: any) => {
  const { intl }: InjectedIntlProps = props;
  const { allBusinessTypes, businessTypeDescriptions, error: businessTypeErrorId } = useContext(BusinessTypesContext);

  const {
    address,
    setAddress,
    businessType,
    setBusinessType,
    isInclosedBuilding,
    setIsInclosedBuilding,
    hasSecurityMeasures,
    setHasSecurityMeasures,
  } = useContext(BusinessDataContext);

  const { isLoading: isRiskAddressLoading, error: riskAddressError, checkRiskAddress } = useContext(RiskAddressContext);

  const {
    setIsErrorMessage,
    plausiNr,
    setPlausiNr,
    isValidationOn,
    setIsValidationError,
    setIsServerErrorModelOpen,
  } = useContext(GlobalsContext);

  const [isNotSupportedBusinessType, setIsNotSupportedBusinessType] = useState(false);

  const [currentBusinessTypesSuggestions, setCurrentBusinessTypesSuggestions] = useState<string[]>([]);

  const [hasAddressChanged, setHasAddressChanged] = useState(false);

  useEffect(() => {
    setIsServerErrorModelOpen(!!businessTypeErrorId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessTypeErrorId]);

  // check for validation error
  useEffect(() => {
    setIsValidationError(
      !!validateRequiredField(businessType) ||
      !!validateRequiredStringLength(30, address.street) ||
      !!validateNoSpaces(address.houseNumber) ||
      !!validateNoNumberOnEnd(address.street) ||
      !!validateRequiredStringLength(10, address.houseNumber) ||
      !!validateZipCode(address.zipCode) ||
      !!validateRequiredStringLength(24, address.city) ||
      !!validateRequiredYesNoInput(isInclosedBuilding) ||
      !!validateRequiredYesNoInput(hasSecurityMeasures),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessType, address, isInclosedBuilding, hasSecurityMeasures]);

  // check isErrorMessage on load of the component
  useEffect(() => {
    setIsErrorMessage(false);
  }, []);

  const triggerCheckRiskAddress = () => {
    if (
      address.city !== '' &&
      address.street !== '' &&
      address.houseNumber !== '' &&
      address.zipCode !== '' &&
      hasAddressChanged
    ) {
      checkRiskAddress(address);
      setHasAddressChanged(false);
    }
  };

  const setBusinessTypeHandler = (businessTypeValue: string) => {
    setBusinessType(businessTypeValue);
    setCurrentBusinessTypesSuggestions(
      allBusinessTypes.filter((businessTypeSuggestion) => businessTypeSuggestion.toLowerCase().includes(businessTypeValue.toLowerCase())),
    );

    // In Firefox the focus of the element is loosing after selecting the value from the list,
    // therefore the onBlur does not happen after selecting the value.
    // With this fix keep focus on element at every change of business type value.
    // TODO: make sure, that with the update of ERGO-Elements this problem is gone.
    document
      .getElementsByClassName('gp-autocomplete-firefox-fix')[0]
      .getElementsByTagName('input')[0]
      .focus();
  };

  const checkBusinessTypeHandler = () => {
    // eslint-disable-next-line max-len
    const activeBusinessType = businessTypeDescriptions.find((businessTypeDescription) => businessTypeDescription.businessTypeDescription === businessType);

    if (activeBusinessType) {
      switch (activeBusinessType.requestLevel) {
        case EBusinessTypeRequestLevel.CALC:
          setIsNotSupportedBusinessType(false);
          setIsErrorMessage(!isInclosedBuilding);
          break;
        case EBusinessTypeRequestLevel.REQ_WITH_CALL:
          setIsNotSupportedBusinessType(true);
          setIsErrorMessage(true);
          setPlausiNr(EPlausiNr.P001);
          break;
        case EBusinessTypeRequestLevel.REQ_WITHOUT_CALL:
          setIsNotSupportedBusinessType(true);
          setIsErrorMessage(true);
          setPlausiNr(EPlausiNr.P002);
          break;
        default:
          break;
      }
    } else if (businessType === '') {
      setIsNotSupportedBusinessType(false);
      setPlausiNr(EPlausiNr.P001);
      setIsErrorMessage(false);
    } else {
      setIsNotSupportedBusinessType(true);
      setIsErrorMessage(true);
      setPlausiNr(EPlausiNr.P003);
    }
  };

  const showInclosedBuildingErrorMessage = () => isInclosedBuilding !== undefined && businessType !== '' && !isInclosedBuilding;

  const showSecurityMeasuresErrorMessage = () => hasSecurityMeasures !== undefined && businessType !== '' && !hasSecurityMeasures;

  return (
    <AdvisoryTemplate>
      <FormSection
        heading={intl.formatMessage({
          id: 'business-type-page.business-details.heading',
        })}
      >
        <FormRow
          label={intl.formatMessage({
            id: 'business-type-page.business-details.business-type.label',
          })}
          tooltip={<FormattedHTMLMessage id={EInfoMessages.I100} />}
        >
          <ControlWithHint error={validateRequiredField(businessType, isValidationOn)}>
            <Autocomplete
              className="gp-autocomplete-firefox-fix"
              value={businessType}
              onChange={setBusinessTypeHandler}
              onBlur={checkBusinessTypeHandler}
              onFocus={() => setIsNotSupportedBusinessType(false)}
              suggestions={currentBusinessTypesSuggestions}
              error={validateRequiredField(businessType, isValidationOn)}
              placeholder={intl.formatMessage({
                id: 'business-type-page.business-details.business-type.placeholder',
              })}
            />
          </ControlWithHint>
        </FormRow>

        {isNotSupportedBusinessType && (
          <ErrorMessage plausiNr={plausiNr} isContactHidden={plausiNr === EPlausiNr.P002} />
        )}

        <div className={isNotSupportedBusinessType ? 'u-hidden' : ''}>
          <FormRow
            label={intl.formatMessage({
              id: 'business-type-page.business-details.location.label',
            })}
            colspans={[2, 1]}
            tooltip={<FormattedHTMLMessage id={EInfoMessages.I101} />}
          >
            <ControlWithHint
              error={
                validateRequiredStringLength(30, address.street, isValidationOn) ||
                validateNoNumberOnEnd(address.street)
              }
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'business-type-page.business-details.location.street-name.placeholder',
                })}
                name="streetName"
                value={address.street}
                onBlur={triggerCheckRiskAddress}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setAddress({ ...address, ...{ street: e.target.value } });
                  setHasAddressChanged(true);
                }}
              />
            </ControlWithHint>
            <ControlWithHint
              error={
                validateNoSpaces(address.houseNumber, isValidationOn) ||
                validateRequiredStringLength(10, address.houseNumber, isValidationOn)
              }
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'business-type-page.business-details.location.house-number.placeholder',
                })}
                name="houseNumber"
                value={address.houseNumber}
                onBlur={triggerCheckRiskAddress}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setAddress({ ...address, ...{ houseNumber: e.target.value } });
                  setHasAddressChanged(true);
                }}
              />
            </ControlWithHint>
          </FormRow>
          <FormRow label="" colspans={[1, 2]}>
            <ControlWithHint error={validateZipCode(address.zipCode, isValidationOn)}>
              <Input
                placeholder={intl.formatMessage({
                  id: 'business-type-page.business-details.location.zip.placeholder',
                })}
                name="zip"
                pattern="[0-9]*"
                maxLength="5"
                minLength="5"
                value={address.zipCode}
                onBlur={triggerCheckRiskAddress}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (isNumber(e.target.value) || e.target.value === '') {
                    setAddress({ ...address, ...{ zipCode: e.target.value } });
                    setHasAddressChanged(true);
                  }
                }}
              />
            </ControlWithHint>
            <ControlWithHint error={validateRequiredStringLength(24, address.city, isValidationOn)}>
              <Input
                placeholder={intl.formatMessage({
                  id: 'business-type-page.business-details.location.place.placeholder',
                })}
                name="place"
                value={address.city}
                onBlur={triggerCheckRiskAddress}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setAddress({ ...address, ...{ city: e.target.value } });
                  setHasAddressChanged(true);
                }}
              />
            </ControlWithHint>
          </FormRow>
          <FormRow label="">
            <FormattedMessage id="business-type-page.business-details.location.country.value" />
          </FormRow>
          <RiskAddressRequestStatus isRiskAddressLoading={isRiskAddressLoading} riskAddressError={riskAddressError} />
        </div>
      </FormSection>
      <FormSection
        className={isNotSupportedBusinessType ? 'u-hidden' : ''}
        heading={intl.formatMessage({
          id: 'business-type-page.building-details.heading',
        })}
      >
        <FormRow
          boundary="narrow"
          tooltip={<FormattedHTMLMessage id={EInfoMessages.I102} />}
          label={intl.formatMessage({
            id: 'business-type-page.building-details.is-inclosed-building.question',
          })}
        >
          <YesNoInput
            name="is-inclosed-building"
            onChange={(newIsInclosedBuilding) => {
              setIsInclosedBuilding(newIsInclosedBuilding);
              setIsErrorMessage(!newIsInclosedBuilding || !hasSecurityMeasures);
            }}
            value={isInclosedBuilding}
            error={validateRequiredYesNoInput(isInclosedBuilding, isValidationOn)}
          />
        </FormRow>
        {showInclosedBuildingErrorMessage() && <ErrorMessage plausiNr={EPlausiNr.P007} />}
        <div className={showInclosedBuildingErrorMessage() ? 'u-hidden' : ''}>
          <p>
            <FormattedMessage id="business-type-page.building-details.has-security-measures.info" />
          </p>
          <FormRow
            boundary="narrow"
            tooltip={<FormattedHTMLMessage id={EInfoMessages.I103} />}
            label={intl.formatMessage({ id: 'business-type-page.building-details.has-security-measures.question' })}
          >
            <YesNoInput
              name="has-security-measures"
              onChange={(newHasSecurityMeasures) => {
                setHasSecurityMeasures(newHasSecurityMeasures);
                setIsErrorMessage(!newHasSecurityMeasures || !isInclosedBuilding);
              }}
              value={hasSecurityMeasures}
              error={validateRequiredYesNoInput(hasSecurityMeasures, isValidationOn)}
            />
          </FormRow>
          {showSecurityMeasuresErrorMessage() && <ErrorMessage plausiNr={EPlausiNr.P004} />}
        </div>
      </FormSection>
      <ServerErrorModal
        message={intl.formatMessage({ id: 'business-type-page.business-details.business-type.server-error' })}
        action={() => {
          window.location.href = '/';
        }}
      />
    </AdvisoryTemplate>
  );
};

export const BusinessTypePage = withI18n(BusinessTypePageComponent, messages);
