import PhoneIcon from '@eg/elements/Icons/PhoneIcon';
import { withI18n } from 'client/i18n/withI18n';
import { IAgency } from 'client/model/IAgency';
import { AgencyContext } from 'client/store/AgencyContext';
import { BusinessDataContext } from 'client/store/BusinessDataContext';
import React, { useContext } from 'react';
import { FormattedMessage, InjectedIntl } from 'react-intl';

import { messages } from './messages';
import styles from './styles.module.scss';

export enum EPlausiNr {
  P001 = 'p001',
  P002 = 'p002',
  P003 = 'p003',
  P004 = 'p004',
  P005 = 'p005',
  P006 = 'p006',
  P007 = 'p007',
  P008 = 'p008',
  P009 = 'p009',
  CombinedP008P009 = 'comb008009',
}

interface IErrorMessageProps {
  children?: React.ReactNode;
  isContactHidden?: boolean;
  plausiNr?: EPlausiNr;
  isWarning?: boolean;
  intl: InjectedIntl;
}

function isAgentContactInfoAvailable(agency: IAgency | null): agency is IAgency {
  return !!agency && !!agency.email && !!agency.phone;
}

const ErrorMessageComponent = ({
  children, isContactHidden, plausiNr, isWarning, intl,
}: IErrorMessageProps) => {
  const { agency } = useContext(AgencyContext);
  const plausiMessageKey = `plausi.${plausiNr}.message`;
  const plausiContactMessageKey = `plausi.${plausiNr}.contact.text`;
  const { businessType } = useContext(BusinessDataContext);

  const getPlausiText = () => plausiNr && intl.formatMessage({ id: plausiMessageKey }, { businessType: businessType || '' });

  const boxClass = isWarning ? styles.boxWarning : styles.boxError;
  const messageClass = isWarning ? styles.messageWarning : styles.messageError;
  const telClass = isWarning ? styles.telWarning : styles.telError;

  const contactTextId = messages[plausiContactMessageKey] !== undefined ? plausiContactMessageKey : 'contact.text';
  const contactPhoneNumber = plausiNr === EPlausiNr.P002 ? 'contact.phone-number.p002' : 'contact.phone-number';

  const phone = isAgentContactInfoAvailable(agency) ? agency.phone : intl.formatMessage({ id: contactPhoneNumber });
  const mail = isAgentContactInfoAvailable(agency) ? agency.email : intl.formatMessage({ id: 'contact.mail.title' });
  const mailHref = isAgentContactInfoAvailable(agency) ? agency.email : messages['contact.mail.link'];

  return (
    <div className={boxClass} data-testid={plausiNr}>
      <div className={messageClass}>{plausiNr ? getPlausiText() : children}</div>
      {!isContactHidden && (
        <>
          <p>
            <FormattedMessage id={contactTextId} />
          </p>
          <p className={styles.phoneWrapper}>
            <span className={styles.iconWrapper}>
              <PhoneIcon className={styles.icon} width={30} height={30} />
            </span>
            <span className={telClass}>{phone}</span>
          </p>
          <span>
            <FormattedMessage id="contact.mail.text" />
            &nbsp;
            <a href={`mailto:${mailHref}`}>{mail}</a>
          </span>
        </>
      )}
    </div>
  );
};

export const ErrorMessage = withI18n(ErrorMessageComponent, messages);
