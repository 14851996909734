import { IRiskAddressState } from './IRiskAddressState';

// eslint-disable-next-line import/prefer-default-export
export class RiskAddressState implements IRiskAddressState {
  // eslint-disable-next-line no-useless-constructor, no-empty-function
  public constructor(public zuersClass: number, public hazardZone: number, public isValid: boolean) {}

  public canCalcByZuersClass(): boolean {
    // only zuers-class 1 and 2 are insurable by gp online
    return this.isValid && this.zuersClass >= 1 && this.zuersClass <= 2;
  }

  public canCalcByHazardZone(): boolean {
    // only hazardzone 1 and 2 are insurable by gp online
    return this.isValid && this.hazardZone < 3;
  }

  public canCalc(): boolean {
    return this.canCalcByHazardZone() && this.canCalcByZuersClass();
  }

  public showPlausi008() {
    return this.isValid && this.zuersClass !== 1 && this.zuersClass !== 2;
  }

  public showPlausi009() {
    return this.isValid && this.hazardZone !== 1 && this.hazardZone !== 2;
  }

  public static getDefaultRiskAddressState(): RiskAddressState {
    return new RiskAddressState(-1, -1, false);
  }
}
