/* eslint-disable max-len */
import { ErgoElementsContext } from '@eg/elements/Provider';
import SvgIcon from '@eg/elements/SvgIcon';
import React, { useContext } from 'react';

interface IUnnamedIconProps {
  width?: number;
  height?: number;
}

export const UnnamedIcon = ({ width, height }: IUnnamedIconProps) => {
  const { idGenerator } = useContext(ErgoElementsContext);

  return (
    <SvgIcon idGenerator={idGenerator} title="fire" viewBox="0 0 52 52" width={width} height={height}>
      <path d="m6.4 2.2h39c2.2 0 4 1.8 4 4v39c0 2.2-1.8 4-4 4h-39c-2.2 0-4-1.8-4-4v-39c0-2.2 1.8-4 4-4z" fill="none" />
      <path
        d="m28 2.101v-1.434a.667.667 0 0 0 -.667-.667h-5.333a.667.667 0 0 0 -.667.667v3.723l-3.723-3.723c-.858-.859-2.357-.86-3.218 0l-2.097 2.096.056.057 2.905 6.988-1.082 5.008 2.542 4.648-.793 4.595.793 4.415v2.546l-.793-2.546-1.75-4.415v-4.595l-3.64-3.452.53-6.204-3.076-2.7-.02-.017-7.77 7.77a.666.666 0 0 0 .471 1.138h3.332v15.333c0 .368.3.667.667.667h22.666a.667.667 0 0 0 .667-.667v-6.844l-1.643.548-2.107-.647-2.25.647 1.89-2.06 2.108.303 2.002-.982v-6.297h3.333a.667.667 0 0 0 .472-1.138l-3.805-3.805v-7.578l-1.79 1.031-.025 1.59-.948 1.254-1.436 1.29 1.427-1.992.514-2.392 2.258-2.16z"
        fillRule="evenodd"
        transform="matrix(.8 0 0 .8 13 13)"
      />
    </SvgIcon>
  );
};
