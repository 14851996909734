export const consultModelMessages: { [key: string]: string } = {
  /* eslint-disable max-len */
  'consult-modal.header': 'Wünschen Sie eine Beratung?',
  'consult-modal.p1':
    'Seit Februar 2018 gilt die reformierte EU-Richtlinie für den Versicherungsvertrieb. Seitdem muss auch im Direktvertrieb eine Beratung und deren Dokumentation erfolgen, bevor ein Vertrag abgeschlossen wird.',
  'consult-modal.p2':
    'Möchten Sie weiterhin direkt abschließen und auf die Beratung und deren Dokumentation verzichten? Dann erklären Sie bitte später bei Online-Vertragsabschluss Ihren Beratungsverzicht und laden die Beratungsverzichtserklärung herunter.',
  'consult-modal.i-wich.btn': 'Ich wünsche eine Beratung',
  'consult-modal.next.btn': 'Weiter ohne Beratung',
};
