/* eslint-disable import/prefer-default-export */
export enum EInfoMessages {
  I100 = 'i-100',
  I101 = 'i-101',
  I102 = 'i-102',
  I103 = 'i-103',
  I104 = 'i-104',
  I105 = 'i-105',
  I106 = 'i-106',
  I107 = 'i-107',
  I108 = 'i-108',
  I109 = 'i-109',
  I110 = 'i-110',
  I111 = 'i-111',
  I112 = 'i-112',
  I113 = 'i-113',
  I114 = 'i-114',
  I115 = 'i-115',
  I116 = 'i-116',
  I117 = 'i-117',
  I118 = 'i-118',
  I119 = 'i-119',
  I120 = 'i-120',
  I121 = 'i-121',
}
