/* eslint-disable-next-line */
export enum EProduct {
  BusinessContent = 'BUSINESS_CONTENT',
  Electronics = 'ELECTRONICS',
  FirmClosure = 'FIRM_CLOSURE',
  Glass = 'GLASS',
  Machine = 'MACHINE',
  OperatingCosts = 'OPERATING_COSTS',
  Vehicle = 'VEHICLE'
}
