import { getBusinessTypes } from 'client/store/api';
import { ConsumerTypeContext } from 'client/store/ConsumerTypeContext';
import { BusinessTypeDescription } from 'common/BusinessTypeDescription';
import { BusinessTypeRequestDto } from 'common/dto/business-types-request.dto';
import React, {
  createContext, useContext, useEffect, useState,
} from 'react';

export interface IBusinessTypesContext {
  allBusinessTypes: string[];
  businessTypeDescriptions: BusinessTypeDescription[];
  error: string | null;
  isLoading: boolean;
}

const BusinessTypesContext = createContext<IBusinessTypesContext>({
  allBusinessTypes: [],
  businessTypeDescriptions: [],
  error: null,
  isLoading: false,
});
const { Provider } = BusinessTypesContext;

interface IBusinessTypesProvider {
  children: React.ReactNode;
}

const BusinessTypesProvider = ({ children }: IBusinessTypesProvider) => {
  const { consumerType } = useContext(ConsumerTypeContext);
  const [allBusinessTypes, setAllBusinessTypes] = useState(['']);
  const [businessTypeDescriptions, setBusinessTypeDescriptions] = useState<BusinessTypeDescription[]>([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setIsLoading(true);
    setError(null);
    getBusinessTypes(new BusinessTypeRequestDto(consumerType)).then((businessTypesResponse) => {
      if (!businessTypesResponse.error && businessTypesResponse.payload) {
        setIsLoading(false);
        const businessTypes = businessTypesResponse.payload as BusinessTypeDescription[];
        setBusinessTypeDescriptions(businessTypes);
        setAllBusinessTypes(businessTypes.map((businessType) => businessType.businessTypeDescription));
      } else {
        setIsLoading(false);
        setError(businessTypesResponse.error);
      }
    });
  }, []);

  return (
    <Provider
      value={{
        allBusinessTypes,
        businessTypeDescriptions,
        error,
        isLoading,
      }}
    >
      {children}
    </Provider>
  );
};

export { BusinessTypesContext, BusinessTypesProvider };
