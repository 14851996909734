import Checkbox from '@eg/elements/Checkbox';
import TooltipIcon from '@eg/elements/TooltipIcon';
import React, { SyntheticEvent } from 'react';

import styles from './styles.module.scss';

export interface ICheckboxTileProps {
  icon: any;
  label: string;
  className?: string;
  value?: boolean;
  isDisabled?: boolean;
  dataTestid?: string;
  tooltip?: React.ReactNode;
  onChange?: (newValue: boolean) => void;
}

export const CheckboxTile = ({
  icon: Icon,
  label,
  className,
  value,
  onChange,
  isDisabled,
  dataTestid,
  tooltip: TooltipContent,
}: ICheckboxTileProps) => {
  const checkboxTileClass = `esc_container esc_border-radius esc_border-radius--small ${styles.checkboxTileContainer}
  ${className ? ` ${className}` : ''} ${isDisabled ? styles.disabled : styles.clickable}`;
  const checkboxTileInnerContainerClass = 'esc_box esc_box--around';
  const onChangeHandler = (e: SyntheticEvent) => {
    // we have to provide a change handler to Checkbox, otherwise it is not mutable
    // and to prevent mutliple click events, we have to style inputContainer with pointer-events: none
    e.stopPropagation();
    if (onChange) {
      onChange(!value);
    }
  };
  const onClickHandler = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (isDisabled) {
      return;
    }
    if (onChange) {
      onChange(!value);
    }
  };
  return (
    <div className={checkboxTileClass} onClick={onClickHandler} role="presentation">
      <div className={checkboxTileInnerContainerClass}>
        <div className={styles.flexContainer}>
          <div className={styles.iconContainer}>
            <Icon width={48} height={48} />
          </div>
          <div className={styles.labelContainer}>{label}</div>
          {TooltipContent ? (
            <div className={styles.tooltipContainer}>
              <TooltipIcon>{TooltipContent}</TooltipIcon>
            </div>
          ) : null}
          <div className={styles.inputContainer}>
            <Checkbox
              label=""
              checked={value}
              onChange={onChangeHandler}
              disabled={isDisabled}
              data-testid={dataTestid}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
