/* eslint-disable max-len */
import { ErgoElementsContext } from '@eg/elements/Provider';
import SvgIcon from '@eg/elements/SvgIcon';
import React, { useContext } from 'react';

interface IExtendedCoverageIconProps {
  width?: number;
  height?: number;
}

export const ExtendedCoverageIcon = ({ width, height }: IExtendedCoverageIconProps) => {
  const { idGenerator } = useContext(ErgoElementsContext);

  return (
    <SvgIcon idGenerator={idGenerator} title="fire" viewBox="0 0 52 52" width={width} height={height}>
      <path d="m6.4 2.2h39c2.2 0 4 1.8 4 4v39c0 2.2-1.8 4-4 4h-39c-2.2 0-4-1.8-4-4v-39c0-2.2 1.8-4 4-4z" fill="none" />
      <g>
        <path d="m22.1 13.6c0 .4-.1.9-.1 1.3-.1 1.3-.2 2.6-.2 3.9.3-.1.5-.2.8-.3.5-.2 1.1-.3 1.6-.5.1 0 .3-.1.4-.1-.9 1.1-1.9 2.2-2.8 3.3-1 1.2-2 2.4-3.1 3.6-1.5-1.2-3.1-2.4-4.6-3.6.3-.1.6-.2.9-.3.4-.1.9-.3 1.3-.4-.2-.8-.5-1.5-.7-2.3.7.5 1.4.9 2.2 1.4-.2-1.5-.4-3.1-.7-4.6 0-.1 0-.2 0-.3.2.2.4.5.6.7.7.8 1.3 1.6 2 2.3.1.2.3.3.4.5.1-.2.2-.4.3-.6.5-1.2 1.1-2.6 1.7-4 0 .1 0 .1 0 0z" />
        <path d="m12.5 28h.1.6.7c.6.1 1.2.4 1.7.7.7.4 1.4.9 2.1 1.4.2.2.5.4.7.6l.2.2c.2 0 .5.1.7.1 1 .2 2.1.4 3.1.7-.1.4-.3.8-.4 1.2.4.3.8.6 1.2.9-.1.3-.2.7-.3 1l.7.7s.1.1 0 .1c-.1.3-.1.7-.1 1-.1 0-.3.1-.4.1-.2 0-.4 0-.6 0 .1-.4.1-.9-.1-1.4s-.4-.9-.8-1.2-.8-.5-1.3-.5c-.2 0-.5 0-.7 0-.5.1-.9.3-1.2.7-.3.3-.6.8-.7 1.2s-.1.8 0 1.2c-1.8 0-3.5 0-5.3 0 0-1.9 0-3.9 0-5.8h1.3.8.7.6.4.2c.1 0 .2-.2.2-.3s-.1-.2-.2-.3c-.2-.1-.3-.2-.5-.4-.2-.1-.4-.2-.6-.4-.5-.3-.9-.5-1.5-.7-.2-.1-.4-.1-.6-.1s-.4 0-.6 0c-.1 0-.3 0-.4 0 0-.3 0-.5 0-.8.3.2.3.1.3.1z" />
        <path d="m40.1 36.8c0-.3-.2-.6-.5-.7-.1-.1-.3 0-.4 0-.6 0-1.1 0-1.7 0-.1 0-.3 0-.4 0 0-3.2 0-6.4 0-9.6.2.1.2.3.4.4s.4.1.6.1c.3-.1.6-.3.6-.6 0-.1 0-.3 0-.5 0-.1-.1-.2-.2-.3-2.8-3.3-5.5-6.6-8.3-9.9-.2-.2-.4-.4-.5-.6-.1-.1-.2-.2-.4-.2-.1 0-.2 0-.4 0-.1 0-.2.1-.3.1-.1.1-.2.2-.3.3-2.9 3.4-5.7 6.8-8.6 10.2-.1.1-.2.3-.3.4v.5c.1.4.5.6.9.6.2 0 .4-.1.5-.2s.1-.2.2-.2v.4 3.5c.6.1 1.2.3 1.7.5.1 0 .3.1.4.2s.2.2.2.3.1.3 0 .4c-.1.3-.2.5-.2.8.3.2.5.4.8.6l.2.2c.1.1.1.2.1.3s.1.2 0 .3c-.1.2-.1.5-.2.7.2.2.3.3.5.5.1.1.1.3.1.4v.4c0 .2-.1.4-.1.6s-.1.3-.1.5c0 .1-.1.2-.1.3-.1.1-.2.2-.3.2h15.1.4c.3-.3.6-.6.6-.9zm-8.5-.7c-1.7 0-3.3 0-5 0 0-1.9 0-3.8 0-5.8 0-.1 0-.2 0-.2.1-.3.4-.6.8-.6h3.4c.2 0 .5.1.6.3s.2.4.2.6v5.2z" />
        <path d="m21.9 35.4c-.2-.4-.5-.7-.8-.9-.4-.2-.8-.3-1.2-.2-.5.1-.9.3-1.2.7-.2.3-.3.6-.4.9v.5c0 .3.2.6.4.9.3.4.6.6 1.1.7.2.1.4.1.6 0 .5-.1 1-.3 1.3-.8.3-.3.4-.7.4-1.2 0-.2-.1-.4-.2-.6zm-.9 1.1c-.1.3-.5.6-.9.5-.2 0-.3-.1-.5-.2-.1-.1-.2-.3-.3-.5 0-.3.1-.6.3-.7.1-.1.2-.2.4-.2s.5 0 .7.2c.3.1.4.5.3.9z" />
      </g>
    </SvgIcon>
  );
};
