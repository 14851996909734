/* eslint-disable max-len */
export const infoMessages = {
  'i-100': `Damit wir Ihren Betrieb richtig versichern können, wählen Sie zunächst eine Betriebsart. Diese muss dem tatsächlich vorhandenen Risiko entspricht, d.h. das, was Sie in Ihrem Betrieb machen, muss typisch für die ausgewählte Betriebsart sein. Geben Sie dafür ein Suchwort ein. Die Suchfunktion bezieht sich nicht allein auf den Wortbeginn, sondern auch auf diese Buchstabenkombination mitten im Wort. <br/><br/>
  Wenn Sie in Ihrem Betrieb für die ausgewählte Betriebsart untypische Vorräte oder Waren führen, sind diese sogenannten „nicht betriebsüblichen Vorräte und Waren“ trotzdem mitversichert (ein Friseur verkauft z.B. auch Textilien). Allerdings nur bis zu 15% der Versicherungssumme, max. 20.000 Euro. Sollte der tatsächlich vorhandene Anteil „nicht betriebsüblicher“ Vorräte und Waren höher sein, wählen Sie die Betriebsart entsprechend den sog. „nicht betriebsüblichen“ Vorräten und Waren (hier: Textilienhandel). Sollten Sie sich unsicher sein, rufen Sie uns gerne an. <br/>
  Sollten bei einer Betriebsart Warensortimente ausgenommen sein, so gilt die Regelung zu den „nicht betriebsüblichen Vorräten und Waren“ nicht. In diesen Fällen suchen Sie die Betriebsart mit diesem Warensortiment. Beispiel: Ein Zeitschriftenhandel verkauft in kleinem Maße auch Tabakwaren. In diesem Fall darf nicht die Betriebsart "Zeitschriftenhandel (ohne Tabak oder Spirituosen, kein Kiosk)" verwendet werden. Es muss die Betriebsart "Zeitschriftenhandel mit Tabak/Spirituosen (kein Kiosk)" gewählt werden.`,
  'i-101':
    'Geben Sie die Anschrift an, an der sich Ihr zu versichernder Betrieb befindet. Nach der Eingabe erfolgt eine Adressprüfung. Sollte die Adresse nicht zugordnet werden können, erhalten Sie eine entsprechende Meldung. Prüfen Sie dann Ihre Eingabe und sprechen Sie uns ggf. an.',
  'i-102':
    'Versicherungsschutz können wir nur anbieten, wenn sich die Versicherungsräume in einem allseitig umschlossenen Gebäude befinden.',
  'i-103': `Die Türen, Tore und Fenster zu Ihrem Betrieb müssen gesichert sein, damit sie nicht von außen durch unberechtigte Dritte geöffnet werden können. <br/>
  Türen sind ausreichend gesichert, wenn die vorhandenen Türschlösser betätigt werden. Dabei muss der Riegel des Türschlosses vollständig ins Schließblech der Tür eingreifen. Beim elektromechanischen Verschluss kann die Berechtigung zur Betätigung des Schlosses elektronisch oder biometrisch (z.B. durch Transponder, Magnetkarte, elektronische Schlüssel, Fingerabdruck) erfolgen. Der Verschluss erfolgt dabei weiterhin über die mechanische Verriegelung des Riegels. Neben dem üblichen Türschloss kann der Verschluss auch durch andere mechanische Verschlusseinrichtungen wie z.B. Querriegelschloss, Stangenschloss gewährleistet werden. <br/>
  Fenster und Fenstertüren sind üblicherweise mit Drehkippbeschlägen ausgerüstet. Ein ausreichender Verschluss ist gewährleistet, wenn die Fenster und Fenstertüren im nicht gekippten Zustand über die Fenstergriffe verschlossen sind. Bei beweglichen Fenstern ohne Drehkippbeschlägen ist es ausreichend, wenn sie durch andere mechanische Verriegelungen von außen nicht geöffnet werden können.`,
  'i-104': `Wir versichern die technische und kaufmännische Betriebseinrichtung sowie Vorräte und Waren. Fremdes Eigentum ist mitversichert. <br/>
  Die Versicherungssumme soll dem Versicherungswert bei Eintritt des Versicherungsfalls entsprechen. Ist die Versicherungssumme niedriger als der Versicherungswert, liegt eine Unterversicherung vor. Dies führt dazu, dass im Schadensfall nur ein Teil des Schadens ersetzt wird, und zwar im Verhältnis Versicherungssumme zu Versicherungswert. Bei Schäden bis 50.000 Euro rechnen wir eine etwaige Unterversicherung nicht an. Bei Schäden größer 50.000 Euro wird eine Unterversicherung nur angerechnet, wenn der Schaden 20% der Versicherungssumme übersteigt. <br/>
  Versicherungswert der technischen und kaufmännischen Betriebseinrichtung ist der Neuwert, d.h. der Betrag, der aufzuwenden ist, um Sachen gleicher Art und Güte in neuwertigem Zustand wiederzubeschaffen oder sie neu herzustellen. Für Vorräte und Waren ist Versicherungswert der Betrag, der aufzuwenden ist, um Sachen gleicher Art und Güte wiederzubeschaffen oder sie neu herzustellen. Liebhaberwerte sind nicht versicherbar.<br/>
  Wichtiger Hinweis: Nicht ausreichend ist in der Regel der Buchwert aus der Bilanz. Auch gebraucht gekaufte Sachen müssen mit ihrem Neuwert berücksichtigt werden. <br/>
  Zum Ausgleich von Schätzungsdifferenzen, Wertverbesserungen, Bestandsveränderungen sowie bei jahreszeitlichen Schwankungen des Vorrätebestands sollten Sie ein Vorsorgebetrag einrechnen (Empfehlung: 10% bis 15%). `,
  'i-105':
    'Wenn keine Selbstbeteiligung für die Gefahren Feuer, Einbruchdiebstahl, Leitungswasser, Sturm/Hagel gewählt wird, gelten für die Gefahren Überschwemmung/Rückstau, Sonstige Naturgefahren, Extended Coverage Gefahren und Unbenannte Gefahren jeweils 500 Euro Selbstbeteiligung.',
  'i-106': `Wir leisten Entschädigung für Ihre Betriebseinrichtung, Vorräte und Waren, wenn diese durch eine der folgenden Gefahren zerstört oder beschädigt werden oder infolgedessen abhandenkommen.<br/>
  Feuer: Versichert sind Schäden, die durch Brand, Blitzschlag oder Überspannung durch Blitz verursacht werden. Auch gegen Schäden durch Explosion, Implosion und Anprall oder Absturz eines Luftfahrzeugs sind Sie abgesichert.
  Einbruchdiebstahl: Erstattet werden der Verlust Ihrer Sachen sowie deren Beschädigung durch Einbruchdiebstahl. Vandalismus nach einem Einbruch gehört ebenso dazu wie Raub innerhalb eines Gebäudes oder Grundstücks und auf Transportwegen.
  Leitungswasser: Wenn Wasser bestimmungswidrig aus den Rohren oder Installationen des Gebäudes entweicht, ersetzen wir die Kosten für die Beseitigung des Schadens am Rohr sowie den entstandenen Nässeschaden an Einrichtung, Vorräten und Waren. Mitversichert sind Schäden durch Löschanlagen-Leckage.
  Sturm/Hagel: Der Schutz umfasst alle Schäden, die durch direkte Einwirkung von Sturm oder Hagel eintreten oder durch umherfliegende Gegenstände wie Bäume oder Gebäudeteile verursacht werden. Von einem Sturm ist ab Windstärke 8 die Rede.`,
  'i-107':
    'Wir leisten bei allen Schäden, die durch Überschwemmung entstehen, z.B. die Überflutung des Grund und Bodens des Versicherungsorts nach einem Hochwasser. Oder wenn die Kanalisation bei einem Unwetter die Wassermassen nicht aufnehmen kann und das Grundstück überflutet wird. Der Versicherungsschutz tritt frühestens einen Monat nach Antragstellung in Kraft (Wartezeit).',
  'i-108':
    'Versichert sind alle Schäden, die durch Erdbeben, Erdsenkung oder Erdrutsch entstehen. Auch Schäden durch Lawinen, Schneedruck oder einen Vulkanausbruch sind im Versicherungsschutz inbegriffen. Der Versicherungsschutz tritt frühestens einen Monat nach Antragstellung in Kraft (Wartezeit).',
  'i-109':
    'Versichert sind Schäden, die durch innere Unruhen, Streik oder Aussperrung entstehen. Auch böswillige Beschädigung oder Schäden durch Fahrzeuganprall, Rauch und Überschalldruckwellen gehören dazu.',
  'i-110': `Hier gilt: Alles ist versichert, was nicht explizit ausgeschlossen ist – z.B. eine unvorhersehbare Beschädigung oder Zerstörung durch den Einsturz von Regalen. Schäden durch Abhandenkommen sind nicht versichert. <br/>
  Die Unbenannten Gefahren können keine andere Gefahr ersetzen! Sie sollten deshalb nur zusammen mit allen anderen Gefahren beantragt werden.`,
  'i-111':
    'Bei einem Schadensfall kann es passieren, dass Ihr Betrieb teilweise oder ganz stillsteht, z.B. weil Maschinen zerstört oder beschädigt wurden und deshalb nicht benutzt werden können. Die Ertragsausfallversicherung trägt in diesem Fall die laufenden Kosten und ersetzt den entgangenen Gewinn. <br/> \n' +
    'Wir haften für den Ertragsausfallschaden, der innerhalb von 24 Monaten seit Eintritt des Sachschadens entsteht (Haftzeit).',
  'i-112': `Wenn Ihr Betrieb durch eine Sperrung öffentlich zugänglicher Bereiche durch
  <ul><li>das Entschärfen oder Beseitigen von Bomben aus dem Ersten oder Zweiten Weltkrieg,
  </li><li>die Durchführung oder Androhung eines Terrorakts bzw. der Verdacht auf einen Terrorakt,
  </li><li>eine Amoklage oder der Verdacht auf eine Amoklage.
  </li></ul>
  unterbrochen oder beeinträchtigt wird, versichern wir Ihren Ertragsausfall. Dauert die Sperrung länger als drei Stunden, erstatten wir Ihnen die vereinbarte Tagesentschädigung – unabhängig vom tatsächlichen Umsatzverlust.`,
  'i-113': `Wir organisieren in den folgenden Notfällen das notwendige Fachpersonal und zahlen jeweils den Einsatz bis zu 3.000 €:
  <ul><li>Schlüsseldienst-Service
  </li><li>Rückreisekosten bei einem Versicherungsfall während einer Urlaubs- oder Dienstreise
  </li><li>Sanitär-, Elektro- und Heizungs-Service
  </li><li>Notheizungs-Service
  </li><li>Psychologische Betreuung nach einem Versicherungsfall
  </li><li>Sicherheitsberatung nach einem Einbruch
  </li></ul>
  Übernehmen Sie die Organisation selbst, zahlen wir Ihnen bis zu 500 €. Sie müssen uns den Eintritt des Notfalls unverzüglich melden.`,
  'i-114':
    'Wir übernehmen die Kosten für Wiederherstellung oder Reparaturen an Gebäude- und Mobiliarverglasungen. Versichert sind dabei u.a. Scheiben aus Glas oder Kunststoff, Glasbausteine und Profilbaugläser, Lichtkuppeln sowie Scheiben von Sonnenkollektoren gegen Schäden durch Zerbrechen.',
  'i-115':
    'Unsere Elektronik-Pauschalversicherung bietet Versicherungsschutz für Ihre elektrotechnischen und elektronischen Anlagen und Geräte der Informations-, Kommunikations-, Büro-, Sicherungs- und Meldetechnik. Versichert sind unvorhergesehene Sachschäden (z.B. durch Bedienungsfehler, Schwelen, Glimmen, Sengen und Kurzschluss).<br/>' +
    'Es gilt eine Selbstbeteiligung von 250 Euro je Versicherungsfall. Für mobile/beweglich einsetzbare Sachen bei Schäden durch Abhandenkommen infolge Diebstahl, Einbruchdiebstahl, Raub oder Plünderung außerhalb des Versicherungsorts beträgt die Selbstbeteiligung 25 % des Schadens, mindestens 250 Euro je Versicherungsfall. Für die Software (Daten, Programme und Wechseldatenträger), die zur versicherten Hardware gehört, gilt eine Selbstbeteiligung von 500 Euro.',
  'i-116': `Die Versicherungssumme soll der Summe der Einzel-Versicherungswerte aller versicherten Sachen entsprechen. Einzel-Versicherungswert ist der jeweils gültige Listenpreis der versicherten Sache im Neuzustand (Neuwert) zuzüglich der Bezugskosten (z.B. Kosten für Verpackung, Fracht, Zölle und Montage) sowie zuzüglich dem Wert der Versorgungstechnik (z.B. Klima- und Netzersatzanlagen), Leitungen und Erdkabel. Rabatte und Preiszugeständnisse bleiben für den Versicherungswert unberücksichtigt. Sind Sie nicht vorsteuerabzugsberechtigt, berücksichtigen Sie auch die Mehrwertsteuer. <br/>
  Bis zu einer Versicherungssumme zur Geschäftsinhaltsversicherung von 50.000 € erfassen Sie für die Elektronik-Pauschalversicherung keine separate Versicherungssumme. Die Versicherungssumme zur Elektronik-Pauschalversicherung entspricht in diesem Fall der Versicherungssumme zur Geschäftsinhaltsversicherung.`,
  'i-117':
    'Durch die Zusatzdeckung versichern Sie <b>zusätzlich</b> elektrotechnische und elektronische Anlagen und Geräte der Medizintechnik, der Mess-, Prüf- und Steuerungstechnik, der Satz- und Reprotechnik sowie der Bild- und Tontechnik.',
  'i-118': `Wählen als Zahlungsweise "halbjährlich", erhöht sich der Netto-Beitrag um 3% (Ratenzahlungszuschlag). Bei einer vierteljährlichen Zahlungsweise erhöht sich der Beitrag um 5%. Netto-Beitrag ist der Beitrag ohne Versicherungssteuer. <br/>
  Die Versicherungssteuer beträgt derzeit 19%.`,
  'i-119': `Geben Sie an, welche Art von Vorschäden Ihr Betrieb hatte, unabhängig davon, ob der Schaden versichert war oder nicht. Relevant sind Schäden durch Feuer, Einbruchdiebstahl, Leitungswasser, Sturm/Hagel, Überschwemmung/Rückstau, Sonstige Naturgefahren, Extended Coverage Gefahren und Unbenannte Gefahren sowie durch Glasbruch.. <br/>
  An welchem Datum ist der Schaden eingetreten? Und welcher Versicherer hat den Schaden ggf. bezahlt? <br/>
  Bitte schreiben Sie auch, durch welche Maßnahmen solche Schäden künftig möglichst verhindert werden sollen.`,
  'i-120':
    'Diese Felder sind mit der Anschrift Ihres Versicherungsorts vorbelegt. Soll Ihre Post an eine andere Adresse geschickt werden (z.B. Ihre Privatanschrift), können Sie die hier stehende Anschrift überschreiben.',
  'i-121': `Der Versicherungsbeginn wird mit Tagesdatum +1 vorbelegt. Es ist keine Auswahl eines älteren Datums möglich. Sie können den Beginn bis 364 Tage in der Zukunft festlegen. <br/>
  Die Vertragslaufzeit beträgt ein Jahr. Der Vertrag verlängert sich dann von Jahr zu Jahr.`,
};
