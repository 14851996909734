import { IAgency } from 'client/model/IAgency';
import * as H from 'history';
import * as queryString from 'query-string';
import React, { createContext, useState } from 'react';
import { withRouter } from 'react-router-dom';

export interface IAgencyContext {
  agency: IAgency | null;
}

const AgencyContext = createContext<IAgencyContext>({
  agency: null,
});
const { Provider } = AgencyContext;

interface IAgencyProviderProps {
  children?: React.ReactNode;
  location: H.Location;
}

type AgencyKeys = keyof IAgency;

const AgencyMapping: { [key: string]: AgencyKeys } = {
  adh_anrede: 'salution',
  adh_fax: 'fax',
  adh_internet: 'homepage',
  adh_mail: 'email',
  adh_mobil: 'mobile',
  adh_nachname: 'lastname',
  adh_oenr: 'oeNr',
  adh_ort: 'city',
  adh_plz: 'zipCode',
  adh_pnr: 'pnr',
  adh_strasse: 'street',
  adh_tel: 'phone',
  adh_vorname: 'firstname',
  popref: 'ref',
};

const SrcKeys = Object.keys(AgencyMapping);

const AgencyProviderComponent = ({ children, location }: IAgencyProviderProps) => {
  const [agency, setAgency] = useState<IAgency | null>(null);
  const searchParts = queryString.parse(location.search);

  let hasUpdate = false;
  const update: IAgency = {} as any;
  SrcKeys.forEach((srcKey) => {
    if (!searchParts[srcKey]) {
      return;
    }
    const destKey = AgencyMapping[srcKey];
    update[destKey] = searchParts[srcKey] as any;

    // if same param passed multiple times, take last occurence
    if (Array.isArray(update[destKey])) {
      update[destKey] = update[destKey].slice(-1).toString();
    }

    if (destKey === 'homepage' && (!update[destKey].startsWith('https://') || !update[destKey].startsWith('http://'))) {
      update[destKey] = `https://${update[destKey]}`;
    }
    hasUpdate = !agency || update[destKey] !== agency[destKey];
  });

  if (hasUpdate) {
    const newAgency = { ...agency, ...update };
    setAgency(newAgency);
  }

  return (
    <Provider
      value={{
        agency,
      }}
    >
      {children}
    </Provider>
  );
};

const AgencyProvider = withRouter(AgencyProviderComponent);

export { AgencyContext, AgencyProvider };
