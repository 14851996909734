import { EElectronics } from 'common/electronics.enum';
import React, {
  createContext, Dispatch, SetStateAction, useContext, useState,
} from 'react';

import { BusinessDataContext } from './BusinessDataContext';

export interface IDocumentContext {
  hasAcceptedConsultingWaiver: boolean;
  hasAcceptedPrivacyPolicy: boolean;
  hasAcceptedTermsConditions: boolean;
  hasDownloadedDocument: boolean;
  getDocumentLink: (disclaimer: boolean) => string;
  setHasAcceptedConsultingWaiver: Dispatch<SetStateAction<boolean>>;
  setHasAcceptedPrivacyPolicy: Dispatch<SetStateAction<boolean>>;
  setHasAcceptedTermsConditions: Dispatch<SetStateAction<boolean>>;
  setHasDownloadedDocument: Dispatch<SetStateAction<boolean>>;
}

const DocumentContext = createContext<IDocumentContext>({
  hasAcceptedConsultingWaiver: false,
  hasAcceptedPrivacyPolicy: false,
  hasAcceptedTermsConditions: false,
  hasDownloadedDocument: false,
  getDocumentLink: () => '',
  setHasAcceptedConsultingWaiver: () => {},
  setHasAcceptedPrivacyPolicy: () => {},
  setHasAcceptedTermsConditions: () => {},
  setHasDownloadedDocument: () => {},
});
const { Provider } = DocumentContext;

interface IDocumentProvider {
  children: React.ReactNode;
}

const DocumentProvider = ({ children }: IDocumentProvider) => {
  const [hasAcceptedConsultingWaiver, setHasAcceptedConsultingWaiver] = useState(false);
  const [hasAcceptedPrivacyPolicy, setHasAcceptedPrivacyPolicy] = useState(false);
  const [hasAcceptedTermsConditions, setHasAcceptedTermsConditions] = useState(false);
  const [hasDownloadedDocument, setHasDownloadedDocument] = useState(false);
  const {
    electronics, glass, lossOfRevenuePlus, risks,
  } = useContext(BusinessDataContext);

  const getDocumentLink = (disclaimer: boolean): string => {
    let apiUrl = window.location.origin;
    if (apiUrl.indexOf('localhost:3000')) {
      apiUrl = apiUrl.replace(':3000', ':7001');
    }
    let electronicsStr = EElectronics.None.toString();
    if (electronics && electronics.isSelected) {
      electronicsStr = electronics.isAdditionalCoverageSelected
        ? EElectronics.Additional.toString()
        : EElectronics.Standard.toString();
    }
    const query = `businessContent=${true}&glass=${glass}&electronics=${electronicsStr}&lossOfRevenuePlus=${
      lossOfRevenuePlus.isSelected
    }&assistance=${risks.assistance}&disclaimer=${disclaimer}`;
    return `${apiUrl}/api/document?${query}`;
  };

  return (
    <Provider
      value={{
        hasAcceptedConsultingWaiver,
        hasAcceptedPrivacyPolicy,
        hasAcceptedTermsConditions,
        hasDownloadedDocument,
        getDocumentLink,
        setHasAcceptedConsultingWaiver,
        setHasAcceptedPrivacyPolicy,
        setHasAcceptedTermsConditions,
        setHasDownloadedDocument,
      }}
    >
      {children}
    </Provider>
  );
};

export { DocumentContext, DocumentProvider };
