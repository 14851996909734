import { validateRequiredField } from 'client/helpers/functions/validations/validateRequiredField';
import { validationMessages } from 'client/i18n/validationMessages';

export const validateInsuranceSum = (value: number | null, maxPossibleValue: number, isValidationOn = true) => {
  let isValid: string | boolean = true;
  isValid = validateRequiredField(value, isValidationOn);
  if (!isValid && isValidationOn && (value! < 1000 || value! > maxPossibleValue)) {
    isValid = `${validationMessages['validation.error.insuranse-sum-is-not-valid']} ${maxPossibleValue.toLocaleString(
      'DE-de',
    )} €.`;
  }
  return isValid;
};
