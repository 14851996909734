import { validateRequiredField } from 'client/helpers/functions/validations/validateRequiredField';
import { validationMessages } from 'client/i18n/validationMessages';
import { electronicFormatIBAN, isValidIBAN } from 'ibantools';

const euMembers = [
  'BE',
  'BG',
  'CZ',
  'DK',
  'DE',
  'EE',
  'IE',
  'EL',
  'ES',
  'FR',
  'HR',
  'IT',
  'CY',
  'LV',
  'LT',
  'LU',
  'HU',
  'MT',
  'NL',
  'AT',
  'PL',
  'PT',
  'RO',
  'SI',
  'SK',
  'FI',
  'SE',
];

const isEuMember = (value: string) => euMembers.indexOf(value.toUpperCase()) !== -1;

export const validateIban = (iban: string, isValidationOn = true) => {
  let isValid: string | boolean = true;
  isValid = validateRequiredField(iban, isValidationOn);
  const normalizedIban = electronicFormatIBAN(iban) || '';

  const validEuIban = () => isEuMember(normalizedIban.substr(0, 2)) && isValidIBAN(normalizedIban);

  if (!isValid && isValidationOn && !validEuIban()) {
    isValid = validationMessages['validation.error.iban-is-not-valid'];
  }
  return isValid;
};
