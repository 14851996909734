import { addYears } from 'client/helpers/functions/addYears';
import { cleanUpPhoneNumber } from 'client/helpers/functions/cleanUpPhoneNumber';
import { isCompany } from 'client/helpers/functions/isCompany';
import { NavigationUrls } from 'client/helpers/NavigationUrls';
import { history } from 'client/routers/history';
import { mapAddressToDto } from 'common/address.interface';
import { BusinessContent } from 'common/BusinessContent';
import { ECancellationStatus } from 'common/cancellation-status.enum';
import { ECommunicationType } from 'common/communication-type.enum';
import {
  IClaim, IDirectDebitMandate, IInsurant, IOrderRequestDto,
} from 'common/dto/order-request.dto';
import { toISODate } from 'common/helper/toISODate';
import { EProduct } from 'common/product.enum';
import { EShippingMethod } from 'common/shipping-method.enum';
import React, {
  createContext, useContext, useEffect, useState,
} from 'react';

import { sendOrder } from './api';
import { BusinessDataContext } from './BusinessDataContext';
import { BusinessTypesContext } from './BusinessTypesContext';
import { ConsumerTypeContext } from './ConsumerTypeContext';
import { RiskAddressContext } from './RiskAddressContext';
import { TariffContext } from './TariffContext';

export interface ISendOrderContext {
  isLoading: boolean;
  error: string | null;
  requestSendOrder: () => void;
  agentNo: string | null;
}

const SendOrderContext = createContext<ISendOrderContext>({
  isLoading: false,
  error: null,
  agentNo: null,
  requestSendOrder: () => { },
});
const { Provider } = SendOrderContext;

interface ISendOrderProviderProps {
  children: React.ReactNode;
}

const SendOrderProvider = ({ children }: ISendOrderProviderProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [agentNo, setAgentNo] = useState<string | null>(null);
  const [organisationalUnit, setOrganisationalUnit] = useState<string | null>(null);

  const { tariff } = useContext(TariffContext);
  const { businessTypeDescriptions } = useContext(BusinessTypesContext);
  const {
    directDebitMandate,
    formOfPayment,
    salutation,
    nameAffixOrInsurantFirstName,
    companyOrInsurantName,
    advertisementPermission,
    address,
    risks,
    isInclosedBuilding,
    hasSecurityMeasures,
    eMail,
    telephoneNumber,
    dateOfBirth,
    hadDamages,
    damagesQuantity,
    damagesDescription,
    damagesOperationalExpenditure,
    insurantAddress,
    deductible,
    businessType,
    insuranceSum,
    lossOfRevenuePlus,
    electronics,
    glass,
    hasPreinsurance,
    combinedShopContentsProduct,
    glassProduct,
    electronicFlatProduct,
  } = useContext(BusinessDataContext);

  const { consumerType } = useContext(ConsumerTypeContext);

  const { riskAddressState } = useContext(RiskAddressContext);

  useEffect(() => {
    const urlParams = new URLSearchParams(history.location.search);
    const agentNoFromQuery = urlParams.get('adh_pnr');
    const organisationalUnitFromQuery = urlParams.get('adh_oenr');

    if (agentNoFromQuery) {
      const agentNoWithoutLeadingZero = agentNoFromQuery.substr(1);
      setAgentNo(agentNoWithoutLeadingZero);
    }

    if (organisationalUnitFromQuery) {
      const numericOrganisationalUnit = organisationalUnitFromQuery.replace(/\D/g, '');
      setOrganisationalUnit(numericOrganisationalUnit);
    }
  }, []);

  const requestSendOrder = async () => {
    if (!isInclosedBuilding || !hasSecurityMeasures || !insuranceSum) {
      return;
    }
    const businessTypeDescription = businessTypeDescriptions.find(
      (businessTypeItem) => businessTypeItem.businessTypeDescription === businessType,
    );
    if (!businessTypeDescription) {
      return;
    }

    const dtoInsurantAddress = mapAddressToDto(insurantAddress);

    // build insurant
    const insurant: IInsurant = {
      advertisementPermission,
      salutation,
      name: companyOrInsurantName.trim(),
      communicationTypes: [],
      address: dtoInsurantAddress,
    };

    if (!isCompany(salutation)) {
      insurant.dateOfBirth = toISODate(dateOfBirth);
      insurant.firstname = nameAffixOrInsurantFirstName.trim();
    } else if (nameAffixOrInsurantFirstName.trim() !== '') {
      // affix of a company name is optional therefore this check is needed.
      insurant.firstname = nameAffixOrInsurantFirstName.trim();
    }
    if (insurant.communicationTypes && telephoneNumber) {
      insurant.communicationTypes.push({
        communicationType: ECommunicationType.TelephoneOffice,
        communicationAddress: cleanUpPhoneNumber(telephoneNumber),
      });
    }
    if (insurant.communicationTypes && eMail) {
      insurant.communicationTypes.push({
        communicationType: ECommunicationType.EmailOffice,
        communicationAddress: eMail,
      });
    }

    const dtoAddress = mapAddressToDto(address);

    // build business content
    const businessTypeID = businessTypeDescription.businessTypeId;
    const businessContent: BusinessContent = new BusinessContent(
      businessTypeID,
      insuranceSum,
      deductible,
      { address: dtoAddress },
      risks.toArray(),
      isInclosedBuilding,
      hasSecurityMeasures,
      riskAddressState.zuersClass,
      riskAddressState.hazardZone,
    );
    if (lossOfRevenuePlus.isSelected) {
      businessContent.setLossOfRevenuePlus(lossOfRevenuePlus.rate);
    }
    if (electronics.isSelected && electronics.insuranceSum) {
      businessContent.setElectronics(electronics.insuranceSum, electronics.isAdditionalCoverageSelected);
    }
    if (glass) {
      businessContent.setGlass(glass);
    }

    // build claims
    const claim: IClaim = {
      numberOfClaims: hadDamages && damagesQuantity ? damagesQuantity : 0,
    };
    if (claim.numberOfClaims && damagesOperationalExpenditure !== null) {
      claim.description = damagesDescription.trim();
      claim.totalClaimAmount = damagesOperationalExpenditure;
    }

    // build direct debit mandate
    const expirationDate = addYears(directDebitMandate.inceptionDate, 1);
    const iDirectDebitMandate: IDirectDebitMandate = {
      iban: directDebitMandate.iban,
      expirationDate: toISODate(expirationDate),
      inceptionDate: toISODate(directDebitMandate.inceptionDate),
      signatureDate: toISODate(new Date()),
      transactionDate: directDebitMandate.transactionDate,
    };

    // build order request dto
    const orderRequestDto: IOrderRequestDto = {
      consumer: consumerType,
      expirationDate: iDirectDebitMandate.expirationDate,
      formOfPayment,
      inceptionDate: iDirectDebitMandate.inceptionDate,
      premium: tariff.price.grossValue,
      shippingMethod: EShippingMethod.Mail,
      insurant,
      businessContent,
      claim,
      directDebitMandate: iDirectDebitMandate,
    };

    if (agentNo) {
      orderRequestDto.agentNo = agentNo;
    }

    if (organisationalUnit) {
      orderRequestDto.organisationalUnit = organisationalUnit;
    }

    if (hasPreinsurance) {
      orderRequestDto.preInsuring = [];
      if (
        Object.values(ECancellationStatus).includes(
          combinedShopContentsProduct.cancellationStatus as ECancellationStatus,
        )
      ) {
        orderRequestDto.preInsuring.push({
          product: EProduct.BusinessContent,
          cancellationStatus: combinedShopContentsProduct.cancellationStatus as ECancellationStatus,
          contractNo: combinedShopContentsProduct.contractNumber.trim(),
          insuranceKey: combinedShopContentsProduct.insuranceKey,
        });
      }

      if (
        glass &&
        Object.values(ECancellationStatus).includes(glassProduct.cancellationStatus as ECancellationStatus)
      ) {
        orderRequestDto.preInsuring.push({
          product: EProduct.Glass,
          cancellationStatus: glassProduct.cancellationStatus as ECancellationStatus,
          contractNo: glassProduct.contractNumber.trim(),
          insuranceKey: glassProduct.insuranceKey,
        });
      }

      if (
        electronics.isSelected &&
        Object.values(ECancellationStatus).includes(electronicFlatProduct.cancellationStatus as ECancellationStatus)
      ) {
        orderRequestDto.preInsuring.push({
          product: EProduct.Electronics,
          cancellationStatus: electronicFlatProduct.cancellationStatus as ECancellationStatus,
          contractNo: electronicFlatProduct.contractNumber.trim(),
          insuranceKey: electronicFlatProduct.insuranceKey,
        });
      }
    }

    setIsLoading(true);
    setError(null);

    const sendOrderResult = await sendOrder(orderRequestDto);
    if (sendOrderResult.payload) {
      setIsLoading(false);
    }
    if (sendOrderResult.error) {
      setIsLoading(false);
      setError(sendOrderResult.error);
    } else {
      // redirect to confirmation page after the send order is successfully completed
      window.location.href = NavigationUrls.orderConfirmationPageUrl;
    }
  };

  return (
    <Provider
      value={{
        isLoading,
        error,
        requestSendOrder,
        agentNo,
      }}
    >
      {children}
    </Provider>
  );
};

export { SendOrderContext, SendOrderProvider };
