export const notValidEmailEndings = [
  '@danv.de',
  '@das.de',
  '@dkv.com',
  '@ergo.com',
  '@ergo.de',
  '@ergo.digital',
  '@hamburg-mannheimer.de',
  '@itergo.com',
  '@longial.de',
  '@victoria.de',
  '@viv.de',
];
