/* eslint-disable max-len */
export const messages = {
  'contribution-page.contribution-calculation-form.title': 'Berechnung Ihres Beitrags',
  'contribution-page.contribution-calculation-form.select-sum.label': 'Versicherungssumme*',
  'contribution-page.contribution-calculation-form.select-sum.placeholder': 'gewünschte Versicherungssumme',
  'contribution-page.contribution-calculation-form.select-sum.invalid-input':
    'Bitte geben Sie eine Versicherungssumme ein.',
  'contribution-page.contribution-calculation-form.select-deductible.label': 'Selbstbeteiligung*',
  'contribution-page.contribution-calculation-form.select-deductibl.select.option-1':
    'keine Selbstbeteiligung für Feuer, Einbruchdiebstahl, Leitungswasser, Sturm/Hagel',
  'contribution-page.contribution-calculation-form.select-deductibl.select.option-2': '500 € Selbstbeteiligung',
  'contribution-page.contribution-calculation-form.select-deductibl.select.option-3': '1.000 € Selbstbeteiligung',
  'contribution-page.contribution-calculation-form.select-deductibl.info':
    'Für {list} gilt eine Selbstbeteiligung von 500 €.',
  'contribution-page.benefit-teaser.title': 'Komfort-Schutz',
  'contribution-page.business-content.title': 'Geschäftsinhaltsversicherung',
  'contribution-page.business-content.risk-tile.basic.title': 'Feuer, Einbruchdiebstahl, Leitungswasser, Sturm/Hagel',
  'contribution-page.business-content.risk-tile.flooding.title': 'Überschwemmung/\u200bRückstau',
  'contribution-page.business-content.risk-tile.natural-hazard.title': 'Sonstige Naturgefahren',
  'contribution-page.business-content.risk-tile.extended-coverage.title': 'Extended Coverage Gefahren',
  'contribution-page.business-content.risk-tile.unnamed-dangers.title': 'Unbenannte Gefahren',
  'contribution-page.business-content.risk-tile.loss-revenue.title': 'Absicherung Ertragsausfall',
  'contribution-page.benefit-teaser.extra-descriptions.item-1.title': '100% Schutz',
  'contribution-page.benefit-teaser.extra-descriptions.item-1.text': 'für Regelversorgung beim Zahnersatz',
  'contribution-page.benefit-teaser.extra-descriptions.item-2.title': '90% Schutz',
  'contribution-page.benefit-teaser.extra-descriptions.item-2.text': 'für privatzahnärztliche Versorgung bei Zahnersatz',
};
