import { NavigationUrls } from 'client/helpers/NavigationUrls';

export const primaryStepNames = ['Unternehmen', 'Beitrag', 'Persönliches', 'Abschluss'];

export type StepsType = {
  primaryStepName: string;
  url: string;
  buttons?: {
    next?: {
      text?: string;
      url?: string;
      isDisable?: boolean;
      isHidden?: boolean;
      hasConsultModal?: boolean;
      isSalesman?: boolean;
    };
    prev?: { text?: string; url?: string; isDisable?: boolean; isHidden?: boolean };
  };
}[];
export const steps: StepsType = [
  {
    primaryStepName: primaryStepNames[0],
    url: NavigationUrls.businessTypePageUrl,
    buttons: {
      prev: {
        isHidden: true,
      },
    },
  },
  {
    primaryStepName: primaryStepNames[1],
    url: NavigationUrls.contributionPageUrl,
  },
  {
    primaryStepName: primaryStepNames[1],
    url: NavigationUrls.extraBenefitsPageUrl,
    buttons: {
      next: {
        hasConsultModal: true,
      },
    },
  },
  {
    primaryStepName: primaryStepNames[1],
    url: NavigationUrls.preinsuranceDamagesPageUrl,
  },
  {
    primaryStepName: primaryStepNames[2],
    url: NavigationUrls.contactDataPageUrl,
  },
  {
    primaryStepName: primaryStepNames[2],
    url: NavigationUrls.paymentDataPageUrl,
  },
  {
    primaryStepName: primaryStepNames[3],
    url: NavigationUrls.summaryPagePageUrl,
    buttons: {
      next: {
        isHidden: true,
        isSalesman: true,
      },
    },
  },
];
