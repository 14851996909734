/* eslint-disable max-len */
export const messages = {
  'payment-data-page.payment-method.heading': 'Versicherungsbeginn',
  'payment-data-page.payment-begin.label': 'Beginn*',
  'payment-data-page.sepa.label': 'SEPA-Lastschrift',
  'payment-data-page.is-same-holder.label': 'Der Vertragspartner ist zugleich der Kontoinhaber.',
  'payment-data-page.account-holder.label': 'Kontoinhaber',
  'payment-data-page.iban.label': 'IBAN*',
  'payment-data-page.transaction-date.label': 'Erste Abbuchung am*',
  'payment-data-page.transaction-date.please-select.select': '-- Bitte auswählen --',
  'payment-data-page.authorization.text':
    'Ich ermächtige die ERGO Versicherung AG, ERGO-Platz 1, 40198 Düsseldorf (Gläubiger-ID DEO5ZZZ00000012101), Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von ERGO Versicherung AG auf mein Konto bezogenen Lastschriften einzulösen.',
  'payment-data-page.hint.text':
    'Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Beitrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen. Der SEPA-Basislastschrift-Einzug wird mir spätestens fünf Kalendertage im Voraus unter Angabe der weiteren Fälligkeitstermine angekündigt.',
  'payment-data-page.hint.label': 'Hinweis',
};
