export const NavigationUrls = {
  businessTypePageUrl: '/step/business-type',
  preinsuranceDamagesPageUrl: '/step/preinsurance-damages',
  contributionPageUrl: '/step/contribution',
  extraBenefitsPageUrl: '/step/extra-benefits',
  contactDataPageUrl: '/step/contact-data',
  summaryPagePageUrl: '/step/summary',
  paymentDataPageUrl: '/step/payment-data',
  orderConfirmationPageUrl: '/order-confirmation',
};
