import Button from '@eg/elements/Button';
import ArrowRightIcon from '@eg/elements/Icons/ArrowRightIcon';
import DashboardCheckIcon from '@eg/elements/Icons/DashboardCheckIcon';
import { withI18n } from 'client/i18n/withI18n';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from './messages';

const OrderSuccessMessageComponent = () => (
  <div className="u-padding-top-l u-padding-bottom-m u-text-align-center">
    <DashboardCheckIcon width={60} height={60} />
    <h1>
      <FormattedMessage id="order-success-message.heading" />
    </h1>
    <p>
      <FormattedMessage id="order-success-message.p1" />
    </p>
    <p>
      <FormattedMessage id="order-success-message.p2" />
    </p>
    <Button
      className="u-margin-top-m"
      onClick={() => window.location.replace('https://www.ergo.de/de/Geschaeftskunden-Produkte')}
      variant="primary"
      iconRight={ArrowRightIcon}
    >
      <FormattedMessage id="order-success-message.button.text" />
    </Button>
  </div>
);

export const OrderSuccessMessage = withI18n(OrderSuccessMessageComponent, messages);
