import { Location } from 'history';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

interface IScrollToTopProps {
  children: React.ReactChildren;
  location: Partial<Location>;
}

// token from
// https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md
const ScrollToTopComponent = ({ children, location: { pathname } }: IScrollToTopProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
};

// todo: typedefinition for withRouter does not like function components
export const ScrollToTop = withRouter(ScrollToTopComponent as any);
