import ControlWithHint from '@eg/elements/ControlWithHint';
import FormRow from '@eg/elements/FormRow';
import Input from '@eg/elements/Input';
import Select from '@eg/elements/Select';
import { preInsuringCompanies } from 'client/helpers/data/preInsiringCompanies';
import { ESelectValues } from 'client/helpers/ESelectValues';
import { validateRequiredStringLength, validateSelectBox } from 'client/helpers/functions/validations';
import { translateCancellationStatus } from 'client/i18n/translateEnums';
import { withI18n } from 'client/i18n/withI18n';
import { IPreinsuranceProduct } from 'client/store/BusinessDataContext';
import { GlobalsContext } from 'client/store/GlobalsContext';
import { ECancellationStatus } from 'common/cancellation-status.enum';
import React, {
  Dispatch, SetStateAction, useContext, useEffect,
} from 'react';
import { InjectedIntlProps } from 'react-intl';

import { messages } from './messages';

interface IPreinsuranceProductForm extends InjectedIntlProps {
  disableSelectNone: boolean;
  preinsuranceProduct: IPreinsuranceProduct;
  setPreinsuranceProduct: Dispatch<SetStateAction<IPreinsuranceProduct>>;
  productName: string;
  setIsFormValid: Dispatch<SetStateAction<boolean>>;
  updateErrorMessagesState: (cancellationStatus: ECancellationStatus) => {};
  isVisible: boolean;
}

const PreinsuranceProductFormComponent = ({
  intl,
  disableSelectNone,
  preinsuranceProduct,
  setPreinsuranceProduct,
  productName,
  setIsFormValid,
  updateErrorMessagesState,
  isVisible,
}: IPreinsuranceProductForm) => {
  const { isValidationOn } = useContext(GlobalsContext);

  const hasProductNoPreinsurance = () => preinsuranceProduct.insuranceKey === ESelectValues.NoPreinsuarance;

  // check for validation error
  useEffect(() => {
    if (isVisible) {
      setIsFormValid(
        !validateSelectBox(preinsuranceProduct.insuranceKey) &&
        !validateRequiredStringLength(30, preinsuranceProduct.contractNumber, !hasProductNoPreinsurance()) &&
        !validateSelectBox(preinsuranceProduct.cancellationStatus, !hasProductNoPreinsurance()),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preinsuranceProduct]);

  if (isVisible) {
    return (
      <>
        <FormRow
          label={intl.formatMessage({
            id: productName,
          })}
        >
          <ControlWithHint
            description={intl.formatMessage({ id: 'preinsurance-damages-page.preinsurance.organisation-name.label' })}
            error={validateSelectBox(preinsuranceProduct.insuranceKey, isValidationOn)}
          >
            <Select
              name="insuranceKey"
              aria-label={intl.formatMessage({ id: 'preinsurance-damages-page.preinsurance.organisation-name.label' })}
              value={preinsuranceProduct.insuranceKey}
              onFocus={() => setPreinsuranceProduct({ ...preinsuranceProduct, isEditing: true })}
              onBlur={() => setPreinsuranceProduct({ ...preinsuranceProduct, isEditing: false })}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setPreinsuranceProduct({ ...preinsuranceProduct, insuranceKey: e.target.value });
              }}
            >
              <option value={ESelectValues.PleaseSelect} disabled>
                {intl.formatMessage({
                  id: 'preinsurance-damages-page.preinsurance.please-select.select',
                })}
              </option>
              {!disableSelectNone && (
                <option value={ESelectValues.NoPreinsuarance}>
                  {intl.formatMessage({
                    id: 'preinsurance-damages-page.preinsurance.no-preinsurance.select',
                  })}
                </option>
              )}
              {preInsuringCompanies.map((preInsuringCompany: { id: string; value: string }) => (
                <option key={preInsuringCompany.id} value={preInsuringCompany.id}>
                  {preInsuringCompany.value}
                </option>
              ))}
            </Select>
          </ControlWithHint>
        </FormRow>
        <FormRow label="" colspans={[2, 1]}>
          <ControlWithHint
            description={intl.formatMessage({ id: 'preinsurance-damages-page.preinsurance.policy-number.label' })}
            error={validateRequiredStringLength(
              30,
              preinsuranceProduct.contractNumber,
              isValidationOn && !hasProductNoPreinsurance(),
            )}
          >
            <Input
              name="contractNumber"
              value={preinsuranceProduct.contractNumber}
              /* eslint-disable-next-line max-len */
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPreinsuranceProduct({ ...preinsuranceProduct, contractNumber: e.target.value })}
              aria-label={intl.formatMessage({ id: 'preinsurance-damages-page.preinsurance.policy-number.label' })}
              disabled={hasProductNoPreinsurance()}
            />
          </ControlWithHint>

          <ControlWithHint
            description={intl.formatMessage({ id: 'preinsurance-damages-page.preinsurance.terminated-by.label' })}
            error={validateSelectBox(
              preinsuranceProduct.cancellationStatus,
              isValidationOn && !hasProductNoPreinsurance(),
            )}
          >
            <Select
              data-testid="cancellationStatus"
              value={preinsuranceProduct.cancellationStatus}
              onChange={(e: any) => {
                setPreinsuranceProduct({
                  ...preinsuranceProduct,
                  cancellationStatus: e.target.value,
                });
                updateErrorMessagesState(e.target.value);
              }}
              disabled={hasProductNoPreinsurance()}
            >
              <option value={ESelectValues.PleaseSelect} disabled>
                {intl.formatMessage({
                  id: 'preinsurance-damages-page.preinsurance.please-select.select',
                })}
              </option>
              <option value={ECancellationStatus.ByInsurant}>
                {translateCancellationStatus(ECancellationStatus.ByInsurant)}
              </option>
              <option value={ECancellationStatus.ByInsurance}>
                {translateCancellationStatus(ECancellationStatus.ByInsurance)}
              </option>
              <option value={ECancellationStatus.Uncancelled}>
                {translateCancellationStatus(ECancellationStatus.Uncancelled)}
              </option>
            </Select>
          </ControlWithHint>
        </FormRow>
      </>
    );
  }
  return null;
};

export const PreinsuranceProductForm = withI18n(PreinsuranceProductFormComponent, messages);
